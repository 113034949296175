"use strict";
/**
 * @def extensibleTableSlots
 * @isGroup false
 * 
 * 
 */


var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var CreateStyle = require('src/core/GenericStyleConstructor');


var extensibleTableSlotsDef = function(uniqueID, options, model) {
		
	// Some CSS stuff
	var styles = [
/*@CSSifySlot*/
		];
	
	var headerDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'VaritextButtonWithPictoFirst',
			nodeName : 'th',
			// this is a big hack of shit (should be an attribute, but not... should be a "DOM" attribute... -> setAttribute(). TODO: fix after re-implementation of _arias&glyphs)
			states : [
				{sortable : 'sortable'},
				{sortedasc : undefined},
				{sorteddesc : undefined}
			],
			props : [
				{headerTitle : undefined}
			],
			reactOnSelf : [
				{
					from : 'headerTitle',
					to : 'content'
				}
			],
//			sWrapper : CreateStyle('extensibleTableSlotsStyles', null, styles).sWrapper,
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
	var sectionDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'ComponentWith_FastReactiveText',
			nodeName : 'tr',
			props : [
				{rowContentAsArray : undefined}
			],
			reactOnSelf : [
					{
						from : 'rowContentAsArray',
						cbOnly : true,
						subscribe : Component.ComponentWith_FastReactiveText.prototype.setContentFromArrayOnEachMemberView
					}
			]
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
	
	
	return {
		headerDef : headerDef,
		sectionDef : sectionDef
	};
}

extensibleTableSlotsDef.__factory_name = 'extensibleTableSlotsDef';
module.exports = extensibleTableSlotsDef;