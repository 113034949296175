"use strict";
/**
* @constructor SpinnerComponent
*/


var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
//var CoreTypes = require('src/core/CoreTypes');

var CreateStyle = require('src/core/GenericStyleConstructor');
var spinnerStyle = require('src/UI/defs/extraStyles/spinner');

var SpinnerComponent = function(def, containerDOMId, automakeable) {
	Components.ComponentWithHooks.apply(this, arguments);
	this.objectType = 'SpinnerComponent';
}

SpinnerComponent.prototype = Object.create(Components.ComponentWithHooks.prototype);SpinnerComponent.prototype.objectType = 'SpinnerComponent';

//SpinnerComponent.prototype.createEvents = function() {
//
//}


SpinnerComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					nodeName : 'loading-spinner',
					attributes : [
						{id : 'loading_spinner_3'},
						{className : 'spinner ' + this._defUID}
					],
					states : [
						{hidden : undefined}
					],
					reactOnParent : [
						{
							from : 'spinnerOn',
							to : 'hidden',
							map : function(val) {return val ? null : 'hidden';}
						}
					],
					sWrapper : CreateStyle('spinner_style', null, spinnerStyle).sWrapper
				}, null, 'hostOnly'),
				members : [
					TypeManager.createComponentDef({
						nodeName : 'div'
					}, null, 'hostOnly'),
					TypeManager.createComponentDef({
						nodeName : 'div'
					}, null, 'hostOnly'),
					TypeManager.createComponentDef({
						nodeName : 'div'
					}, null, 'hostOnly')
				]
		}, 'SpinnerComponent', 'rootOnly');
}



SpinnerComponent.__factory_name = 'SpinnerComponent';
module.exports = SpinnerComponent;
