"use strict";
/**
 * @def ColorSamplerSetComponentSlots
 * @isGroup false
 * 
 * @CSSify styleName : ColorSamplerSetComponentHeader
 * @CSSify styleName : ColorSamplerSetComponentSection
 * @CSSifyTheme themeName : basic-light
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ColorSamplerSetComponentSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */ 		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	for (let i = 0; i < options.listItemMembersCount; i++) {
		options.hostingCompAsAdef.members.push(
			TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					type : 'FlexRowComponent',
					props : [
						{colorGetter : undefined},
						{updateChannel : undefined}
					],
					// host doesn't need to react on parent: it receives data from the typedSlot
//					reactOnSelf: [
//						{
//							from : 'updateChannel',
//							to : 'colorGetter'
//						}
//					]
				}),
				members : [

				]
			}, null, 'rootOnly')
		);
	}
}

module.exports = ColorSamplerSetComponentSlotsDef;