"use strict";
/**
 * @def keyValueListHost
 * @isGroup false
 * 
 * @CSSify hostName : keyValueListHost
 * @CSSifyRule rule : host padding/inset_F
 * 
 */

module.exports = [
/*@CSSifySlot*/
				];