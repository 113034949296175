"use strict";
/**
 * @constructor KeyValuePairComponent
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var keyValueListSlotsDef = require('src/UI/packages_defs/structs/keyValueListSlotsDef');

var KeyValuePairComponent = function() {
	Component.ComponentWithReactiveText.apply(this, arguments);
	this.objectType = 'KeyValuePairComponent';
}
KeyValuePairComponent.prototype = Object.create(Component.ComponentWithReactiveText.prototype);
KeyValuePairComponent.prototype.objectType = 'KeyValuePairComponent';

KeyValuePairComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'tr',
			props : [
				{keyValuePair : undefined}
			],
			reactOnSelf : [
					{
						from : 'keyValuePair',
						cbOnly : true,
						subscribe : this.setContentFromArrayOnEachMemberView
					}
			],
//			sWrapper : keyValueListSlotsDef().headerDef.getHostDef().sWrapper
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'td',
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'td',
			}, null, 'hostOnly')
		]
	}, 'KeyValuePairComponent', 'rootOnly');
}

module.exports = KeyValuePairComponent;