"use strict";
/**
 * @constructor Fieldset
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var createFieldsetDef = require('src/UI/packages_defs/minimal/FieldsetDef');

var Fieldset = function(definition, parentView, parent) {
	// This component may be a group or autonomous
	var hostDef = definition.getGroupHostDef() || definition.getHostDef();
	if (!hostDef.props.findObjectByKey('slotsTextContent'))
		hostDef.props.push({slotsTextContent : undefined});
		
	Component.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'Fieldset';
}
Fieldset.prototype = Object.create(Component.CompositorComponent.prototype);
Fieldset.prototype.objectType = 'Fieldset';
Fieldset.prototype.extendsCore = 'CompoundComponentWithReactiveText';

Fieldset.prototype.createDefaultDef = function() {
	return createFieldsetDef();
}

module.exports = Fieldset;