"use strict";
/**
 * @constructor VisualSetHostComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

//var createVisualSetHostComponentHostDef = require('src/UI/generics/VisualSetHostComponent/packageComponentDefs/VisualSetHostComponentHostDef');
//var createVisualSetHostComponentSlotsDef = require('src/UI/generics/VisualSetHostComponent/packageComponentDefs/VisualSetHostComponentSlotsDef');

var VisualSetHostComponent = function(definition, parentView, parent) {
	Components.ComponentWithView.call(this, definition, parentView, parent);
	this.objectType = 'VisualSetHostComponent';
}
VisualSetHostComponent.prototype = Object.create(Components.ComponentWithView.prototype);
VisualSetHostComponent.prototype.objectType = 'VisualSetHostComponent';

VisualSetHostComponent.defaultDef = {
	nodeName : 'atoms-set-host',
	attributes : [],
	states : [],
	props : [],
	reactOnParent : [],
	reactOnSelf : []
}

VisualSetHostComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			VisualSetHostComponent.defaultDef,
			'VisualSetHostComponentDefaultDef'
		);
}

VisualSetHostComponent.prototype.renewBindings = function(numberedStream) {
	
}

VisualSetHostComponent.prototype.removeBinding = function(numberedStream) {
	
}

VisualSetHostComponent.prototype.saveBindings = function(numberedStream) {
	
}

VisualSetHostComponent.prototype.affectBindings = function(numberedStream) {
	
}

module.exports = VisualSetHostComponent;