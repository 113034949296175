"use strict";
/**
 * @constructor WheelController
 * 
 * 
 */

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

	
var WheelController = function(def, parentView, parent) {
	Component.ComponentWithView.call(this, def, parentView, parent);
	this.objectType = 'WheelControler';
	
	if (typeof def.selectorCB !== 'undefined' && def.selectorCB !== null)
		this.whichAction = def.selectorCB;
	this.options = def.options || {
		weight : 125,
		varioRef : 1,
		keyToStep : .05,
		resetAfterVario : true
	};
	this.state = {
			savedOffset : 0,
			wheelOffset : 0
	};
	this.constants = {
			varioRef : this.options.varioRef,
			weight : this.options.weight,
			keyToStep : this.options.keyToStep
	};
	this.helpers = {
			clickWhere : 0,
			onClickOffset : 0,
	}
	
	this.learning = false;
	this.keyboardEvents = def.keyboardSettings;

	this.initGenericEvent();
	this.init(def);
}
WheelController.prototype = Object.create(Component.ComponentWithView.prototype);
WheelController.prototype.objectType = 'WheelControler';

WheelController.prototype.whichAction = function() {
	return 'jog';
}

WheelController.prototype.createDOM = function(def) {
	var self = this;
	this.domElem = $('<div/>', {'class' : 'jog_wheel'});
	this.hoverElem = $('');
	$.each(this.keyBoardEvents, function(sector, event) {
		self.hoverElem = self.hoverElem.add($('<div/>', {'class' : 'jog_wheel_hover'}));
	});
	this.hoverElem.appendTo(this.domElem);
}

WheelController.prototype.registerClickEvents = function() {
	var self = this;
	
	// Basic move with mouse
	this.domElem.mousedown(function(e) {
		e.originalEvent.preventDefault();
		e.stopPropagation();
		self.helpers.clickWhere = e.pageY;
		self.helpers.onClickOffset = Number(self.state.wheelOffset);
		self.dragging = true;
		self.trackWheel();
	});	
}

WheelController.prototype.updateView = function() {
	this.domElem.css('transform', 'rotate('+ this.state.wheelOffset * Math.PI +'rad)');
}

WheelController.prototype.trackWheel = function() {
	
	var move = function (e) {
		var mouseOffset = (e.pageY - this.helpers.clickWhere);
		var offset = - mouseOffset / this.constants.weight;
		var action = this.whichAction();
		if (action === 'jog') {
			this.jog(offset);
		}
		else if (action === 'varioSpeed') {
			this.varioSpeed(offset);
		}
	}.bind(this);
	
	var end = function (e) {
		if (this.dragging === true) {
			var action = this.whichAction();
			if (action === 'varioSpeed') {
				if (this.options.resetAfterVario)
					this.reset();
			}
			this.dragging = false;
			this.trigger('update');
			this.trigger('updateVario', {varioSpeedValue : this.state.varioPos});
		}
		document.removeEventListener('mousemove', move); 
		document.removeEventListener('mouseup', end);
		document.removeEventListener('mouseleave', end); 
	}.bind(this);
	
	document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', end);
    document.addEventListener('mouseleave', end); 	// not working (but "mouseup" is triggered, even outside of the window)
};

WheelController.prototype.jog = function(offset) {
	this.state.wheelOffset = this.helpers.onClickOffset + offset;
	this.updateView();
	this.trigger('update');
	this.trigger('updateJog', {timeOffset : offset});
}

WheelController.prototype.varioSpeed = function(offset) {
	this.state.wheelOffset = Math.max(-1, (Math.min(1, (offset + this.helpers.onClickOffset) / 4)));
	this.updateView();
	this.trigger('update');
	this.trigger('updateVario', {varioSpeedValue : this.constants.varioRef - this.state.wheelOffset});
}

/**
 * keyboard
 */
WheelController.prototype.jogBack = function() { 	// keyboard : 1 step / keypress
	this.state.wheelOffset = this.state.wheelOffset - this.constants.keyToStep;
	this.updateView();
	this.trigger('update');
	this.trigger('updateJog', {timeOffset : - this.constants.keyToStep});
}
WheelController.prototype.jogForward = function() { 	// keyboard : 1 step / keypress
	this.state.wheelOffset = this.state.wheelOffset + this.constants.keyToStep;
	this.updateView();
	this.trigger('update');
	this.trigger('updateJog', {timeOffset : this.constants.keyToStep});
}
WheelController.prototype.varioBack = function() { 	// keyboard : 1 step / keypress
	this.state.wheelOffset = Math.max(-1, (Math.min(1, (this.state.wheelOffset + this.constants.keyToStep))));
	this.updateView();
	this.trigger('update');
	this.trigger('updateVario', {varioSpeedValue : this.constants.varioRef + this.state.wheelOffset});
}
WheelController.prototype.varioForward = function() { 	// keyboard : 1 step / keypress
	this.state.wheelOffset = Math.max(-1, (Math.min(1, (this.state.wheelOffset - this.constants.keyToStep))));
	this.updateView();
	this.trigger('update');
	this.trigger('updateVario', {varioSpeedValue : this.constants.varioRef + this.state.wheelOffset});
}

WheelController.prototype.reset = function(save) {
	if (save)
		this.state.savedOffset = Number(this.state.wheelOffset);
	this.state.wheelOffset = 0;
	this.updateView();
}

WheelController.prototype.setBackJogWheel = function() {
	this.state.wheelOffset = this.state.savedOffset;
	this.updateView();
}

module.exports = WheelController;
