"use strict";
/**
 * @def ColorSwatchComponent
 * @isGroup true
 * 
 * @CSSify styleName : ColorSwatchComponentHost/true
 * @CSSify styleName : ColorSwatchComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var InnerReactiveComponent = require('src/UI/generics/InnerReactiveComponent');
var CreateStyle = require('src/core/GenericStyleConstructor');

var ColorSwatchComponentDef = function(uniqueID, options, model) {

	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": "input[type=color]",
		"display": "inline-block",
		"border": "0",
		"paddingInlineStart": "0",
		"paddingInlineEnd": "0",
		"background": "transparent",
		"width": "17px",
		"height": "17px",
		"padding": "0",
		"borderRadius": "6px",
		"verticalAlign": "sub",
		"boxSizing": "border-box",
		"WebkitAppearance": "none",
		"cursor": "_pointer",
		"pointerEvents": "none"
	},
	{
		"selector": "input[type=color]::-webkit-color-swatch, input[type=color]::-moz-color-swatch, input[type=color]::-webkit-color-swatch-wrapper",
		"border": "none",
		"padding": "0",
		"border-radius": " 6px"
	},
	{
		"selector": ":host",
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "space-evenly",
		"padding": "1px",
		"border": "1px #99999900 solid",
		"borderWidth": "1px 0 1px 1px",
		"borderRadius": "6px 0 0 6px",
		"boxSizing": "border-box"
	},
	{
		"selector": "span",
		"display": "inline-block",
		"padding": "0px 1px",
		"pointerEvents": "none"
	},
	{
		"selector": ":host(:nth-child(3))",
		"borderWidth": "1px 1px 1px 0",
		"borderRadius": "0 6px 6px 0"
	},
	{
		"selector": "label",
		"cursor": "pointer",
		"pointerEvents": "none"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ColorSwatchComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'color-swatch',
		targetSlotIndex : 0,
		templateNodeName : 'span',
		attributes : [
			{title : '-ColorSwatch' + TypeManager.UIDGenerator.newUID()}
		],
		states : [
			{selected : undefined}
		],
		props : [
			{contentToList : undefined},
			{updateChannel : undefined}
		],
		reactOnSelf : [
			{
				from : 'updateChannel',
				cbOnly : true,
				subscribe : function(value) {
//					console.log(this._key, this.propToAcquireFromDBType, value[this._key][this.propToAcquireFromDBType], value);
					value = this.adaptDBTypedValue(value);
					
//					console.log('color-swatch', 'reactOnSelf', value);
					
//					var fontSize;
//					if (value.fontScale)
//						fontSize = Math.round(value.fontScale[this._key]);
//					else if (value.fontSize)
//						fontSize = Math.round(value.fontSize);
//					else
//						return;

//					this.view.styleHook.setTextInputFontSize(fontSize);
//					this.view.styleHook.setTextInputFontFamily(value.fontFamily);
					
					// contentToList automatically creates a span in the label node
					this.streams.contentToList.value = [value];
					// setContentFromValueOnMemberView is the abstract version of the above "streamed" update mmethod
					this.setContentFromValueOnMemberView(value, 1);
				}
			},
			{
				from : 'contentToList',
				cbOnly : true,
				subscribe : InnerReactiveComponent.prototype.updateTargetView
			}
		]
		,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = ColorSwatchComponentDef;