"use strict";
/**
 * @def RotativeSlidingPanel
 * @isGroup true
 * 
 * @CSSify styleName : RotativeSlidingPanelHost/true
 * @CSSify styleName : RotativeSlidingPanelTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var RotativeSlidingPanelDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host, div, section, aside, header, ul, li",
		"margin": "0",
		"padding": "0",
		"border": "0",
		"outline": "0",
		"background": "transparent",
		"verticalAlign": "baseline",
		"boxShadow": "none",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 0",
		"width": "100%",
		"height": "100%",
		"_padding": "7px"
	},
	{
		"selector": "header",
		"display": "none",
		"background": "#80808011",
		"textAlign": "center",
		"padding": "7px"
	},
	{
		"selector": "section",
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0"
	},
	{
		"selector": "aside",
		"_marginLeft": "7px"
	},
	{
		"selector": ":host scroll-slider",
		"display": "none"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) scroll-slider",
		"display": "flex"
	},
	{
		"selector": ".sliding_panel_shadow, .sliding_panel_inverse_shadow",
		"backgroundColor": "transparent",
		"position": "relative",
		"width": "99.9%",
		"flex": "0 0 7px",
		"zIndex": "2",
		"opacity": "0"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) .sliding_panel_shadow, :host([scrollbarghosted=\"unghosted\"]) .sliding_panel_inverse_shadow",
		"opacity": ".84"
	},
	{
		"selector": ".sliding_panel_shadow",
		"backgroundImage": "linear-gradient(to bottom, #010101AA 0%, #01010130 22%, #01010100 100%)"
	},
	{
		"selector": ".sliding_panel_inverse_shadow",
		"backgroundImage": "linear-gradient(to top, #010101AA 0%, #01010130 22%, #01010100 100%)"
	},
	{
		"selector": "ul",
		"backgroundImage": "linear-gradient(to bottom, #222222FF 0%, #4D4D4DFF 38%, #222222FF 100%)",
		"display": "flex",
		"flex": "1 1 0",
		"flexFlow": "column",
		"margin": "-7px 0px",
		"padding": "3px",
		"border": "1px inset #80808077",
		"borderRadius": "2px",
		"overflow": "hidden"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) ul",
		"backgroundColor": "#FFFFFF11",
		"border": "1px inset #808080AA"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'RotativeSlidingPanelHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = RotativeSlidingPanelDef;