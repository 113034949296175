"use strict";
/**
 * @factory TabPanelFactory
*/
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
// TODO: alreadyComposed is of no use
var TabPanel = function(def, parentView, parent, slotsCount, slotsDef) {
	var alreadyComposed = false;
	this.slotsCount = this.slotsCount || slotsCount || 2;
	this.slotsDef = this.slotsDef || slotsDef;

	Component.CompositorComponent.call(this, def, parentView, parent, alreadyComposed, this.slotsCount, this.slotsDef);
	
	this.objectType = 'TabPanel';
	
	// The this.typedSlots[0] solely has a schema : the this.typedSlots[1] is an evolution of the abstract class, it has a cSet as "self"
	// and a custom method (pushToSlotFromComponent()) to access it
	this.typedSlots[0].setSchema(['headerTitle']);
	this.typedSlots[1].setSchema(['panelTitle']);

} 
TabPanel.prototype = Object.create(Component.CompositorComponent.prototype);
TabPanel.prototype.objectType = 'TabPanel';
TabPanel.prototype.extendsCore = 'LazySlottedCompoundComponent';

//This shall be obtained by Dependancy Injection in the DependancyInjector file (index.js requires it, and it's kind of a mandatory include, so the DI should be permanent)
//(We strongly need the CompoundComponent ctor here... as soon as it exists, cause else we get a require loop)
//TabPanel.prototype = Component.ExtensibleObject.prototype.mergeOwnProperties(TabPanel.prototype, CompoundComponent.prototype);
// And again, Dependancy Injection (this shall be replaced by the actual CompoundComponent ctor)
//TabPanel.prototype.Compositor = {};


TabPanel.prototype.getHeaders = function() {
	return this._children[0];
}
TabPanel.prototype.getPanels = function() {
	return this._children[1];
}
TabPanel.prototype.getHeader = function(idx) {
	return this._children[0]._children[idx];
}
TabPanel.prototype.getLastHeader = function() {
	return this._children[0]._children[this._children[0]._children.length - 1];
}
TabPanel.prototype.getPanel = function(idx) {
	return this._children[1]._children[idx];
}
TabPanel.prototype.getLastPanel = function() {
	return this._children[1]._children[this._children[1]._children.length - 1];
}
TabPanel.prototype.getSmartTabsInPanel = function(idx) {
	return this._children[1]._children[idx]._children[0];
}
TabPanel.prototype.getSmartTabsInLastPanel = function() {
	return this._children[1]._children[this._children[1]._children.length - 1]._children[0];
}


module.exports = TabPanel;