"use strict";
/**
 * @constructor FontSamplerSetComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TuppleStreams = require('src/core/TuppleStreams');

var AbstractSamplerSetComponent = require('src/UI/packages/setsForPanels/AbstractSamplerSetComponent/AbstractSamplerSetComponent');

var createSpecializedTypedListComponentHostDef = require('src/UI/generics/SpecializedTypedListComponent/packageComponentDefs/SpecializedTypedListComponentHostDef');

var createFontSamplerSetComponentHostDef = require('src/UI/packages/setsForPanels/FontSamplerSetComponent/packageComponentDefs/FontSamplerSetComponentHostDef');
var createFontSamplerSetComponentSlotsDef = require('src/UI/packages/setsForPanels/FontSamplerSetComponent/packageComponentDefs/FontSamplerSetComponentSlotsDef');

var FontSamplerSetComponent = function(definition, parentView, parent, listItemsCount, fontStepsCount) {
	this.listItemNodeName = 'fontsamplers-set';
	this.listItemMembersCount = 1;
	this.listItemMemberType = 'FontScaleComponent';
	this.fontStepsCount = fontStepsCount;
	
	this.slotsDefFactory = createFontSamplerSetComponentSlotsDef;
	
	AbstractSamplerSetComponent.call(this, definition, parentView, parent, this.listItemMembersCount, this.fontStepsCount);
	this.objectType = 'FontSamplerSetComponent';
}
FontSamplerSetComponent.prototype = Object.create(AbstractSamplerSetComponent.prototype);
FontSamplerSetComponent.prototype.objectType = 'FontSamplerSetComponent';
FontSamplerSetComponent.prototype.extends = 'AbstractSamplerSetComponent';

FontSamplerSetComponent.prototype.createDefaultDef = function() {
	var def = createFontSamplerSetComponentHostDef();
	def.getHostDef().nodeName = 'fontsamplers-set';
	return def;
}

FontSamplerSetComponent.prototype.getCustomStreams = function() {
	var fontScalesGetters = [
			new TuppleStreams.LogScaleStream('LogScaleStream')
		];
	return fontScalesGetters;
}


module.exports = FontSamplerSetComponent;