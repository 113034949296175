"use strict";
/**
 * @def SliderComponentWithSwatch
 * @isGroup true
 * 
 * @CSSify styleName : SliderComponentWithSwatchHost
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var SliderComponentWithSwatchDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"position": "relative",
		"display": "flex",
		"alignItems": "center",
		"top": "1.5%",
		"width": "100%",
		"height": "39px",
		"margin": "12px 0px 0px 0px",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host(:nth-child(n+4))",
		"margin": "0px"
	},
	{
		"selector": ".slider-horizontal, button, slider-handle",
		"background": "transparent",
		"display": "flex",
		"cursor": "pointer",
		"outline": "0",
		"padding": "0",
		"border": "0",
		"textDecoration": "none",
		"boxSizing": "border-box"
	},
	{
		"selector": ".slider-horizontal",
		"position": "relative",
		"backgroundColor": "#EEE",
		"height": "4px",
		"margin": "0px 7px",
		"flex": "1 1 0",
		"border": "1px #CCC solid",
		"borderRadius": "3px"
	},
	{
		"selector": "slider-handle",
		"backgroundColor": "#999",
		"position": "absolute",
		"width": "13px",
		"height": "13px",
		"marginTop": "-5px",
		"border": "1px outset #777",
		"borderRadius": "6.5px",
		"zIndex": "2"
	},
	{
		"selector": "slider-handle:hover, slider-handle[hovered]",
		"backgroundColor": "#999"
	},
	{
		"selector": "button.triangle-right",
		"width": "0",
		"height": "0",
		"margin": "1px 1px 0.5px 1px",
		"borderLeft": "8px solid #EEE",
		"borderRight": "4px solid transparent",
		"borderBottom": "4px solid transparent"
	},
	{
		"selector": "button.triangle-left",
		"width": "0",
		"height": "0",
		"margin": "1px 1px 0.5px 1px",
		"borderRight": "8px solid #EEE",
		"borderLeft": "4px solid transparent",
		"borderBottom": "4px solid transparent"
	},
	{
		"selector": "button.triangle-right:hover",
		"borderTopColor": "#FFF"
	},
	{
		"selector": "button.triangle-left:hover",
		"borderBottomColor": "#FFF"
	},
	{
		"selector": "label",
		"position": "absolute",
		"display": "block",
		"marginTop": "-38px",
		"padding": "2px"
	},
	{
		"selector": "input",
		"display": "inline-block",
		"width": "27px",
		"height": "27px",
		"border": "1px inset #888",
		"borderRadius": "5px"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'SliderComponentWithSwatchHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'slider-input',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	return moduleDef;
}

module.exports = SliderComponentWithSwatchDef;