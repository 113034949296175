"use strict";
/**
 * @constructor FontScaleSamplerComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TuppleStreams = require('src/core/TuppleStreams');

var SpecializedTypedListComponent = require('src/UI/generics/SpecializedTypedListComponent/SpecializedTypedListComponent');
var createSpecializedTypedListComponentHostDef = require('src/UI/generics/SpecializedTypedListComponent/packageComponentDefs/SpecializedTypedListComponentHostDef');

var createFontScaleSamplerComponentHostDef = require('src/UI/packages/minimal/FontScaleSamplerComponent/packageComponentDefs/FontScaleSamplerComponentHostDef');

var FontScaleSamplerComponent = function(definition, parentView, parent, listItemMembersCount) {
	this.listItemNodeName = 'fontswatchs-sampler';
	this.listItemMembersCount = listItemMembersCount || 8;
	this.listItemMemberType = 'FontSwatchComponent';
	
	SpecializedTypedListComponent.call(this, definition, parentView, parent);
	this.objectType = 'FontScaleSamplerComponent';
	
	this.streams.colorGetter = new TuppleStreams.LinearScaleStream('LinearScaleStream');
	
}
FontScaleSamplerComponent.prototype = Object.create(SpecializedTypedListComponent.prototype);
FontScaleSamplerComponent.prototype.objectType = 'FontScaleSamplerComponent';
FontScaleSamplerComponent.prototype.extends = 'SpecializedTypedListComponent';

FontScaleSamplerComponent.prototype.createDefaultDef = function() {
	var def = createSpecializedTypedListComponentHostDef();
	def.getHostDef().nodeName = 'colorset-viewer';
	return def;
}

module.exports = FontScaleSamplerComponent;