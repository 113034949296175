"use strict";
/**
 * @constructor IFrameTitleComponent
 * 
 * This component may serve as an exemple for any reactively populated text component
 * that we want to "style"
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createIFrameTitleComponentHostDef = require('src/UI/packages/titles/IFrameTitleComponent/packageComponentDefs/IFrameTitleComponentHostDef');
//var createIFrameTitleComponentSlotsDef = require('src/UI/packages/titles/IFrameTitleComponent/packageComponentDefs/IFrameTitleComponentSlotsDef');

var IFrameTitleComponent = function(definition, parentView, parent) {
	Components.ComponentWithReactiveText.call(this, definition, parentView, parent);
	this.objectType = 'IFrameTitleComponent';
}
IFrameTitleComponent.prototype = Object.create(Components.ComponentWithReactiveText.prototype);
IFrameTitleComponent.prototype.objectType = 'IFrameTitleComponent';

IFrameTitleComponent.prototype.createDefaultDef = function(componentDef) {
	var def = TypeManager.createComponentDef({
			props : [
//				{text : undefined}
				{content : undefined}
			],
			reactOnSelf : [
				{to : 'content', cbOnly : true, subscribe : this.appendTextFromValueOnView},
				{from : 'text', to : 'content'}
			]
	});
	
	// Sort of a Hack, to reduce the risk of errors for the user:
	// In case of a component choosing not to define a "text"" prop
	// but rather, for example, reactOnParent directly to "content"
	if (!componentDef.getHostDef().props.hasObjectByKey('text')) {
		var textProp = new TypeManager.propsModel({text : undefined});
		def.getHostDef().props.push(textProp);
		def.getHostDef().streams.push(textProp);
	}
	
	var defaultHostDef = createIFrameTitleComponentHostDef().getHostDef();
	def.getHostDef().nodeName = defaultHostDef.nodeName;
	def.getHostDef().isCustomElem = defaultHostDef.isCustomElem;
	def.getHostDef().sWrapper =  defaultHostDef.sWrapper;

	return def;
}

module.exports = IFrameTitleComponent;