"use strict";
/**
 * @constructor TypedListBuilderComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var TypedListComponent = require('src/UI/generics/TypedListComponent');

var chroma = require('src/functionals/chroma');

var createTypedListBuilderComponentHostDef = require('src/UI/packages/structs/TypedListBuilderComponent/packageComponentDefs/TypedListBuilderComponentHostDef');
var createTypedListBuilderComponentSlotsDef = require('src/UI/packages/structs/TypedListBuilderComponent/packageComponentDefs/TypedListBuilderComponentSlotsDef');

var TypedListBuilderComponent = function(definition, parentView, parent, componentCount) {
	this.componentCount = (typeof componentCount === 'number' && componentCount) || 1;
	var stdDef = (definition && definition.getGroupHostDef()) ? definition : createTypedListBuilderComponentHostDef();
	(stdDef.getGroupHostDef() || stdDef.getHostDef()).section = (definition.getGroupHostDef() || definition.getHostDef()).section;
	
	TypedListComponent.call(this, stdDef, parentView, parent);
	this.objectType = 'TypedListBuilderComponent';
	
	this.typedSlots[0].setSchema(['updateChannel']);
	
//	console.log(this, parentView);
}
TypedListBuilderComponent.prototype = Object.create(TypedListComponent.prototype);
TypedListBuilderComponent.prototype.objectType = 'TypedListBuilderComponent';
TypedListBuilderComponent.prototype.extends = 'TypedListComponent';

//TypedListBuilderComponent.defaultDef = {
//	nodeName : 'section',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}
//
//TypedListBuilderComponent.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			TypedListBuilderComponent.defaultDef,
//			'TypedListBuilderComponentDefaultDef',
//			'rootOnly'
//		);
//}

TypedListBuilderComponent.prototype.defineHostedComponentDef = function(hostedTypeAsString, colorStepsCount, optionalSpecificDefForStyle) {
	
	var self = this;
	
	if (!hostedTypeAsString)
		return;

	if (colorStepsCount)
		this.componentCount = colorStepsCount;

	var componentDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : hostedTypeAsString === 'ColorSwatchComponent' ? 'FlexRowComponent' : 'FlexColumnComponent',
			nodeName : hostedTypeAsString === 'ColorSwatchComponent' ? 'colorswatch-steps' : 'fontswatch-steps',
			props : [
				{updateChannel : undefined}
			],
			sWrapper : createTypedListBuilderComponentSlotsDef(null, {type : hostedTypeAsString}).objectListDef.getHostDef().sWrapper
		})
	});		// don't cache, as the members are Components, and MUST give a new default def obj at each iteration
	// , hostedType + 'cachedPairsListDef'
//	console.log(createTypedListBuilderComponentSlotsDef());
	
	for (let i = 0; i < this.componentCount; i++) {
		componentDef.members.push(TypeManager.createComponentDef({
			type : hostedTypeAsString,
			reactOnParent : [
				{
					from : 'updateChannel',
					cbOnly : true,
					// CAUTION : memberAt(1) shall become memberAt(2) with 1 picto, memberAt(3) with 2 pictos, etc.
					subscribe : function(value) { 
						if (hostedTypeAsString === 'FontSwatchComponent') {
							this.streams.updateChannel.value = value;
						}
						else if (!value) {
							console.warn('bad value received for a stream:', value, 'Returning...');
							return;
						}
						else if (hostedTypeAsString === 'ColorSwatchComponent' && typeof value.correctLightness === 'undefined') {
							console.warn('bad colorScale received as value of a stream:', value, 'Returning...');
							return;
						}
						else if (hostedTypeAsString !== 'FontSwatchComponent') {
							this.view.subViewsHolder.memberAt(1).value = value.colors(self.componentCount)[this._key];
							this.streams.contentToList.value = [value.colors(self.componentCount)[this._key]];
						}
					}
				}
			],
			sWrapper : optionalSpecificDefForStyle ? optionalSpecificDefForStyle().getHostDef().sWrapper : null
		}));
	}

//	console.log(componentDef);
	this.useRowDef(componentDef);
}

TypedListBuilderComponent.prototype.useRowDef = function(def) {
	this.typedSlots[0].defaultListDef.getHostDef().template = def;
}

module.exports = TypedListBuilderComponent;