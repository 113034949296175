"use strict";
/**
 * @def SelectInputSlots
 * @isGroup false
 * 
 * @CSSify styleName : SelectInputHeader
 * @CSSify styleName : SelectInputSection
 * @CSSifyTheme themeName : basic-light
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var SelectInputSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */ 		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	var optionDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'VaritextButton',
			nodeName : 'option',
			props : [
				{optionTitle : undefined}
			],
			reactOnSelf : [
				{
					from : 'optionTitle',
					to : 'content'
				}
			]/**@CSSify Style componentStyle : SelectInputHeader */
		}, null, 'hostOnly')
	}, null, 'rootOnly');
	
//	var sectionDef = TypeManager.createComponentDef({
//		host : TypeManager.createComponentDef({
//			type : 'ComponentWithView',
//			nodeName : 'pseudoslot-panel'/**@CSSifyStyle componentStyle : SelectInputSection */
//		}, null, 'hostOnly')
//	}, null, 'rootOnly');
//	
//	
	
	return {
		optionDef : optionDef
	};
}

module.exports = SelectInputSlotsDef;