"use strict";
/**
 * @constructor LabelledButton
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var labelledButtonInterface = require('src/UI/_mixins/labelledButtonInterface');

var createLabelledButtonHostDef = require('src/UI/packages/minimal/LabelledButton/packageComponentDefs/LabelledButtonHostDef');
//var createLabelledButtonSlotsDef = require('src/UI/packages/minimal/LabelledButton/packageComponentDefs/LabelledButtonSlotsDef');

var LabelledButton = function(definition, parentView, parent) {
	Components.ComponentWithHooks.call(this, definition, parentView, parent);
	this.objectType = 'LabelledButton';
}
LabelledButton.prototype = Object.create(Components.ComponentWithHooks.prototype);
LabelledButton.prototype.objectType = 'LabelledButton';

LabelledButton = Components.ExtensibleObject.prototype.addInterface(LabelledButton, labelledButtonInterface);

LabelledButton.prototype.createDefaultDef = function() {
	return createLabelledButtonHostDef();
}

module.exports = LabelledButton;