"use strict";
/**
 * @factory ComponentsFactory
 * 
 */

var TypeManager = require('src/core/TypeManager');
var ComponentsDictionnary = require('src/UI/generics/_interestingComponents');

module.exports = function(componentType, inheritsFrom, interfaces, defaultDef) {
	var component = function() {
		ComponentsDictionnary[inheritsFrom].apply(this, arguments);
		this.objectType = componentType;
	}
	component.prototype = Object.create(ComponentsDictionnary[inheritsFrom].prototype);
	component.prototype.objectType = componentType;
	
	if (interfaces) {
		interfaces = Array.isArray(interfaces) ? interfaces : [interfaces];
		interfaces.forEach(function(inter) {
			component = Component.ExtensibleComponent.prototype.addInterface(component, inter);
		});	
	}
	
	if (defaultDef) {
		component.prototype.createDefaultDef = function() {return TypeManager.createComponentDef(defaultDef, this.__proto__.objectType);};
		return component;
	}
	
	return component;
}