"use strict";
/**
 * @constructor InnerReactiveComponent
 * This is an abstract implementation
*/


var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');



var EachOnTheBeachComponent = function(definition, parentView, parent) {
//	console.log(this.view, parentView);
	Component.ComponentWithReactiveText.call(this, definition, parentView, parent);
//	console.log('EachOnTheBeachComponent', this.view);
	this.objectType = 'EachOnTheBeachComponent';
}
EachOnTheBeachComponent.prototype = Object.create(Component.ComponentWithReactiveText.prototype);
EachOnTheBeachComponent.prototype.objectType = 'EachOnTheBeachComponent';

EachOnTheBeachComponent.prototype.createDefaultDef = function() {
	// The "contentToList/errors/what you want" stream only calls a callback : this.updateTargetView, and pass it the array of nodes to create and append to the declared "slot"
	return TypeManager.createComponentDef({
		UID : 'ZZ',
		templateNodeName : 'p',
		targetSlotIndex : 1,
		reactOnParent : [
			{
				cbOnly : true,
				from : 'errors',
				subscribe : this.updateTargetView
			}
		]
	}, 'EachOnTheBeachComponent');
}

EachOnTheBeachComponent.prototype.updateTargetView = function(contentArray) {
	if (!Array.isArray(contentArray))
		return;
	
	if (this.shallUpdate(contentArray)) {
		this.setContentFromCacheOnTargetSubview();
		this.trigger('update', this.targetSubViewContentCache);
	}
	else if (!contentArray.length)
		this.reset();
}

EachOnTheBeachComponent.prototype.shallUpdate = function(contentArray) {
	if (Array.isArray(contentArray) && contentArray.length)
		return this.dirtyCheckTargetViewContent(contentArray);
}

EachOnTheBeachComponent.prototype.dirtyCheckTargetViewContent = function(contentArray) {
	if (!contentArray || !contentArray.length)
		return this.resetTargetSubViewContent();
	var exists = 0;
	contentArray.forEach(function(val) {
		(this.targetSubViewContentCache.indexOf(val) !== -1 && exists++);
	}, this);
	return (
			(!this.targetSubViewContentCache.length || exists !== this.targetSubViewContentCache.length)
					&& (this.targetSubViewContentCache = contentArray)
			);
}



module.exports = EachOnTheBeachComponent;