"use strict";
/**
 * @consructor BinarySlice
 */





var BinarySlice = function(start, length) {
	this.start = start;
	this.length= length;
}
BinarySlice.prototype = {};


















module.exports = BinarySlice;