"use strict";
/**
 * @constructor FontSwatchComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TagChipComponent = require('src/UI/packages/minimal/TagChipComponent/TagChipComponent');

var labelledFontInputInterface = require('src/UI/_mixins/labelledFontInputInterface');
var dbFedComponentInterface = require('src/UI/_mixins/dbFedComponentInterface');

var createFontSwatchComponentHostDef = require('src/UI/packages/minimal/FontSwatchComponent/packageComponentDefs/FontSwatchComponentHostDef');
//var createFontSwatchComponentSlotsDef = require('src/UI/packages/minimal/FontSwatchComponent/packageComponentDefs/FontSwatchComponentSlotsDef');

var FontSwatchComponent = function(definition, parentView, parent) {
	TagChipComponent.call(this, definition, parentView, parent);
	this.objectType = 'FontSwatchComponent';
	
	this.propToAcquireFromDBType = 'family';
}

FontSwatchComponent.prototype = Object.create(TagChipComponent.prototype);
FontSwatchComponent.prototype.objectType = 'FontSwatchComponent';
//console.log(FontSwatchComponent.prototype._asyncInitTasks);
//FontSwatchComponent.prototype._asyncInitTasks.splice(0, 1); // already queued viewExtension in TagChipComponent
FontSwatchComponent = Components.ExtensibleObject.prototype.addInterface(FontSwatchComponent, labelledFontInputInterface);
FontSwatchComponent = Components.ExtensibleObject.prototype.addInterface(FontSwatchComponent, dbFedComponentInterface);

//FontSwatchComponent.defaultDef = {
//	nodeName : 'font-swatch',
//	targetSlotIndex : 0,
//	templateNodeName : 'span',
//	states : [
//		{selected : undefined}
//	],
//	props : [
//		{contentToList : undefined}
//	],
//	reactOnSelf : [
//		{
//			from : 'contentToList',
//			cbOnly : true,
//			subscribe : FontSwatchComponent.prototype.updateTargetView
//		}
//	]
//}
//console.log(createFontSwatchComponentHostDef);
//if (!FontSwatchComponent.defaultDef.sWrapper)
//	Object.assign(FontSwatchComponent.defaultDef, {sWrapper : createFontSwatchComponentHostDef().getHostDef().sWrapper});

FontSwatchComponent.prototype.createDefaultDef = function() {
//	var def = TypeManager.createComponentDef(
//		
//	);
//	def.getHostDef().attributes.push(new TypeManager.attributesModel({title : '-FontSwatch' + TypeManager.UIDGenerator.newUID()}));
	return createFontSwatchComponentHostDef();
}

FontSwatchComponent.prototype.handleClickEvent = function(e) {
//	console.log(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))));
//	if (e.target === this.view.getWrappingNode().children[1]) {
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this)))).handleClickEvent.call(this, e);
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))).handleClickEvent.call(this, e);
//	}
}



module.exports = FontSwatchComponent;