"use strict";
/**
 * @constructors dedicated components
 */

var CoreTypes = require('src/core/CoreTypes');

var AGDef = require('src/UI/defs/_arias&glyphsDef');
var JSkeyboardMap = require('src/events/JSkeyboardMap');

var TypeManager = require('src/core/TypeManager');
var ComponentsFactory = require('src/UI/generics/ComponentsFactory');
var ComponentsDictionnary = require('src/UI/generics/_interestingComponents');

/*
 *  Some extension & overriding possibilities, to show some parts of the "component" mechanics 
 *  	=> in a perf quest, we should write ad-hoc components : the "afterRegisterEvents" hook would then not be required
 *  	these defs shall be available for the componentGroup ctor by placing this file in the src/UI/dedicated/ folder...
 */

//var VaritextButtonDef = ComponentsDictionnary.SimpleTextReplace.prototype.createDefaultDef.call(ComponentsDictionnary.InViewInjectionStrategies.prototype);
var VaritextButtonDef =  {nodeName : 'dummy'};
var picto_DeleteDef = {nodeName : 'dummy', attributes : [{ariaHidden : true}], states : [{'delete' : undefined}]};

var VaritextButton = ComponentsFactory('VaritextButton', 'SimpleTextReplace', null, VaritextButtonDef);
VaritextButton.prototype.createDefaultDef = ComponentsDictionnary.SimpleTextReplace.prototype.createDefaultDef;
VaritextButton.prototype.registerClickEvents = function() {
	this.view.getMasterNode().addEventListener('mousedown', this.handleClickEvent.bind(this));
}
VaritextButton.prototype.handleClickEvent = function(e) {
	e.stopPropagation();
	this.trigger('update', {grandParent_key : this._parent._parent._key, parent_key : this._parent._key, self_depth : this.getSelfDepth(), self_key : this._key, self_UID : this._UID}, true);
}







var picto_Delete = ComponentsFactory('picto_Delete', 'VisibleStateComponent', null, picto_DeleteDef);

picto_Delete.prototype.registerClickEvents = function() {
	this.view.getMasterNode().firstChild.addEventListener('mousedown', this.handleClickEvent.bind(this));
}
picto_Delete.prototype.handleClickEvent = function(e) {
	e.stopPropagation();
	this.trigger('update', {remove : this._parent._key}, true);
}








var VaritextButtonWithPictoFirst = function(def, parentView, parent) {
	
	ComponentsDictionnary.VisibleStateComponent.call(this, def, parentView, parent);
	this.objectType = 'VaritextButtonWithPictoFirst';
}
/**
 * @chained_inheritance VaritextButtonWithPictoFirst <= VisibleStateComponent <= VaritextButton
 */
var proto_proto = Object.create(VaritextButton.prototype);
Object.assign(proto_proto, ComponentsDictionnary.VisibleStateComponent.prototype);
VaritextButtonWithPictoFirst.prototype = Object.create(proto_proto);
VaritextButtonWithPictoFirst.prototype.objectType = 'VaritextButtonWithPictoFirst';

VaritextButtonWithPictoFirst.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			states : [
				{expanded : undefined}
			],
			props : [
				{content : undefined}
			],
			reactOnSelf : [
				{to : 'content', cbOnly : true, subscribe : this.appendContentFromValueOnView}
			]
	}, 'VaritextButtonWithPictoFirst');
}

VaritextButtonWithPictoFirst.prototype.handleClickEvent = function(e) {
	Object.getPrototypeOf(Object.getPrototypeOf(this)).handleClickEvent.call(this, e);
	if (e.target === this.view.getRoot().children[3] || (this.view.getRoot().children.length > 4 && e.target === this.view.getRoot().children[4]))
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))).handleClickEvent.call(this, e);
}

VaritextButtonWithPictoFirst.prototype.simulateClick = function() {
	this.trigger('clicked_ok');
	this.trigger('update', {self_UID : this._UID}, true);
}

VaritextButtonWithPictoFirst.prototype.basicLateViewExtend = function(definition) {
	var glyphDef = AGDef.getGlyphs(this.__proto__.objectType);
	if (glyphDef === null)
		return;

	var state;
	
	var nodeDef = TypeManager.createComponentDef({
		nodeName : 'span',
		attributes : [
			{hidden : 'hidden'},
			{className : null}
		]
	}, 'glyphClass');
	
	// unshift'ing the pictos is virtualy handled by using the appendContentFromValueOnView callback on the simili-SimpleTextReplace injection
	for (let glyphName in glyphDef) {
		state = glyphName.slice(5).toLowerCase();				// glyphSomething

		if (state in CoreTypes.commonStates && definition.getHostDef().states.findObjectByKey(state) !== false) {
			nodeDef.getHostDef().attributes[1].className = 'glyphicon ' + glyphDef[glyphName];
			this.addReactiveMemberViewFromFreshDef(definition, nodeDef, state);
		}
		state = glyphName.slice(8);								// glyphNotsomething
		if (state in CoreTypes.commonStates && definition.getHostDef().states.findObjectByKey(state) !== false) {
			nodeDef.getHostDef().attributes[1].className = 'glyphicon ' + glyphDef[glyphName]; 
			this.addReactiveMemberViewFromFreshDef(definition, nodeDef, 'Not' + state);
		}
	}
}








var ClassNameReactiveComponent = ComponentsFactory('ClassNameReactiveComponent', 'ComponentWithView', null, {
	props : [
		{className : undefined}
	],
	reactOnSelf : [
		{
			cbOnly : true,
			from : 'className',
			subscribe : function(value) {
				this.view.getMasterNode().className = value ? value : null;
			}
		}
	]
});


var KeyboardSubmittableTodoInput = ComponentsFactory('KeyboardSubmittableTodoInput', 'KeyboardSubmittableInput');
KeyboardSubmittableTodoInput.prototype.handleKeyboardEvent = function(e) {
	e.stopPropagation();

	if (e.keyCode === JSkeyboardMap.indexOf('ENTER')) {
		this.trigger('update', {action : 'newtodo', value : e.target.value.trim()}, true);
		e.target.value = '';
	}
	return;
}


module.exports = {
		VaritextButton : VaritextButton,
		VaritextButtonWithPictoFirst : VaritextButtonWithPictoFirst,
		picto_Delete : picto_Delete,
		ClassNameReactiveComponent : ClassNameReactiveComponent,
		KeyboardSubmittableTodoInput : KeyboardSubmittableTodoInput
};