"use strict";
/**
 * @def CanvasBasedJSONTreeComponentSlots
 * @isGroup false
 * 
 * @CSSify styleName : CanvasBasedJSONTreeComponentHeader
 * @CSSify styleName : CanvasBasedJSONTreeComponentSection
 * @CSSifyTheme themeName : basic-light
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var CanvasBasedJSONTreeComponentSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */ 		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	var secondSlotDef = TypeManager.createComponentDef({
			type : 'VisiblyTypedKeyValuePair',
			states : [
				{selected : undefined},
				{leafInTree : undefined}
			],
			reactOnParent : [
				{
					from : 'selected',
					cbOnly : true,
					subscribe : function(value) {this.streams.selected.value = value === this._UID ? 'selected' : null;}
				}
			]/**@CSSify Style componentStyle :JSONTreeLeaf */
		}, 'jsonTreeLeafTemplate');
	
	
	return secondSlotDef;
	
	
	
//	return {
//		headerDef : headerDef,
//		sectionDef : sectionDef
//	};
}

module.exports = CanvasBasedJSONTreeComponentSlotsDef;