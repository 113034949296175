"use strict";
/**
 * @def FrameRateVisualizerComponent
 * @isGroup true
 * 
 * @CSSify styleName : FrameRateVisualizerComponentHost
 * @CSSify styleName : FrameRateVisualizerComponentTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var FrameRateVisualizerComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"position": "absolute",
		"display": "inline-block",
		"backgroundColor": "#1a2327",
		"right": "29px",
		"top": "5px",
		"padding": "7px 24px",
		"borderRadius": "7px",
		"border": "1px #383F50 solid"
	},
	{
		"selector": ":host p",
		"fontSize": "13px",
		"lineHeight": "19px",
		"color": "#CCCCCC",
		"margin": "0"
	},
	{
		"selector": ":host p:nth-child(2)",
		"fontSize": "16px"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'FrameRateVisualizerComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'CompoundComponent',
			nodeName : 'FrameRateVisualizer'.toLowerCase() + '-component',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'p',
				props : [
					{text : 'Global Real-time Rendering performance :'}
				]
			}),
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'p',
				props : [
					{text : '(=> Partial Rendering is not yet implemented)'}
				]
			}),
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'p',
				props : [
					{text : 'Style Solving Time: '}
				]
			}),
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'p',
				props : [
					{text : 'Total Rendering Time: '}
				]
			}),
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'p',
				props : [
					{text : 'Equivalent Frame Rate: '}
				]
			})
		]
	});
	
	return moduleDef;
}

module.exports = FrameRateVisualizerComponentDef;