"use strict";
/**
 * @def ComponentPickingInput
 * @isGroup true
 * 
 * @CSSify styleName : ComponentPickingInputHost/false
 * @CSSify styleName : ComponentPickingInputButton/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ComponentPickingInputDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var buttonStyles = [

	{
		"selector": ":host, div",
		"boxSizing": "border-box",
		"background": "none",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": ":host",
		"backgroundColor": "#3F3F3F",
		"display": "flex",
		"alignItems": "center",
		"justifyContent": "center",
		"width": "38px",
		"height": "36px",
		"border": "1px outset #777",
		"borderRadius": "3px"
	},
	{
		"selector": ":host(:nth-child(3))",
		"width ": "31px",
		"height": "36px"
	}

	];
	var buttonStylesUseCache = {
		use : false,
		nameInCache : 'ComponentPickingInputButtonStyles'
	}

	var hostStyles = [

	{
		"selector": ":host, div",
		"boxSizing": "border-box",
		"background": "none",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flex": "1 1 0",
		"alignItems": "center",
		"justifyContent": "space-between",
		"border": "1px solid #383838",
		"margin": "2px",
		"padding": "3px",
		"borderRadius": "2px"
	},
	{
		"selector": "label",
		"padding": "2px 7px"
	}

	];
	var hostStylesUseCache = {
		use : false,
		nameInCache : 'ComponentPickingInputHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
//			type : 'ComponentPickingInput',
			nodeName : 'picking-input',
			props : [
				{accepts : undefined},
				{title : undefined},
				{inChannel : undefined},
				{outChannel : undefined}
			],
			states : [],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'SimpleText',
				nodeName : 'label',
				reactOnParent : [
					{
						from : 'title',
						to : 'content'
					}
				]
			}),
			TypeManager.createComponentDef({
				type : 'VisibleStateComponent',
				nodeName : 'pad-in',
				props : [
					
				],
				sWrapper : CreateStyle(
						buttonStylesUseCache.use ? buttonStylesUseCache.nameInCache : null,
						buttonStyles
					)
			}),
			TypeManager.createComponentDef({
				type : 'FontSwatchComponent',
				nodeName : 'swatch-section',
//				props : [
//					{accepts : undefined}
//				],
				reactOnParent : [
//					{
//						from : 'accepts',
//						to : 'accepts'
//					},
					{
						from :  'inChannel',
						to : 'updateChannel',
						map : function(val) {return this.adaptDBTypedValue(val);}
					}
				]
			}),
//			TypeManager.createComponentDef({
//				host : TypeManager.createComponentDef({
//					type : 'TypedListBuilderComponent',
//					nodeName : 'section',
//					props : [
//						{accepts : undefined}
//					],
//					reactOnParent : [
//						{
//							from : 'accepts',
//							to : 'accepts'
//						},
//						{
//							from :  'inChannel',
//							cbOnly : true,
//							subscribe : function(value) {
//								var self = this;
//								this.streams.accepts.subscribe(function(val) {
//									console.log(val, self.typedSlots[0].newItem(value), value, self);
//									self.defineHostedComponentDef(val, 1);
//									self.typedSlots[0].push(
//										self.typedSlots[0].newItem(value)
//									);
//								})
////								var type = this.streams.accepts.value;
////								console.log(this.streams, type, value);
////								
////								this.defineHostedComponentDef(type, 1);
//////								this.typedSlots[0].push(
//////									this.typedSlots[0].newItem(value)
//////								);
//							}
//						}
//					]
//				})
//			}, null, 'rootOnly'),
			TypeManager.createComponentDef({
				type : 'VisibleStateComponent',
				nodeName : 'pad-out',
				props : [
					
				],
				sWrapper : CreateStyle(
						buttonStylesUseCache.use ? buttonStylesUseCache.nameInCache : null,
						buttonStyles
					)
			}),
		]
	}, null, 'rootOnly');
	
	
	return moduleDef;
}

module.exports = ComponentPickingInputDef;