"use strict";
/**
 * @constructor ColorSamplerSetComponent
*/

var TypeManager = require('src/core/TypeManager');
var CoreTypes = require('src/core/CoreTypes');
var Components = require('src/core/Component');

var AbstractSamplerSetComponent = require('src/UI/packages/setsForPanels/AbstractSamplerSetComponent/AbstractSamplerSetComponent');

var createColorSamplerSetComponentHostDef = require('src/UI/packages/setsForPanels/ColorSamplerSetComponent/packageComponentDefs/ColorSamplerSetComponentHostDef');
var createColorSamplerSetComponentSlotsDef = require('src/UI/packages/setsForPanels/ColorSamplerSetComponent/packageComponentDefs/ColorSamplerSetComponentSlotsDef');

var createColorSwatchComponentAlt_1Def = require('src/UI/packages/minimal/ColorSwatchComponent/packageComponentDefs/ColorSwatchComponentAltDef_no_label_&_big');

var ColorSamplerSetComponent = function(definition, parentView, parent, listItemsCount, colorStepsCount) {
	this.listItemNodeName = 'colorsamplers-set';
	this.listItemMembersCount = listItemsCount || 8;
	this.colorStepsCount = colorStepsCount || 8;
	this.slotsDefFactory = createColorSamplerSetComponentSlotsDef;
	
	AbstractSamplerSetComponent.call(this, definition, parentView, parent, this.listItemMembersCount, this.colorStepsCount);
	this.objectType = 'ColorSamplerSetComponent';
	
	console.log(this);
}

ColorSamplerSetComponent.prototype = Object.create(AbstractSamplerSetComponent.prototype);
ColorSamplerSetComponent.prototype.objectType = 'ColorSamplerSetComponent';
ColorSamplerSetComponent.prototype.extends = 'AbstractSamplerSetComponent';

ColorSamplerSetComponent.prototype.createDefaultDef = function() {
	var def = createColorSamplerSetComponentHostDef();
	def.getHostDef().nodeName = 'colorsamplers-set';
	return def;
}

module.exports = ColorSamplerSetComponent;