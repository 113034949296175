"use strict";
/**
 * @constructor BorderSetViewer
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var SpecializedTypedListComponent = require('src/UI/generics/SpecializedTypedListComponent/SpecializedTypedListComponent');
var createSpecializedTypedListComponentHostDef = require('src/UI/generics/SpecializedTypedListComponent/packageComponentDefs/SpecializedTypedListComponentHostDef');

var createBorderSetViewerHostDef = require('src/UI/packages/special/BorderSetViewer/packageComponentDefs/BorderSetViewerHostDef');

var BorderSetViewer = function(definition, parentView, parent) {
	this.listItemNodeName = 'borderswatchs-pair';
	this.listItemMembersCount = 6;
	this.listItemMemberType = 'BorderSwatchComponent';
	
	SpecializedTypedListComponent.call(this, definition, parentView, parent);
	this.objectType = 'BorderSetViewer';
}
BorderSetViewer.prototype = Object.create(SpecializedTypedListComponent.prototype);
BorderSetViewer.prototype.objectType = 'BorderSetViewer';
BorderSetViewer.prototype.extends = 'SpecializedTypedListComponent';

BorderSetViewer.prototype.createDefaultDef = function() {
	var def = createSpecializedTypedListComponentHostDef();
	def.getHostDef().nodeName = 'borderset-viewer';
	return def;
}



module.exports = BorderSetViewer;