"use strict";
/**
 * @def KeyValueList
 * @isGroup true
 * 
 * @CSSify hostName : keyValueListSlots
 * @CSSifyRule rule : host block
 * @CSSifyRule rule : tdth inlineBlock
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/core/GenericStyleConstructor');
var createKeyValueListSlotsDef = require('src/UI/packages_defs/structs/keyValueListSlotsDef');
var keyValueListHostStyles = require('src/UI/defs/extraStyles/keyValueListHost');



var keyValueListDef = function(uniqueID, options, model) {

		
	// Some CSS stuff (styles are directly injected in the main def below)
	var styles = [
/*@CSSifySlot*/
		];
//	var styleDef = CreateStyle('pseudo-slot-style', null, pseudoSlotsStyles);
	
	var slotDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'ComponentWithView',
			nodeName : 'pseudo-slot',
			section : 0,
			states : [
				{'slot-id' : undefined},
				{'is-embedded' : undefined},
				{'position' : undefined}
			],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			],
//			sWrapper : createKeyValueListSlotsDef().headerDef.getHostDef().sWrapper,
			sWrapper : CreateStyle('keyValueListSlotsStyles', null, styles).sWrapper
//			sWrapper : styleDef.sWrapper
		}, null, 'hostOnly')
	}, 'keyValueListSlotDef', 'rootOnly');

	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'key-value-list',
			sWrapper : CreateStyle('keyValueList', null, keyValueListHostStyles).sWrapper
		}),
		lists : [
			TypeManager.createComponentDef({
				type : 'ComponentList',
				template : slotDef
			})
		]
	}, 'keyValueListDef', 'rootOnly');
	
	return moduleDef;
}

keyValueListDef.__factory_name = 'keyValueListDef';
module.exports = keyValueListDef;