"use strict";
/**
 * @def ColorSetViewer
 * @isGroup true
 * 
 * @CSSify styleName : ColorSetViewerHost/true
 * @CSSify styleName : ColorSetViewerTemplate
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ColorSetViewerDef = function(uniqueID, options, model) {
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "column",
		"padding": "7px",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ColorSetViewerHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}, 'ColorSetViewerDefaultHostDef');
	
	return moduleDef;
}

module.exports = ColorSetViewerDef;