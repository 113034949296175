"use strict";
/**
 * @constructor Menu
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createMenuHostDef = require('src/UI/packages/setsForPanels/Menu/packageComponentDefs/MenuHostDef');
//var createMenuSlotsDef = require('src/UI/packages/setsForPanels/Menu/packageComponentDefs/MenuSlotsDef');

var Menu = function(definition, parentView, parent) {
	Components.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'Menu';
}
Menu.prototype = Object.create(Components.CompositorComponent.prototype);
Menu.prototype.objectType = 'Menu';
Menu.prototype.extendsCore = 'CompoundComponentWithHooks';

Menu.prototype.createDefaultDef = function() {
	return createMenuHostDef();
}

Menu.prototype.registerClickEvents = function() {
	this.view.currentViewAPI.getMasterNode().addEventListener('mouseout', function(e) {
		this.streams.open.value = false;
	}.bind(this));
	
//	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
//		if (!this.streams.open.value) {
////			this.showChildren();
////			this.streams.open.value = true;
//		}
//		else {
////			this.hideChildren();
////			this.streams.open.value = false;
//		}
//	}.bind(this));
}

//Menu.prototype.showChildren = function() {
//	this._children.forEach(function(child, key) {
//		if (key > 0)
//			child.streams.hidden.value = null;
//	}, this);
//}
//
//Menu.prototype.hideChildren = function() {
//	this._children.forEach(function(child, key) {
//		if (key > 0)
//			child.streams.hidden.value = 'hidden';
//	}, this);
//}

module.exports = Menu;