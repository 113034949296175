"use strict";
/**
 * @constructor KeyValueList
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var KeyValuePairComponent = require('src/UI/generics/KeyValuePairComponent');

var TabPanel = require('src/UI/packages/panels/TabPanel');
var createKeyValueListDef = require('src/UI/packages_defs/structs/keyValueListDef');
var createKeyValueListSlotsDef = require('src/UI/packages_defs/structs/keyValueListSlotsDef');


var KeyValueList = function(def, parentView, parent, slotsCount, slotsDef) {
	var stdDefinition = createKeyValueListDef();
	this.slotsDef = createKeyValueListSlotsDef();
	this.pseudoModel = [];
	this.listTemplate = TypeManager.createComponentDef({ type: 'ComponentList' });
	this.listTemplate.getHostDef().each = this.pseudoModel;
	
	TabPanel.call(this, stdDefinition, parentView, parent, slotsCount, slotsDef);
	this.objectType = 'KeyValueList';
}
KeyValueList.prototype = Object.create(TabPanel.prototype);
KeyValueList.prototype.objectType = 'KeyValueList';
KeyValueList.prototype.extends = 'TabPanel';

KeyValueList.prototype.acquireData = function(keyValueObjectsArray) {
	var component;
	this.addPairedItems('Key');
	this.pushToSlotFromText(0, 'Value');

	keyValueObjectsArray.forEach(function(item, key) {
		if (typeof item.value !== 'undefined') {
			this.getPanel(0).pushChild((component = new KeyValuePairComponent(TypeManager.createComponentDef({host : {}}), this.getPanel(0).view, this.getPanel(0))));
			TypeManager.dataStoreRegistry.setItem(component._UID, this.pseudoModel.length);
			this.pseudoModel.push({keyValuePair : [item.key + '&nbsp;:&nbsp;', item.value.toString()], displayedas: item.type});
		}
	}, this);
	
	this.typedSlots[1].childToFront(0);
	this.render();

//	this.pseudoModel.length = 0;
}
	

module.exports = KeyValueList;
