"use strict";
/**
 * @constructor DOMcontentPreview
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createDOMcontentPreviewHostDef = require('src/UI/packages/special/DOMcontentPreview/packageComponentDefs/DOMcontentPreviewHostDef');
//var createDOMcontentPreviewSlotsDef = require('src/UI/packages/special/DOMcontentPreview/packageComponentDefs/DOMcontentPreviewSlotsDef');

var DOMcontentPreview = function(definition, parentView, parent) {
	Components.ComponentWithView.call(this, definition, parentView, parent);
	this.objectType = 'DOMcontentPreview';
	
	window.DOMcontentPreviewComponent = this;
}
DOMcontentPreview.prototype = Object.create(Components.ComponentWithView.prototype);
DOMcontentPreview.prototype.objectType = 'DOMcontentPreview';

//DOMcontentPreview.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

DOMcontentPreview.prototype.createDefaultDef = function() {
	return createDOMcontentPreviewHostDef();
}

DOMcontentPreview.prototype.createUserDefinedComponent = defer`
	// console.log(DOMcontentPreviewComponent._children);
	var targetView = DOMcontentPreviewComponent.view.subViewsHolder.lastMember();
	DOMcontentPreviewComponent.removeAllChildren();
	var componentDef = ${null};
	//console.log(componentDef);
	var injectedComp = new App.componentTypes.CompoundComponent(componentDef, targetView, DOMcontentPreviewComponent);
	new App.DelayedDecoration();
	//console.log(injectedComp);`;

function defer([first, ...rest]) {
  return (...values) => rest.reduce((acc, str, i) => acc + values[i] + str, first);
}

module.exports = DOMcontentPreview;