"use strict";
/**
 * @constructor IncrementableInput
*/

var TypeManager = require('src/core/TypeManager');
var CoreTypes = require('src/core/CoreTypes');
var Components = require('src/core/Component');

var VisibleStateComponent = require('src/UI/Generics/VisibleStateComponent');
var Tooltip = require('src/UI/packages/minimal/Tooltip');

var labelledTextInputInterface = require('src/UI/_mixins/labelledTextInputInterface');
var typedInputInterface = require('src/UI/_mixins/typedInputInterface');

var createIncrementableInputHostDef = require('src/UI/packages/minimal/IncrementableInput/packageComponentDefs/IncrementableInputHostDef');
//var createIncrementableInputSlotsDef = require('src/UI/packages/minimal/IncrementableInput/packageComponentDefs/IncrementableInputSlotsDef');

var IncrementableInput = function(definition, parentView, parent) {
	Components.ComponentStrokeAware.call(this, definition, parentView, parent);
	this.objectType = 'IncrementableInput';
}
var proto_proto = Object.create(Components.ComponentStrokeAware.prototype);
Object.assign(proto_proto, VisibleStateComponent.prototype);
IncrementableInput.prototype = Object.create(proto_proto);
IncrementableInput.prototype.objectType = 'IncrementableInput';

IncrementableInput = Components.ExtensibleObject.prototype.addInterface(IncrementableInput, labelledTextInputInterface);
IncrementableInput = Components.ExtensibleObject.prototype.addInterface(IncrementableInput, typedInputInterface);

IncrementableInput.prototype._asyncInitTasks.push(new TypeManager.TaskDefinition({
	type : 'lateAddChild',
	task : function(definition) {
//			var effectiveViewIdx = TypeManager.viewsRegistry.length - 3;
			var basicDef = TypeManager.mockDef();
			new Tooltip(basicDef, this.view, this);
//			this.view.subViewsHolder.addMemberView(this._children[this._children.length - 1].view);
//			CoreTypes.ComponentSubViewsHolder.prototype.immediateAscendViewAFewStepsHelper.call(null, 19, effectiveViewIdx);
		}
	})
);

//IncrementableInput.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

IncrementableInput.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			createIncrementableInputHostDef(),
			'IncrementableInputDefaultDef'
		);
}

module.exports = IncrementableInput;