"use strict";
/**
 * @constructor ObjectPairsListBuilderComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var TypedListComponent = require('src/UI/generics/TypedListComponent');

var createObjectPairsListBuilderComponentHostDef = require('src/UI/packages/structs/ObjectPairsListBuilderComponent/packageComponentDefs/ObjectPairsListBuilderComponentHostDef');
var createObjectPairsListBuilderComponentSlotsDef = require('src/UI/packages/structs/ObjectPairsListBuilderComponent/packageComponentDefs/ObjectPairsListBuilderComponentSlotsDef');

var ObjectPairsListBuilderComponent = function(definition, parentView, parent) {
	var stdDef = createObjectPairsListBuilderComponentHostDef();
	(stdDef.getGroupHostDef() || stdDef.getHostDef()).section = (definition.getGroupHostDef() || definition.getHostDef()).section;
	
	TypedListComponent.call(this, stdDef, parentView, parent);
	this.objectType = 'ObjectPairsListBuilderComponent';
	
	this.typedSlots[0].setSchema(['labelTitle']);
	
//	console.log(this.typedSlots[0]);
}
ObjectPairsListBuilderComponent.prototype = Object.create(TypedListComponent.prototype);
ObjectPairsListBuilderComponent.prototype.objectType = 'ObjectPairsListBuilderComponent';
ObjectPairsListBuilderComponent.prototype.extends = 'TypedListComponent';

//ObjectPairsListBuilderComponent.defaultDef = {
//	nodeName : 'section',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}
//
//ObjectPairsListBuilderComponent.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			ObjectPairsListBuilderComponent.defaultDef,
//			'ObjectPairsListBuilderComponentDefaultDef',
//			'rootOnly'
//		);
//}

ObjectPairsListBuilderComponent.prototype.defineHostedComponentDef = function(dataType) {
	
	var hostedType, peerCount;
	switch (dataType) {
		case 'colorSet' : 
			hostedType = 'ColorSwatchComponent';
			peerCount = 2;
			break;
		case 'fontSet' : 
			hostedType = 'FontSwatchComponent';
			peerCount = 2;
			break; 
		case 'borderSet' : 
			hostedType = 'BorderSwatchComponent';
			peerCount = 6;
			break; 
	}

	var componentDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'CompoundComponent',
			nodeName : 'tagchip-pair',
			props : [
				{labelTitle : undefined}
			],
			sWrapper : createObjectPairsListBuilderComponentSlotsDef().objectPairDef.getHostDef().sWrapper
		})
	});		// don't cache, as the members are Components, and MUST give a new default def obj at each iteration
	// , hostedType + 'cachedPairsListDef'
//	console.log(createObjectPairsListBuilderComponentSlotsDef());
	
	for (let i = 0; i < peerCount; i++) {
		componentDef.members.push(TypeManager.createComponentDef({
			type : hostedType,
			reactOnParent : [
				{
					from : 'labelTitle',
					cbOnly : true,
					// CAUTION : memberAt(1) shall become memberAt(2) with 1 picto, memberAt(3) with 2 pictos, etc.
					subscribe : function(value) {
						if (hostedType !== 'BorderSwatchComponent')
							this.view.subViewsHolder.memberAt(1).value = value[this._key];
						else {
							this.streams.styleSetter.value = value[this._key];
						}
						this.streams.contentToList.value = [value[this._key]];
						this.view.getMasterNode().title = value[this._key];
						
						if (hostedType === 'FontSwatchComponent') {
							var CSS = 'input {font-family: ' + value[this._key] + '}';
							var styleElem = document.createElement('style');
							styleElem.innerHTML = CSS;
							this.view.getRoot().prepend(styleElem);
						}
					}
				}
			]
		}));
	}

	
	this.useRowDef(componentDef);
//	this.render(null, this.typedSlots[0].defaultListDef.getHostDef());
}

ObjectPairsListBuilderComponent.prototype.useRowDef = function(def) {
//	console.log(this.typedSlots[0]);
	this.typedSlots[0].defaultListDef.getHostDef().template = def;
}

module.exports = ObjectPairsListBuilderComponent;