"use strict";
/**
 * @def slidingPanel
 * 
 * @CSSify styleName : SlidingPanelHost/true
 * @CSSifyRule rule : host flexBoxRow/flexGrow/padding
 * @CSSifyRule rule : section ghosted/flexBoxColumn/flexGrow
 * @CSSifyRule rule : aside ghosted
 */



var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');

var panelDef = function(uniqueId, noStylesheet) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host, div, section, aside, header, ul, li",
		"margin": "0",
		"padding": "0",
		"border": "0",
		"outline": "0",
		"background": "transparent",
		"verticalAlign": "baseline",
		"boxShadow": "none",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 0",
		"width": "100%",
		"height": "100%",
		"padding": "7px",
		"overflow": "hidden"
	},
	{
		"selector": "header",
		"display": "none",
		"background": "#2e3138",
		"textAlign": "center",
		"padding": "7px"
	},
	{
		"selector": "section",
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0"
	},
	{
		"selector": "aside"
	},
	{
		"selector": ":host scroll-slider",
		"opacity": "0"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) scroll-slider",
		"opacity": "1"
	},
	{
		"selector": ".sliding_panel_shadow, .sliding_panel_inverse_shadow",
		"backgroundColor": "transparent",
		"position": "relative",
		"width": "99.9%",
		"flex": "0 0 9px",
		"zIndex": "2",
		"opacity": "0"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) .sliding_panel_shadow, :host([scrollbarghosted=\"unghosted\"]) .sliding_panel_inverse_shadow",
		"opacity": ".84"
	},
	{
		"selector": ".sliding_panel_shadow",
		"backgroundImage": "linear-gradient(to bottom, #80808044 0%, #80808022 22%, #80808000 100%)"
	},
	{
		"selector": ".sliding_panel_inverse_shadow",
		"backgroundImage": "linear-gradient(to top, #80808024 0%, #80808015 22%, #80808000 100%)"
	},
	{
		"selector": "ul",
		"display": "flex",
		"flex": "1 1 0",
		"flexFlow": "column",
		"margin": "-9px 0px",
		"overflow": "hidden"
	},
	{
		"selector": "li",
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0"
	},
	{
		"selector": ":host([scrollbarghosted=\"unghosted\"]) ul",
		"backgroundColor": "#1a2327",
		"border": "1px inset #383838"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'SlidingPanelHostStyles'
	}
		
	
	var moduleDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});
	
	
	return moduleDef;
}

module.exports = panelDef;