"use strict";
/**
 * @constructor SubmitButton
 * 
*/
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var typedInputInterface = require('src/UI/_mixins/typedInputInterface');

var createSubmitButtonDef = require('src/UI/packages_defs/minimal/submitButtonDef');
//	console.log(createSubmitButtonDef);

var SubmitButton = function() {
	Component.ComponentWithHooks.apply(this, arguments);
	this.objectType = 'SubmitButton';
}
SubmitButton.prototype = Object.create(Component.ComponentWithHooks.prototype);
SubmitButton.prototype.objectType = 'SubmitButton';

SubmitButton = Component.ExtensibleObject.prototype.addInterface(SubmitButton, typedInputInterface);

SubmitButton.prototype.createEvents = function() {
	this.createEvent('clicked_ok');
}

SubmitButton.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			nodeName : 'button',
			attributes : [
				{title : '-Submit'}
			],
			props : [
//				{text : undefined},
				{content : undefined}
			],
			reactOnSelf : [
				{
					cbOnly : true,
					to	 : 'content',
					subscribe : this.setContentFromValueOnView,
				},
				{
					from : 'text',
					to : 'content',
				}
			],
			sWrapper : createSubmitButtonDef().getHostDef().sWrapper
	}, 'SubmitButton');
}

SubmitButton.prototype.afterCreateDOM = function() {
//	this.view.getMasterNode()['value'] = this.def.title;
//	if (this.def.sWrapper && this.def.sWrapper.styleElem)
//		this.view.getMasterNode().appendChild(this.def.sWrapper.styleElem);
}

SubmitButton.prototype.afterRegisterEvents = function() {
//	console.log(this._parent)
	var self = this;
	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
		self.trigger('clicked_ok');
	});
	this.onclicked_ok = this._parent.trigger.bind(this._parent, 'submit');
}
	

module.exports = SubmitButton;