"use strict";
/**
 * @library DOMresolver
 */


var DOMelementTypes = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/DOMsubModules/DOMelementTypes');
var DocumentCloner = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/DOMsubModules/DocumentCloner');















var extendStatics = function(d, b) {
    extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return extendStatics(d, b);
};

function __extends(d, b) {
    extendStatics(d, b);
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
}

var __assign = function() {
    __assign = Object.assign || function __assign(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

function __awaiter(thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
}

function __generator(thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
}





// Copy from CSStypesResolver.js (The one and only reference to external vars in the current file)
var CHECKBOX = 'checkbox';
var RADIO = 'radio';



/*
 * START_OF
 * src/dom/node-parser.ts
 */

var LIST_OWNERS = ['OL', 'UL', 'MENU'];

var createContainer = function (element) {
    if (isImageElement(element)) {
        return new DOMelementTypes.ImageElementContainer(element);
    }
    if (isCanvasElement(element)) {
        return new DOMelementTypes.CanvasElementContainer(element);
    }
    if (isSVGElement(element)) {
        return new DOMelementTypes.SVGElementContainer(element);
    }
    if (isLIElement(element)) {
        return new DOMelementTypes.LIElementContainer(element);
    }
    if (isOLElement(element)) {
        return new DOMelementTypes.OLElementContainer(element);
    }
    if (isInputElement(element)) {
        return new DOMelementTypes.InputElementContainer(element);
    }
    if (isSelectElement(element)) {
        return new DOMelementTypes.SelectElementContainer(element);
    }
    if (isTextareaElement(element)) {
        return new DOMelementTypes.TextareaElementContainer(element);
    }
    if (isIFrameElement(element)) {
        return new DOMelementTypes.IFrameElementContainer(element);
    }
    return new DOMelementTypes.ElementContainer(element);
};







var parseNodeTree = function (node, parent, root) {
    
    var childNode = node.firstChild || node.shadowRoot, nextNode;
    for (childNode, nextNode = void 0; childNode; childNode = nextNode) {

        if (isShadowRootNode(childNode))
            childNode = childNode.children[0];
        else
            nextNode = childNode.nextSibling;

        if (isStyleElement(childNode)) {
            childNode = childNode.nextSibling;
            nextNode = childNode.nextSibling;
        }
        else if (isTextNode(childNode) && childNode.data.trim().length > 0) {
            parent.textNodes.push(new DOMelementTypes.TextContainer(childNode, parent.styles));
        }

        if (isElementNode(childNode) || isInputElement(childNode)) {
            var container = createContainer(childNode);
            if (container.styles.isVisible()) {
                if (createsRealStackingContext(childNode, container, root)) {
                    container.flags |= 4 /* CREATES_REAL_STACKING_CONTEXT */;
                }
                else if (createsStackingContext(container.styles)) {
                    container.flags |= 2 /* CREATES_STACKING_CONTEXT */;
                }
                if (LIST_OWNERS.indexOf(childNode.tagName) !== -1) {
                    container.flags |= 8 /* IS_LIST_OWNER */;
                }
                parent.elements.push(container);
                if (!isTextareaElement(childNode) && !isSVGElement(childNode) && !isSelectElement(childNode)) {
                    parseNodeTree(childNode, container, root);
                }
            }
        }
    }
};


var parseTree = function (element) {
    var container = createContainer(element);
    container.flags |= 4 /* CREATES_REAL_STACKING_CONTEXT */;
    parseNodeTree(element, container, container);
    return container;
};








var createsRealStackingContext = function (node, container, root) {
    return (container.styles.isPositionedWithZIndex() ||
        container.styles.opacity < 1 ||
        container.styles.isTransformed() ||
        (isBodyElement(node) && root.styles.isTransparent()));
};


var createsStackingContext = function (styles) {
	return styles.isPositioned() || styles.isFloating();
};







var isTextNode = function (node) { return node.nodeType === Node.TEXT_NODE; };
var isElementNode = function (node) { return node.nodeType === Node.ELEMENT_NODE; };
var isShadowRootNode = function (node) { return node.host && node.hasChildNodes() && node.children[0].getRootNode({composed : false}) === node;};
var isHTMLElementNode = function (node) {
    return isElementNode(node) && typeof node.style !== 'undefined' && !isSVGElementNode(node);
};
var isSVGElementNode = function (element) {
    return typeof element.className === 'object';
};
var isLIElement = function (node) { return node.tagName === 'LI'; };
var isOLElement = function (node) { return node.tagName === 'OL'; };
var isInputElement = function (node) { return node.tagName === 'INPUT'; };
var isHTMLElement = function (node) { return node.tagName === 'HTML'; };
var isSVGElement = function (node) { return node.tagName === 'svg'; };
var isBodyElement = function (node) { return node.tagName === 'BODY'; };
var isCanvasElement = function (node) { return node.tagName === 'CANVAS'; };
var isImageElement = function (node) { return node.tagName === 'IMG'; };
var isIFrameElement = function (node) { return node.tagName === 'IFRAME'; };
var isStyleElement = function (node) { return node.tagName === 'STYLE'; };
var isScriptElement = function (node) { return node.tagName === 'SCRIPT'; };
var isTextareaElement = function (node) { return node.tagName === 'TEXTAREA'; };
var isSelectElement = function (node) { return node.tagName === 'SELECT'; };

var isTextInputElement = function (container) {
    if (container instanceof DOMelementTypes.TextareaElementContainer) {
        return true;
    }
    else if (container instanceof DOMelementTypes.SelectElementContainer) {
        return true;
    }
    else if (container instanceof DOMelementTypes.InputElementContainer && container.type !== RADIO && container.type !== CHECKBOX) {
        return true;
    }
    return false;
};




/*
 * END_OF
 * src/dom/node-parser.ts
 */






module.exports = {
	resolveStyleFromDOMnode : parseTree
} 