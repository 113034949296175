"use strict";
/**
 * @constructor PIXIRendererComponent
*/

//var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var appConstants = require('src/appLauncher/appLauncher');

var createPIXIRendererComponentHostDef = require('src/UI/packages/special/PIXIRendererComponent/packageComponentDefs/PIXIRendererComponentHostDef');
//var createPIXIRendererComponentSlotsDef = require('src/UI/packages/special/PIXIRendererComponent/packageComponentDefs/PIXIRendererComponentSlotsDef');

var PIXIRendererComponent = function(definition, parentView, parent) {
	Components.ComponentWithHooks.call(this, definition, parentView, parent);
	this.objectType = 'PIXIRendererComponent';
	
	this.createRenderer();
	this.stage;
	this.boundingBox = {
		w : 0,
		h : 0
	};
}
PIXIRendererComponent.prototype = Object.create(Components.ComponentWithHooks.prototype);
PIXIRendererComponent.prototype.objectType = 'PIXIRendererComponent';

PIXIRendererComponent.prototype.createDefaultDef = function() {
	return createPIXIRendererComponentHostDef();
}

PIXIRendererComponent.prototype.beforeRegisterEvents = function() {
	this.createRenderer();
	
	var parentDOMNode = this.view.parentView.getMasterNode();
	
	appConstants.resizeObserver.observe(parentDOMNode, this.setCanvasDimensions.bind(null, parentDOMNode, this));
}

PIXIRendererComponent.prototype.setCanvasDimensions = function(parentDomNode, self, e) {
	
	self.boundingBox.w = e.data.boundingBox.w - 21;		// Prepare for a potential browser-scrollbar if the canva-s height is greater than the window's height
	self.boundingBox.h = e.data.boundingBox.h;

	if (e.data.boundingBox.w !== 0) {
		appConstants.resizeObserver.unobserve(parentDomNode);
		self.view.getWrappingNode().appendChild(self.renderer.view);
	}
	
	self.renderer.resize(self.boundingBox.w, self.boundingBox.h);
	
	
}


PIXIRendererComponent.prototype.createRenderer = function() {
	if (typeof PIXI === 'undefined') {
		console.warn('The PIXIRendererComponent requires the Pixi.js to be loaded in the global scope');
		return;
	}
	
	PIXI.settings.ROUND_PIXELS = true;
	
	this.renderer = new PIXI.Renderer({
//		width : bounds.width,			// dynamically defined (via resizeObserver)
//		height : bounds.height,			// dynamically defined (via resizeObserver)
		transparent : true,
		backgroundAlpha : 0.05,			// not working
		antialias : true,
		autoResize: true
	});
}

/**
 * @method createAndPopulateStage
 * @param {Object} shapesList
 */
PIXIRendererComponent.prototype.createAndPopulateStage = function(shapesList) {
	// shapesList is a hash-map
	this.createStage();
	this.populateStage(shapesList);
}

PIXIRendererComponent.prototype.createStage = function() {
	this.stage = new PIXI.Container();
}

PIXIRendererComponent.prototype.populateStage = function(shapesList) {
	for (var UID in shapesList) {
//		console.log(shapesList[UID].shape);	//._geometry.graphicsData[0]
		this.stage.addChild(shapesList[UID].shape);
	}
}

PIXIRendererComponent.prototype.renderStage = function(shapesList) {
	this.renderer.render(this.stage);
}

PIXIRendererComponent.prototype.adaptHeightToContainer = function(containerHeight) {
	if (containerHeight > this.renderer.height)
		this.renderer.resize(this.boundingBox.w, containerHeight);
}









module.exports = PIXIRendererComponent;