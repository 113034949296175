"use strict";
/**
 * @constructor VisualSetComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

//var createVisualSetComponentHostDef = require('src/UI/generics/VisualSetComponent/packageComponentDefs/VisualSetComponentHostDef');
//var createVisualSetComponentSlotsDef = require('src/UI/generics/VisualSetComponent/packageComponentDefs/VisualSetComponentSlotsDef');

var VisualSetComponent = function(definition, parentView, parent) {
	Components.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'VisualSetComponent';
}
VisualSetComponent.prototype = Object.create(Components.CompositorComponent.prototype);
VisualSetComponent.prototype.objectType = 'VisualSetComponent';
VisualSetComponent.prototype.extendsCore = 'CompoundComponent';

VisualSetComponent.defaultDef = {
		host : TypeManager.createComponentDef({
			nodeName : 'visual-set'
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly')
		]
}

VisualSetComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			VisualSetComponent.defaultDef,
			'VisualSetComponentDefaultDef',
			'rootOnly'
		);
}

module.exports = VisualSetComponent;