"use strict";
/**
 * @constructor ColorSetViewer
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var SpecializedTypedListComponent = require('src/UI/generics/SpecializedTypedListComponent/SpecializedTypedListComponent');
var createSpecializedTypedListComponentHostDef = require('src/UI/generics/SpecializedTypedListComponent/packageComponentDefs/SpecializedTypedListComponentHostDef');

var createColorSetViewerHostDef = require('src/UI/packages/special/ColorSetViewer/packageComponentDefs/ColorSetViewerHostDef');

var ColorSetViewer = function(definition, parentView, parent) {
	this.listItemNodeName = 'colorswatchs-pair';
	this.listItemMembersCount = 2;
	this.listItemMemberType = 'ColorSwatchComponent';
	
	SpecializedTypedListComponent.call(this, definition, parentView, parent);
	this.objectType = 'ColorSetViewer';
	
//	console.log(definition, this);
	
}
ColorSetViewer.prototype = Object.create(SpecializedTypedListComponent.prototype);
ColorSetViewer.prototype.objectType = 'ColorSetViewer';
ColorSetViewer.prototype.extends = 'SpecializedTypedListComponent';

ColorSetViewer.prototype.createDefaultDef = function() {
	var def = createSpecializedTypedListComponentHostDef();
	def.getHostDef().nodeName = 'colorset-viewer';
	return def;
}



module.exports = ColorSetViewer;