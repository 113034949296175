"use strict";
/**
 * @def scrollSlider
 * 
 * @CSSify styleName : ScrollSliderHost/true
 * 
 */

var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');

var scrollSliderDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "column",
		"alignItems": "center",
		"top": "1.5%",
		"height": "100%",
		"width": "15px",
		"boxSizing": "border-box"
	},
	{
		"selector": ".slider-vertical, button, slider-handle",
		"background": "transparent",
		"display": "flex",
		"cursor": "pointer",
		"outline": "0",
		"padding": "0",
		"border": "0",
		"textDecoration": "none",
		"boxSizing": "border-box"
	},
	{
		"selector": ".slider-vertical",
		"position": "relative",
		"backgroundColor": "#1a2327",
		"width": "7px",
		"margin": "2px 0px",
		"flex": "1 1 0",
		"border": "1px #383838 solid",
		"borderRadius": "3px"
	},
	{
		"selector": "slider-handle",
		"backgroundColor": "#999",
		"position": "absolute",
		"width": "5px",
		"height": "127px",
		"marginTop": "1px",
		"borderRadius": "3px",
		"zIndex": "2"
	},
	{
		"selector": "slider-handle:hover, slider-handle[hovered]",
		"backgroundColor": "#999"
	},
	{
		"selector": "button.triangle-up",
		"width": "0",
		"height": "0",
		"margin": "1px 1px 1px 0.5px",
		"borderLeft": "4px rgba(0,0,0,0) solid",
		"borderRight": "4px rgba(0,0,0,0) solid",
		"borderBottom": "8px solid #B3B3B3"
	},
	{
		"selector": "button.triangle-down",
		"width": "0",
		"height": "0",
		"margin": "1px 1px 1px 0.5px",
		"borderLeft": "4px rgba(0,0,0,0) solid",
		"borderRight": "4px rgba(0,0,0,0) solid",
		"borderTop": "8px solid #B3B3B3"
	},
	{
		"selector": "button.triangle-down:hover",
		"borderBlockStartColor": "#FFF"
	},
	{
		"selector": "button.triangle-up:hover",
		"borderBlockEndColor": "#FFF"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ScrollSliderHostStyles'
	}
	
	var moduleDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	});

	return moduleDef;
}

module.exports = scrollSliderDef;