"use strict";
/**
 * 
 * @CSSify hostName : abstractTableSlots
 * @CSSifyRule rule : host flexBoxRow
 * @CSSifyRule rule : tdth inlineBlock/three_columns
 * @CSSifyRule rule : tdthspecial special_table
 */


module.exports = function() {
	return [
/*@CSSifySlot*/
			]
};