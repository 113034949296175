"use strict";
/**
 * @constructor ExtensibleTable
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var SpinnerComponent = require('src/UI/generics/SpinnerComponent');

var createExtensibleTableDef = require('src/UI/generics_defs/extensibleTableDef');
var createExtensibleTableSlotsDef = require('src/UI/generics_defs/extensibleTableSlotsDef');


var ExtensibleTable = function(def, parentView, parent, slotsCount, slotsDef) {
	var stdDefinition = createExtensibleTableDef();
	this.slotsDef = createExtensibleTableSlotsDef();

	if (def && def.getGroupHostDef().nodeName)
		stdDefinition = def;

	this.listTemplate = TypeManager.createComponentDef({ type: 'ComponentList' });
	this.listTemplate.getHostDef().each = this.pseudoModel;
	
	Component.CompositorComponent.call(this, stdDefinition, parentView, parent, slotsCount, slotsDef);
	this.pseudoModel = [];
	this.objectType = 'ExtensibleTable';
}
ExtensibleTable.prototype = Object.create(Component.CompositorComponent.prototype);
ExtensibleTable.prototype.objectType = 'ExtensibleTable';
ExtensibleTable.prototype.extendsCore = 'AbstractTable';

ExtensibleTable.prototype._asyncInitTasks = [];
ExtensibleTable.prototype._asyncInitTasks.push(new TypeManager.TaskDefinition({
	type : 'lateAddChild',
	task : function(definition) {
			var basicDef = TypeManager.createComponentDef({type : 'emptyDef'}, 'SpinnerUsedByExtensibleTable');
			this.pushChild(new SpinnerComponent(basicDef, this.view, this));
			this.view.subViewsHolder.addMemberView(this._children[this._children.length - 1].view);
		}
	})
);


ExtensibleTable.prototype.pushToSlotFromText = function(slotNbr, content) {
	// Here, newItem() depends on the type given in the ctor... or afterwards with setSchema()
	this.typedSlots[slotNbr].push(this.typedSlots[slotNbr].newItem(content));
	
	if (slotNbr === 0) {
		var lastChild = this._children[0].getLastChild();
		lastChild.getMasterNode().addEventListener('mousedown', function(e) {
			this.trigger('header_clicked', {self_key : lastChild._key});
			this.typedSlots[1].sortForPropHostingArrayOnArrayIdx('rowContentAsArray', lastChild._key, lastChild.streams.sortedasc.value ? 'invert' : null);
			this._children[0].childButtonsSortedLoop(lastChild._key, lastChild.streams.sortedasc.value ? 'desc' : 'asc');
		}.bind(this));
	}
}

ExtensibleTable.prototype.pushApplyToSlot = function(slotNbr, contentAsArray) {
	var lastChildIndex = this._children[0]._children.length;

	// Here, newItem() depends on the type given in the ctor... or afterwards with setSchema()
	var cAsArray = contentAsArray.map(function(value, key) {
		if (typeof value !== 'object' || !(value instanceof this.typedSlots[slotNbr].Item))
			return this.typedSlots[slotNbr].newItem(value);
		else
			return value;
	}, this);

	this.typedSlots[slotNbr].pushApply(cAsArray);
	
	if (slotNbr === 0) {
		for (let i = lastChildIndex; i < this._children[0]._children.length; i++) {
			this._children[0]._children[i].view.getMasterNode().addEventListener('mousedown', function(e) {
				this.trigger('header_clicked', {self_key : this._children[0]._children[i]._key});
				this.typedSlots[1].sortForPropHostingArrayOnArrayIdx('rowContentAsArray', this._children[0]._children[i]._key, this._children[0]._children[i].streams.sortedasc.value ? 'invert' : null);
				this._children[0].childButtonsSortedLoop(this._children[0]._children[i]._key, this._children[0]._children[i].streams.sortedasc.value ? 'desc' : 'asc');
			}.bind(this));
		}
	}
}



ExtensibleTable.prototype.acquireData = function(keyValueObjectsArray) {
	var component;
	this.addPairedItems('Key');
	this.pushToSlotFromText(0, 'Value');

	keyValueObjectsArray.forEach(function(item, key) {
//		this.getPanel(0).pushChild((component = new KeyValuePairComponent(TypeManager.createComponentDef({host : {}}), this.getPanel(0).view, this.getPanel(0))));
//		TypeManager.dataStoreRegistry.setItem(component._UID, this.pseudoModel.length);
//		this.pseudoModel.push({keyValuePair : [item.key + ' :&nbsp;', item.value.toString()], displayedas: item.type});
	}, this);
	
//	this.typedSlots[1].childToFront(0);
	
//	this.render();

//	this.pseudoModel.length = 0;
}
	

module.exports = ExtensibleTable;
