"use strict";
/**
 * @def CanvasConnectedGraphNodeComponent
 * @isGroup true
 * 
 * @CSSify styleName : CanvasConnectedGraphNodeComponentHost
* @CSSify styleName : CanvasConnectedGraphNodeComponentBox
* @CSSify styleName : CanvasConnectedGraphNodeComponentCircle
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var CanvasConnectedGraphNodeComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	options = options || {handlesCount : 0};
	
	var moduleDef = TypeManager.createComponentDef({
			host : TypeManager.createComponentDef({
				nodeName : 'CanvasBased-CompoundBox'/**@CSSify Style componentStyle : CanvasConnectedGraphNodeComponentHost */
			}, null, 'hostOnly'),
			member : [
				TypeManager.createComponentDef({
					nodeName : 'CanvasBased-Box'/**@CSSify Style componentStyle : CanvasConnectedGraphNodeComponentBox */
				}, null, 'hostOnly')
			]
		});
		
	for (let i = 0, l = options.handlesCount; i < l; i++) {
		moduleDef.members.push(
			TypeManager.createComponentDef({
					nodeName : 'CanvasBased-Disc'/**@CSSify Style componentStyle : CanvasConnectedGraphNodeComponentCircle */
				}, null, 'hostOnly')
		);
	}
	
	return moduleDef;
}

module.exports = CanvasConnectedGraphNodeComponentDef;