"use strict";
/**
 * @def MenuOption
 * @isGroup true
 * 
 * @CSSify styleName : MenuOptionHost
 * @CSSify styleName : MenuOptionTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var DF = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var MenuOptionDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block",
		"position": "relative",
		"boxSizing": "border-box",
		"background": "#2e3138",
		"width": "177px",
		"border": "0",
		"padding": "2px",
		"zIndex": "21",
		"visibility": "hidden"
	},
	{
		"selector": ":host(:hover)",
		"background": "#404345"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'MenuOptionHostStyles'
	}
	
	
	var moduleDef = DF.createDef({
		host : DF.createDef({
			type : 'ComponentWithView',
			nodeName : 'menu-option',
//			states : [
//				{hidden : 'hidden'}
//			]
			reactOnParent : [
				{
					from : 'open',
					cbOnly : true,
					subscribe : function(val) {
						if (val === true) {
							this.view.styleHook.s.setProp(
								':host',
								'visibility',
								'visible'
							);
						}
						else {
							this.view.styleHook.s.setProp(
								':host',
								'visibility',
								'hidden'
							);
						}
					}
				}
			]
			,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		})
	});
	
	
	
	return moduleDef;
}

module.exports = MenuOptionDef;