"use strict";
/**
 * @constructor FileSelector
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var dbFedComponentInterface = require('src/UI/_mixins/dbFedComponentInterface');

var createFileSelectorHostDef = require('src/UI/packages/panels/FileSelector/packageComponentDefs/FileSelectorHostDef');
var createFileSelectorSlotsDef = require('src/UI/packages/panels/FileSelector/packageComponentDefs/FileSelectorSlotsDef');

var FileSelector = function(definition, parentView, parent) {
	this.slotsDef = createFileSelectorSlotsDef();
	
	Components.TypedListComponent.call(this, definition, parentView, parent);
	this.objectType = 'FileSelector';
	
//	this.propToAcquireFromDBType = 'name';
//	this.acquiredTypeIsArray = true;
	
	this.typedSlots[0].setSchema(['fileName']);	
	this.hiddenInput = this._children[0];
}
FileSelector.prototype = Object.create(Components.TypedListComponent.prototype);
FileSelector.prototype.objectType = 'FileSelector';
FileSelector.prototype.extends = 'TypedListComponent';

/*
 * Left here as a reminder : we could have done that way 
 * but the browser said it was causing a rendering flash.
 * From all points of view, it's better to define it 
 * in the definition of the component
*/
//FileSelector.prototype._asyncInitTasks = [];
//FileSelector.prototype._asyncInitTasks.push(new TypeManager.TaskDefinition({
//	type : 'lateAddChild',
//	task : function() {
//		
//		var inputDef = TypeManager.mockDef();
//		inputDef.getHostDef().attributes.push(new TypeManager.PropFactory({title : '-filename'}));
//		inputDef.getHostDef().sOverride = [
//			{
//				selector : 'input, label',
//				display : 'none'
//			}
//		];
//		
//		this.hiddenInput = new Components.TextInput(inputDef, this.view, this);
//	}
//}));

FileSelector.prototype.createEvents = function() {
	this.createEvent('clicked');
}

FileSelector.prototype.createDefaultDef = function() {
//	console.log(createFileSelectorHostDef());
	return createFileSelectorHostDef();
}

// The the click event is handled on the line component (which now is a VisibleStateComponent)
//FileSelector.prototype.afterRegisterEvents = function() {
//	var self = this, value, target;
//	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
//		if (e.originalTarget.nodeName === 'SPAN')
//				target = 1;
//		else if (e.originalTarget.nodeName === 'P')
//			target = 2;
//			
//		self._children.forEach(function(component) {	
//			if (component.objectType === 'SimpleText'
//				&& component.streams.selected.value === true)
//				component.streams.selected.value = null;
//		});
//		switch (target) {
//			case 1 : 
//				e.originalTarget.parentNode._component.streams.selected.value = true;
//				value = e.originalTarget.textContent;
//				break;
//			case 2 : 
//				e.originalTarget._component.streams.selected.value = true;
//				value = e.originalTarget.children[0].textContent;
//				break;
//		}
//		self.hiddenInput.setValue(value);
//	});
//}

//FileSelector.prototype.getValue = function() {
//	return this.hiddenInput.getValue();
//}

module.exports = FileSelector;