"use strict";
/**
 * @constructor CheckboxInput
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var CheckboxInput = function() {
	Components.ComponentWithReactiveText.apply(this, arguments);
	this.objectType = 'CheckboxInput';
}
CheckboxInput.prototype = Object.create(Components.ComponentWithReactiveText.prototype);
CheckboxInput.prototype.objectType = 'CheckboxInput';

CheckboxInput.prototype.createEvents = function() {
//	GenericComponent.prototype.createEvents.call(this);
	this.createEvent('checked');
}

CheckboxInput.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		nodeName : 'input',
		attributes : [
			{type : 'checkbox'}
		],
		props : [
			{checked : undefined}
		],
		reactOnSelf : [
			{from : 'checked', cbOnly : true, subscribe : function(value) {this.view.getMasterNode().checked = (value ? 'checked' : null);}}
		]
	}, null);
}

CheckboxInput.prototype.registerClickEvents = function() {
	var self = this;
	this.view.getMasterNode().addEventListener('change', function(e) {
		self.trigger('checked', {checked : self.view.getMasterNode().checked ? 'checked' : null});
	})
}








module.exports = CheckboxInput;