"use strict";
/**
 * @def treeLeafTemplate
 * @isGroup true
 * 
 * @CSSify styleName : AbstractTreeLeaf/true
 * @CSSifyRule rule : host block
 * @CSSifyRule rule : div_2ndChild pointer
 */


var TypeManager = require('src/core/TypeManager');

var CreateStyle = require('src/core/GenericStyleConstructor');


var treeLeafTemplateDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var leafStyles = [

	{
		"selector": "[hidden]",
		"visibility": "hidden"
	},
	{
		"selector": ":host",
		"marginLeft": "57px",
		"boxSizing": "border-box"
	},
	{
		"selector": "div:nth-child(3)"
	},
	{
		"selector": "div",
		"display": "inline-block"
	},
	{
		"selector": ":host(:hover) div:nth-child(3):before, :host(:hover) div:nth-child(3):after"
	},
	{
		"selector": ":host([selected]) div:nth-child(3)",
		"fontWeight": "bold"
	}

	];
	var leafStylesUseCache = {
		use : true,
		nameInCache : 'AbstractTreeLeafStyles'
	}
	
	var secondSlotDef = TypeManager.createComponentDef({
			type : 'KeyValuePairComponent',
			nodeName : 'key-value-pair',
			states : [
				{selected : undefined}
			],
			reactOnParent : [
				{
					from : 'selected',
					cbOnly : true,
					subscribe : function(value) {this.streams.selected.value = value === this._UID ? 'selected' : null;}
				}
			],
				sWrapper : CreateStyle(
						leafStylesUseCache.use ? leafStylesUseCache.nameInCache : null,
						leafStyles
					)
		}, 'KeyValuePair');
	
	
	return secondSlotDef;
}

treeLeafTemplateDef.__factory_name = 'treeLeafTemplateDef';
module.exports = treeLeafTemplateDef;