"use strict";
/**
 * @def ColorSwatchComponentAlt_1
 * @isGroup false
 * 
 * @CSSify styleName : ColorSwatchComponentAlt_a/true
 * @CSSifyTheme themeName : basic-light
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ColorSwatchComponentAlt_1Def = function(uniqueID, options, model) {

	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var alt_aStyles = [

	{
		"selector": "input[type=color]",
		"display": "inline-block",
		"border": "0",
		"paddingInlineStart": "0",
		"paddingInlineEnd": "0",
		"background": "transparent",
		"width": "29px",
		"height": "29px",
		"padding": "0",
		"borderRadius": "5px",
		"verticalAlign": "sub",
		"boxSizing": "border-box",
		"WebkitAppearance": "none",
		"cursor": "pointer"
	},
	{
		"selector": "input[type=color]::-webkit-color-swatch, input[type=color]::-moz-color-swatch, input[type=color]::-webkit-color-swatch-wrapper",
		"border": "none",
		"padding": "0",
		"border-radius": " 6px"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flex": "1 1 0",
		"padding": "3px",
		"_border": "1px #99999944 solid",
		"borderWidth": "1px",
		"borderRadius": "3px",
		"boxSizing": "border-box"
	},
	{
		"selector": "span",
		"display": "inline-block",
		"padding": "0px 1px",
		"pointerEvents": "none"
	},
	{
		"selector": "label",
		"display": "none"
	}

	];
	var alt_aStylesUseCache = {
		use : true,
		nameInCache : 'ColorSwatchComponentAlt_aStyles'
	}

	
	var def = TypeManager.createComponentDef({
		nodeName : 'color-swatch',
		targetSlotIndex : 0,
		templateNodeName : 'span',
		attributes : [
			{title : '-ColorSwatch' + TypeManager.UIDGenerator.newUID()}
		],
		states : [
			{selected : undefined}
		],
		props : [
			{contentToList : undefined},
			{updateChannel : undefined}
		],
		reactOnSelf : [
			{
				from : 'updateChannel',
				cbOnly : true,
				subscribe : function(value) {

//					var fontSize;
//					if (value.fontScale)
//						fontSize = Math.round(value.fontScale[this._key]);
//					else if (value.fontSize)
//						fontSize = Math.round(value.fontSize);
//					else
//						return;

//					this.view.styleHook.setTextInputFontSize(fontSize);
//					this.view.styleHook.setTextInputFontFamily(value.fontFamily);
					
					// contentToList automatically creates a span in the label node
					this.streams.contentToList.value = [value];
					// setContentFromValueOnMemberView is the abstract version of the above "streamed" update mmethod
					this.setContentFromValueOnMemberView(value, 1);
				}
			},
			{
				from : 'contentToList',
				cbOnly : true,
				subscribe : InnerReactiveComponent.prototype.updateTargetView
			}
		],
				sWrapper : CreateStyle(
						alt_aStylesUseCache.use ? alt_aStylesUseCache.nameInCache : null,
						alt_aStyles
					)
	});
	
	
	
	return def;
}

module.exports = ColorSwatchComponentAlt_1Def;