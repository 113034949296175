"use strict";
/**
 * 
 * @constructor ScrollSlider
 * 
*/



var appConstants = require('src/appLauncher/appLauncher');
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var ScrollSliderDef = require('src/UI/packages_defs/structs/scrollSliderDef');


var Slider = require('src/UI/generics/AbstractSlider');
var orientationDependantInterface = require('src/UI/_mixins/orientationDependantInterface');



	
var ScrollSlider = function(definition, parentView, parent) {
	this.limitExcursionToInner = true;
	this.orientation = 'vertical';
	this.min = 0;
	this.max = 100;
	this.step = 1;
	Slider.call(this, definition, parentView, parent);
	this.objectType = 'ScrollSlider';
	
//	var self = this;
//	this.addEventListener('change', function(e) {
//		self.streams.toggle.value = e.data === self._valueMax() ? true : null;
//		self.trigger('update', {toggle : self.streams.toggle.value});
//	});
}

/**
 * @chained_inheritance ScrollSlider <= AbstractSlider <= ComponentWithHooks
 */
var proto_proto = Object.create(Component.ComponentWithHooks.prototype);
Object.assign(proto_proto, Slider.prototype);
ScrollSlider.prototype = Object.create(proto_proto);
ScrollSlider.prototype.objectType = 'ScrollSlider';

//	ScrollSlider = Component.ExtensibleObject.prototype.addInterface(ScrollSlider, orientationDependantInterface);

ScrollSlider.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'scroll-slider',
			states : [
				{toggle : undefined}
			],
			sWrapper : ScrollSliderDef().getHostDef().sWrapper
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'button',
				attributes : [
					{'aria-label' : 'scroll upward'},
					{id : 'slider-action-up-' + TypeManager.UIDGenerator.newUID()},
					{className : 'triangle-up'}
				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'div',
				attributes : [
					{id : 'slider-track-' + TypeManager.UIDGenerator.newUID()},
					{className : 'slider-vertical'}
				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'button',
				attributes : [
					{'aria-label' : 'scroll downward'},
					{id : 'slider-action-down-' + TypeManager.UIDGenerator.newUID()},
					{className : 'triangle-down'}
				]
			}, null, 'hostOnly')
		]
	}, null, 'rootOnly');
}




ScrollSlider.prototype.registerClickEvents = function() {
	var self = this;
	this.view.subViewsHolder.memberViews[1].getMasterNode().addEventListener('mousedown', function(e) {
		e.stopPropagation();
		self._mouseStarted = true;
	});
	this.view.subViewsHolder.memberViews[3].getMasterNode().addEventListener('mousedown', function(e) {
		e.stopPropagation();
		self._mouseStarted = true;
		self.handleElem.setAttribute('hovered', 'hover');
		self._mouseDown(e);
	});
	document.addEventListener('mouseup', this._mouseUp.bind(this));
}

ScrollSlider.prototype._mouseUp = function(e) {
	if (this._mouseStarted) {
		if (this._mouseMovement)
			this.trigger('slide', {value : this._value});
		else {
			this._value = this._value !== this._valueMax() ? this._valueMax() : this._valueMin();
			if (this.streams.toggle) {
				this.handleElem.style.left = this._value === this._valueMax() ? this._maxPixelsInner.x.toString() + 'px' : 0;
				this.streams.toggle.value = this._value === this._valueMax() ? true : null;
				this.trigger('update', {toggle : this.streams.toggle.value});
			}
		}
		this.handleElem.removeAttribute('hovered');
		document.removeEventListener('mousemove', this._mouseMove);
	}
		
	this._mouseStarted = false;
	this._mouseMovement = false;
}
	

module.exports = ScrollSlider;