"use strict";
/**
 * @def textInput
 * 
 * @CSSify styleName : TextInputHost
 * 
 */

var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');

var textInputDef = function(uniqueID, options) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block"
	},
	{
		"selector": "label",
		"display": "inline-block",
		"width": "157px"
	},
	{
		"selector": "input",
		"color": "#EAEAEA",
		"background": "#1a2327",
		"height": "25px",
		"width": "345px",
		"border": "1px solid #444"
	},
	{
		"selector": "tooltip"
	},
	{
		"selector": ":host(:not([valid])) input",
		"outline": "2px ridge Orange"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'TextInputHostStyles'
	}
	
	var texTinputDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
	}); 
	return texTinputDef;
}

textInputDef.__factory_name = 'textInputDef';
var factory = textInputDef; // classFactory as the factory sets a unique DOM Id (we may then securely style or query the domElems, qualified with that "id").
module.exports = factory;