"use strict";
/**
 * @constructor BigModalBox
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createBigModalBoxHostDef = require('src/UI/packages/boxes/BigModalBox/packageComponentDefs/BigModalBoxHostDef');
//var createBigModalBoxSlotsDef = require('src/UI/packages/boxes/BigModalBox/packageComponentDefs/BigModalBoxSlotsDef');

var BigModalBox = function(definition, parentView, parent) {
	Components.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'BigModalBox';
}
BigModalBox.prototype = Object.create(Components.CompositorComponent.prototype);
BigModalBox.prototype.objectType = 'BigModalBox';
BigModalBox.prototype.extendsCore = 'CompoundComponent';


//BigModalBox.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

BigModalBox.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			Object.assign(BigModalBox.defaultDef, createBigModalBoxHostDef()),
//			'BigModalBoxDefaultDef'
//		);
	return createBigModalBoxHostDef();
}

module.exports = BigModalBox;