"use strict";
/**
 * @programmatic_validator for TextInput
 */

var Validate = require('src/UI/integrated_fork/Validate');

var validator = (function(uniqueID, options) {
	
	// Username validation
	var constraints = {
		length : {
			minimum : 0,
			message : '^The Username is optional.'
		}
	}
	
	return constraints;
})();

module.exports = validator;
//validator.__factory_name = 'usernameInputStdValidator';
//module.exports = Factory.Maker.getClassFactory(styleDef);