"use strict";
/**
 * @constructor VisiblyTypedKeyValuePair
*/

var TypeManager = require('src/core/TypeManager');
var KeyValuePairComponent = require('src/UI/generics/KeyValuePairComponent');

var createDefForStyles = require('src/ui/packages_defs/structs/visiblyTypedKeyValuePairDef');


var VisiblyTypedKeyValuePair = function() {
	KeyValuePairComponent.apply(this, arguments);
	this.objectType = 'VisiblyTypedKeyValuePair';
}
VisiblyTypedKeyValuePair.prototype = Object.create(KeyValuePairComponent.prototype);
VisiblyTypedKeyValuePair.prototype.objectType = 'VisiblyTypedKeyValuePair';

VisiblyTypedKeyValuePair.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'key-value-pair',
			states : [
				{"displayedas" : undefined}
			],
			props : [
				{keyValuePair : undefined}
			],
			reactOnSelf : [
					{
						from : 'keyValuePair',
						cbOnly : true,
						subscribe : this.setContentFromArrayOnEachMemberView
					}
			],
			sWrapper : createDefForStyles().getHostDef().sWrapper
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'div',
				attributes : [
					{className : 'glyphicon  icofont-file-stack'}
				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'div',
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'div',
			}, null, 'hostOnly')
		]
	}, 'VisiblyTypedKeyValuePair', 'rootOnly');
}

module.exports = VisiblyTypedKeyValuePair;