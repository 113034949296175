"use strict";
/**
 * @constructor PadController
*/


var VisibleStateComponent = require('src/UI/generics/VisibleStateComponent');

var PadControler = function(def) {
	VisibleStateComponent.call(this, def);
	
	this.objectType = 'PadControler';
	this.def = def;
	
	// TODO : refactor the "uniqueness" of the "learning" active state in a ComponentGroup by constructing the group as a circular linked list
	this.learning = false;
	this.keyboardEvents = def.keyboardSettings;

	this.init(def);
}

PadControler.prototype = Object.create(VisibleStateComponent.prototype);
PadControler.prototype.objectType = 'PadControler';

PadControler.prototype.createDOM = function(def) {
	this.domElem = $('<div/>', {'class' : def.buttonClassName + ' ' + def.className}).css(def.css);
	if (def.glyphInactive) {
		this.domElem.append($('<span/>', {'class' : def.glyphInactive}))
	}
	this.hoverElem = $('<div/>', {'class' : def.buttonClassName + '_hover ' + ' '  + def.className}).appendTo(this.domElem);
	this.command = def.command;
}

//PadControler.prototype.registerClickEvents = function() {
//	super.registerClickEvents();
//}

PadControler.prototype.toggleButton = function () {
	if (!this.toggle) {
		this.toggle = true;
		this.domElem.addClass('toggle');

		if (this.def.glyphActive){
			this.domElem.empty();
			this.domElem.append($('<span/>', {'class' : this.def.glyphActive}))
		}
	}
	else {
		this.toggle = false;
		this.domElem.removeClass('toggle');
		
		if (this.def.glyphInactive){
			this.domElem.empty();
			this.domElem.append($('<span/>', {'class' : this.def.glyphInactive}))
		}
	}
}


module.exports = PadControler;
