"use strict";
/**
 * @constructors TabPanelMember_header
 */

var TypeManager = require('src/core/TypeManager');
var ComponentsFactory = require('src/UI/generics/ComponentsFactory');
var ComponentsDictionnary = require('src/UI/generics/_interestingComponents');

var tabPanelMember_headerDef =  TypeManager.createComponentDef({
	host : TypeManager.createComponentDef({
		nodeName : 'header',
		// HACK: this is shit (should be an attribute, but not... should be a "DOM" attribute... -> setAttribute(). TODO: fix after re-implementation of _arias&glyphs)
		states : [
			{role : "heading"} 
		],
		props : [
			{headerTitle : undefined}
		],
		reactOnSelf : [
			{
				from : 'headerTitle',
				to : 'content'
			}
		]
	}, null, 'hostOnly')
}, null, 'rootOnly');

var TabPanelMember_header = ComponentsFactory('TabPanelMember_header', 'SimpleText', null, tabPanelMember_headerDef);
TabPanelMember_header.prototype.createDefaultDef = ComponentsDictionnary.SimpleText.prototype.createDefaultDef;
TabPanelMember_header.prototype.registerClickEvents = function() {
	this.view.getMasterNode().addEventListener('mousedown', this.handleClickEvent.bind(this));
}
TabPanelMember_header.prototype.handleClickEvent = function(e) {
	e.stopPropagation();
	this.trigger('update', {grandParent_key : this._parent._parent._key, parent_key : this._parent._key, self_depth : this.getSelfDepth(), self_key : this._key}, true);
}

module.exports = TabPanelMember_header;