"use strict";
/**
 * @constructor AbstractSamplerSetComponent
*/

var TypeManager = require('src/core/TypeManager');
var CoreTypes = require('src/core/CoreTypes');
var Components = require('src/core/Component');
var TuppleStreams = require('src/core/TuppleStreams');

var SpecializedTypedListComponent = require('src/UI/generics/SpecializedTypedListComponent/SpecializedTypedListComponent');
var createGenericTitledPanelComponentHostDef = require('src/UI/generics/GenericTitledPanelComponent/packageComponentDefs/GenericTitledPanelComponentHostDef');

var createAbstractSamplerSetComponentHostDef = require('src/UI/packages/setsForPanels/AbstractSamplerSetComponent/packageComponentDefs/AbstractSamplerSetComponentHostDef');
var createAbstractSamplerSetComponentSlotsDef = require('src/UI/packages/setsForPanels/AbstractSamplerSetComponent/packageComponentDefs/AbstractSamplerSetComponentSlotsDef');

var AbstractSamplerSetComponent = function(definition, parentView, parent, listItemsCount, colorStepsCount) {
	this.listItemNodeName = this.listItemNodeName || 'genericsamplers-set';
	this.listItemMembersCount = listItemsCount || 8;
	this.colorStepsCount = colorStepsCount || 8;
	this.slotsDefFactory = this.slotsDefFactory || createAbstractSamplerSetComponentSlotsDef;
	
	SpecializedTypedListComponent.call(this, definition, parentView, parent);
	this.objectType = 'AbstractSamplerSetComponent';
}

AbstractSamplerSetComponent.prototype = Object.create(SpecializedTypedListComponent.prototype);
AbstractSamplerSetComponent.prototype.objectType = 'AbstractSamplerSetComponent';
AbstractSamplerSetComponent.prototype.extends = 'SpecializedTypedListComponent';

AbstractSamplerSetComponent.prototype.createDefaultDef = function() {
	var def = createAbstractSamplerSetComponentHostDef();
	def.getHostDef().nodeName = 'genericsamplers-set';
	return def;
}

AbstractSamplerSetComponent.prototype.defineSlotHost = function() {
	this.typedSlots[0].defaultListDef.getHostDef().template = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			type : 'GenericTitledPanelComponent',
			nodeName : this.listItemNodeName,
			props : [
				{updateChannel : undefined}
			]
//			,
//			reactOnSelf : [
//					{
//						from :  'updateChannel',
//						cbOnly : true,
//						subscribe : function(val) {
////							console.log(val);
//						}
//					}
//			]
		})
	}, null, 'rootOnly');
}


AbstractSamplerSetComponent.prototype.defineSlotMembers = function() {
	var membersDef = this.typedSlots[0].defaultListDef.getHostDef().template.members = 
			createGenericTitledPanelComponentHostDef().members;
	
	this.slotsDefFactory(
		null,
		{
			hostingCompAsAdef : membersDef[2],
			listItemMembersCount : this.listItemMembersCount
		}
	);
	
	
}

AbstractSamplerSetComponent.prototype.getCustomStreams = function() {
	var colorScalesGetters = [
			new TuppleStreams.SemiScaleUpStream('SemiScaleUpStream'),
			new TuppleStreams.SemiScaleDownStream('SemiScaleDownStream'),
			new TuppleStreams.LinearScaleStream('LinearScaleStream'),
			new TuppleStreams.PowerScaleStream('PowerScaleStream'),
			new TuppleStreams.LogScaleStream('LogScaleStream'),
			new TuppleStreams.BezierSmoothedScaleStream('BezierSmoothedScaleStream'),
			new TuppleStreams.ConstantLuminanceScaleStream('ConstantLuminanceScaleStream'),
			new TuppleStreams.ThreePointsScaleStream('ThreePointsScaleStream')
		];
	return colorScalesGetters;
}



/**
 * Helper function to be called (in a translated scope) from a subscription to a stream
 * 		=> in a stream, callbacks are bound to the component: 
 * 		=> affect "this.manualGradientFillOnView" to the "subscribe" property for the method to be correctly bound
 */
AbstractSamplerSetComponent.prototype.manualGradientFillOnView = function(colorScale) {
	this.view.manualGradientFill(colorScale);
}


module.exports = AbstractSamplerSetComponent;