"use strict";
/**
 * @constructor FileSelectorFileListHost
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

//var createFileSelectorFileListHostHostDef = require('src/UI/packages/minimal/FileSelectorFileListHost/packageComponentDefs/FileSelectorFileListHostHostDef');
//var createFileSelectorFileListHostSlotsDef = require('src/UI/packages/minimal/FileSelectorFileListHost/packageComponentDefs/FileSelectorFileListHostSlotsDef');

var FileSelectorFileListHost = function(definition, parentView, parent) {
	Components.ComponentWithView.call(this, definition, parentView, parent);
	this.objectType = 'FileSelectorFileListHost';
}
FileSelectorFileListHost.prototype = Object.create(Components.ComponentWithView.prototype);
FileSelectorFileListHost.prototype.objectType = 'FileSelectorFileListHost';

FileSelectorFileListHost.defaultDef = {
	nodeName : 'div',
	attributes : [],
	states : [],
	props : [],
	reactOnParent : [],
	reactOnSelf : []
}

//FileSelectorFileListHost.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			Object.assign(FileSelectorFileListHost.defaultDef, createFileSelectorFileListHostHostDef()),
//			'FileSelectorFileListHostDefaultDef'
//		);
//}

module.exports = FileSelectorFileListHost;