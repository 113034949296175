"use strict";
/**
 * @constructor RotativeSlidingPanel
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var SlidingPanel = require('src/UI/packages/panels/SlidingPanel');

var createRotativeSlidingPanelHostDef = require('src/UI/packages/panels/RotativeSlidingPanel/packageComponentDefs/RotativeSlidingPanelHostDef');
//var createRotativeSlidingPanelSlotsDef = require('src/UI/packages/panels/RotativeSlidingPanel/packageComponentDefs/RotativeSlidingPanelSlotsDef');

var RotativeSlidingPanel = function(definition, parentView, parent) {
	SlidingPanel.call(this, definition, parentView, parent);
	this.objectType = 'RotativeSlidingPanel';
}
RotativeSlidingPanel.prototype = Object.create(SlidingPanel.prototype);
RotativeSlidingPanel.prototype.objectType = 'RotativeSlidingPanel';

//RotativeSlidingPanel.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

RotativeSlidingPanel.prototype.createDefaultDef = function() {
	var def = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef(
						SlidingPanel.prototype.createDefaultDef().host,
						null,
						'hostOnly'
				),
				subSections : SlidingPanel.prototype.createDefaultDef().subSections,
				members : SlidingPanel.prototype.createDefaultDef().members
		},
		'RotativeSlidingPanelDefaultDef',
		'rootOnly'
	);
	def.getHostDef().sWrapper = createRotativeSlidingPanelHostDef().getHostDef().sWrapper;
	return def;
}

module.exports = RotativeSlidingPanel;