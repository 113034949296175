"use strict";
/**
 * @constructor TCDisplay
 */

var Component = require('src/core/Component');
//var timecodeManager = require('src/tools/TCManager');
//var videoDisplay = require('src/renderers/videoDisplay');
//var videoFileModel = require('src/valueObjects/videoFileModel');

	var video, vfModel, mTimer;
	
	var TCdisplay = function(def, container) {
		Component.ComponentWithView.call(this);
		this.objectType = 'TCdisplay';
		
		this.offset = 0;
		this.arrowOffset = 0;
		
		this.bypass = false; 	// bypass keyUp event
		this.canRefresh = true;
		this.selectionLock = false;
		this.selectionCollapsed = true;
		
		this.valueHR = []
		this.value = 0;
		
		this.commands = def.commands;

		this.progressEvent = {
				action : function(timeInterval) {
							if (this.canRefresh)
								this.setValue(video.domElem[0].currentTime);
						}.bind(this)
		};
		
		this.init(def, container);
	}
TCdisplay.prototype = Object.create(Component.ComponentWithView.prototype);
TCdisplay.prototype.objectType = 'TCdisplay';
	
	TCdisplay.prototype.createDOM = function(def, container) {
		var self = this;
		
//		this.container = $('<div/>', {'class' : def.className});
		this.domElem = $('<div/>', {'class' : def.className});
		this.hoverElem = $('<div/>', {'class' : def.displayClassName + '_hover '}).appendTo(this.domElem);
		
		this.field = $('<div/>', {'class' : 'numeric_field'}).appendTo(this.domElem);
		this.field[0].contentEditable = true;
		
		this.field.bind('focus', function() {
			self.canRefresh = false;
		});
		this.field.bind('blur', function() {
			self.canRefresh = true;
		});
	}
	
	TCdisplay.prototype.startMonitoring = function() {
		if (vfModel.fileDesc.frameRate && false === mTimer.eventIsRegistered(this.progressEvent.action))
			this.progressEvent = mTimer.queueEvent('getTCProgress', this.progressEvent.action, null, null, 5);
	}
	
	TCdisplay.prototype.registerClickEvents = function() {
		var self = this;
		
		this.field.bind('mousedown', function(e) {
			if (this.textContent.length === 0)
				return;
			
			var sel = window.getSelection();
			if (sel.rangeCount)
				var range = sel.getRangeAt(0);

			if (range && (range.collapsed || (range && (range.endOffset - range.startOffset) === 1))) {
				self.selectionLock = true;
				self.initSelectionOn(this, 0, this.textContent.length);
				return false;
			}
		})
		.bind('mouseup', function(e) {
			if (self.selectionLock === true) {
//				console.log('selectionLock');
				self.selectionLock = false;
				return false;
			}

			setTimeout(function() {
				var sel = window.getSelection();
				var range = sel.getRangeAt(0);
				var pos = range.endOffset;
				self.initSelectionOn(this, pos, this.textContent.length !== 0 ? pos + 1 : 0);
			}.bind(this), 32);
		});
	}
	
	TCdisplay.prototype.registerKeyboardEvents = function() {
		var self = this;
		
		this.field.bind('keydown', function(e) {
			e.originalEvent.stopPropagation();
	//		console.log('keydown');
			self.bypass = false;
			var sel = window.getSelection();
			var range = sel.getRangeAt(0);
			var pos = range.startOffset;
//			console.log(this.textContent);
			if (range.collapsed)
				self.selectionCollapsed = true;
			else
				self.selectionCollapsed = false;
			
			// Bypass cases : onkeyup will accept stroke and jump over colons (:), bypass onkeyup when we just expect the default behavior
			if ((e.keyCode !== 82 && e.keyCode !== 13)
					&& (this.textContent.length > 10 && sel.isCollapsed && e.keyCode !== 37 && e.keyCode !== 39
						|| this.textContent[pos] === ':'
						|| !self.selectionCollapsed && pos === 0 && e.keyCode === 37)) {
				self.bypass = true;
				return false;
			}
			else if (e.keyCode !== 13 && (e.keyCode < 96 || e.keyCode > 105)) {
				e.originalEvent.preventDefault();
			}
			else if (e.keyCode === 13) {
				self.clearSelection();
//				console.log(this.textContent);
			}
		})
		.bind('keyup', function(e) {
			e.originalEvent.stopPropagation();
			
			if (self.bypass)
				return false;
			
			// input is enter key : get textContent as Array & call refresh command to get the framerate from the context
			// (the "self.command refreshCurrentTC" points in the def to a different "app command" regarding the function of the TCDisplay in the App)
			if (e.keyCode === 13) {
				if (!vfModel.fileDesc || !vfModel.fileDesc.frameRate) {
					this.textContent = '';
					this.blur();
					return;
				}
				self.clearSelection();
//				console.log(this.textContent);
				var dummy = this.textContent.replace(/:/g,'');
				var value = [dummy.slice(-2), dummy.slice(-4,-2), dummy.slice(-6,-4), dummy.slice(-8,-6)];
				self.commands.refreshCurrentTC.act(timecodeManager.convertTC(value.reverse(), 'sexToDec', vfModel.fileDesc.frameRate) - self.offset);
				self.trigger('exportdata', [self.valueHR, self.value]);
				
				this.blur();
				self.clearSelection();
				return false;
			}
			
			var sel = window.getSelection();
			var range = sel.getRangeAt(0);
			var pos = Number(range.startOffset);
			
			var originalPos = $.inArray(range.startOffset, [2, 5, 8]) !== -1 ? Number(range.startOffset) + 1 : Number(range.startOffset);
			var originalLength = Number(this.textContent.length);
	
			// input is Digit and nothing selected : accept input & place colons where needed
			if (e.keyCode >= 96 && e.keyCode <= 105 && self.selectionCollapsed === true) {
				var dummy = this.textContent.replace(/:/g,'');
				pos -=  originalLength - dummy.length;
				var posOffset = 0;
				
				for(var i = dummy.length - 1; i > 0; i--) {
					if (i % 2 === 0) {
						dummy = dummy.splice(-i, 0, ':');
						posOffset++;
					}
				}
				
				this.textContent = dummy;
				self.initSelectionOn(this, pos + posOffset, pos + posOffset); 	// init collapsed selection
			}
			// input is Digit and selection exists : currently selected digit(s) will be replaced by keystroke
			else if ((e.keyCode >= 96 && e.keyCode <= 105)) {
				self.initSelectionOn(this, originalPos, pos !== this.textContent.length ? originalPos + 1 : originalPos); 	// init selection on next digit
//				console.log(this.textContent);
			}
			// input is Arrow key : correct saved caret position relative to colons when arrow left
			else if (e.keyCode === 37 || e.keyCode === 39) {
				if (e.keyCode === 37) {
					originalPos -= ($.inArray(originalPos, [3, 6, 9]) !== -1) ? 2 : ((pos !== this.textContent.length - 1 || self.selectionCollapsed !== true) ? 1 : 0);
				}
				self.initSelectionOn(this, originalPos, pos !== this.textContent.length ? originalPos + 1 : originalPos) // init selection on current
			}
			
			self.selectionCollapsed = false;
		});
	}
	
	TCdisplay.prototype.updateView = function() {
		this.field[0].textContent = this.valueHR[0] + ':' + this.valueHR[1] + ':' + this.valueHR[2] + ':' + this.valueHR[3];
	}
	
	TCdisplay.prototype.getValue = function() {
		return [this.value, this.valueHR];
	}
	
	TCdisplay.prototype.setValue = function(time) {
		this.value = ((typeof time !== 'undefined' && time >= 0) ? time : this.value) + this.offset;
		this.valueHR = timecodeManager.convertTC(this.value, 'decToSex', vfModel.fileDesc.frameRate);
		this.updateView();
	}
	
	TCdisplay.prototype.getOffset = function(time) {
		return this.offset;
	}
	
	TCdisplay.prototype.setOffset = function(e) {
		var timeArray;
		if (typeof e.data !== 'undefined')
			timeArray = e.data;
		else if (Array.isArray(e))
			timeArray = e;
		else if (typeof e === 'number')
			timeArray = [undefined, e];
		else
			return;

		this.offset = timeArray[1];
		this.setValue();
	}
	
	TCdisplay.prototype.initSelectionOn = function(domElem, start, end) {
		var sel = window.getSelection();
		var range = sel.rangeCount ? sel.getRangeAt(0) : new Range();
		range.setStart(domElem.firstChild || domElem, start);
		range.setEnd(domElem.firstChild || domElem, end);
		sel.removeAllRanges();
//		console.log(range);
		sel.addRange(range);
	}
	
	TCdisplay.prototype.clearSelection = function() {
		var sel = window.getSelection();
		sel.removeAllRanges();
	}
	
	TCdisplay.prototype.setAsOffsetField = function() {
		var txt = document.createElement('span');
		txt.className = 'numeric_field'
		txt.textContent = 'TC IN';
//		setTimeout(function() {
			this.domElem[0].insertBefore(txt, this.field[0]);
//		}.bind(this), 1024);
	}
	
	TCdisplay.prototype.resize = function() {
//		this.domElem
//			.width(this.container.width())
//			.height(this.container.height());
		
		setTimeout(function() {
			// TODO : find why "+ 2" and "18 + 2"
			var previousChildWidth = this.domElem[0].childNodes.length > 2 ? this.domElem[0].childNodes[1].clientWidth + 2 : 0;
			this.field
				.width(this.domElem.width() - previousChildWidth - 20)
				.height(this.domElem.height() - 20)
				.css({
					fontSize : (this.domElem.height() - 22) + 'px'
				});
		}.bind(this), 64);
		
	}

module.exports = TCdisplay;
