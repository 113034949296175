"use strict";
/**
 * @def ObjectPairsListBuilderComponentSlots
 * @isGroup false
 * 
 * @CSSify styleName : ObjectPairsListBuilderComponentPair/true
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ObjectPairsListBuilderComponentSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var pairStyles = [

	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 0",
		"justifyContent": "space-evenly",
		"cursor": "pointer",
		"boxSizing": "border-box"
	}

	];
	var pairStylesUseCache = {
		use : true,
		nameInCache : 'ObjectPairsListBuilderComponentPairStyles'
	}
	
	var objectPairDef = TypeManager.createComponentDef({
		UID : 'dummy',
				sWrapper : CreateStyle(
						pairStylesUseCache.use ? pairStylesUseCache.nameInCache : null,
						pairStyles
					)
	});
	
	return {
		objectPairDef : objectPairDef
	};
}

module.exports = ObjectPairsListBuilderComponentSlotsDef;