"use strict";
/**
 * @constructor VariablyStatefullComponent
*/

var TypeManager = require('src/core/TypeManager');
var CoreTypes = require('src/core/CoreTypes');
var Components = require('src/core/Component');
var VisualSetHostComponent = require('src/UI/generics/VisualSetHostComponent/VisualSetHostComponent');

//var createVariablyStatefullComponentHostDef = require('src/UI/generics/VariablyStatefullComponent/packageComponentDefs/VariablyStatefullComponentHostDef');
//var createVariablyStatefullComponentSlotsDef = require('src/UI/generics/VariablyStatefullComponent/packageComponentDefs/VariablyStatefullComponentSlotsDef');

var VariablyStatefullComponent = function(definition, parentView, parent) {
	VisualSetHostComponent.call(this, definition, parentView, parent);
	this.objectType = 'VariablyStatefullComponent';
	this.streamPool = new CoreTypes.StreamPool(this);
}
VariablyStatefullComponent.prototype = Object.create(VisualSetHostComponent.prototype);
VariablyStatefullComponent.prototype.objectType = 'VariablyStatefullComponent';

VariablyStatefullComponent.defaultDef = {
	nodeName : 'atoms-set-host',
	attributes : [],
	states : [],
	props : [],
	reactOnParent : [],
	reactOnSelf : []
}

VariablyStatefullComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			VariablyStatefullComponent.defaultDef,
			'VariablyStatefullComponentDefaultDef'
		);
}

VariablyStatefullComponent.prototype.addStream = function() {
	var stream = this.streamPool.newChild();
}


VariablyStatefullComponent.prototype.addStreamAt = function(atIndex) {
	var stream = this.streamPool.newChildAt(atIndex);
}


VariablyStatefullComponent.prototype.removeStream = function(streamKey) {
	var removed = this.streamPool.removeChild(streamKey);
	return removed;
}

VariablyStatefullComponent.prototype.removeLastStream = function() {
	var removed = this.streamPool.removeLastChild();
	return removed;
}

VariablyStatefullComponent.prototype.removeAllStreams = function() {
	this.streamPool.removeAllChildren();
	return true;
}

module.exports = VariablyStatefullComponent;