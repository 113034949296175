"use strict";
/**
 * @constructor TagChipComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var VisibleStateComponent = require('src/UI/generics/VisibleStateComponent');
var VaritextButtonComponent = require('src/UI/generics/VaritextButtonComponent/VaritextButtonComponent');
var InnerReactiveComponent = require('src/UI/generics/InnerReactiveComponent');

var labelledInputInterface = require('src/UI/_mixins/labelledInputInterface');

var AGDef = require('src/UI/defs/_arias&glyphsDef');

//var createTagChipComponentHostDef = require('src/UI/packages/minimal/TagChipComponent/packageComponentDefs/TagChipComponentHostDef');
//var createTagChipComponentSlotsDef = require('src/UI/packages/minimal/TagChipComponent/packageComponentDefs/TagChipComponentSlotsDef');

var TagChipComponent = function(definition, parentView, parent) {
	InnerReactiveComponent.call(this, definition, parentView, parent);
	this.objectType = 'TagChipComponent';
}

/**
 * @chained_inheritance TagChipComponent <= InnerReactiveComponent <= VisibleStateComponent <= VaritextButtonComponent
 */
var proto_proto = Object.create(VaritextButtonComponent.prototype);
Object.assign(proto_proto, VisibleStateComponent.prototype);
proto_proto = Object.create(proto_proto);
Object.assign(proto_proto, InnerReactiveComponent.prototype);
TagChipComponent.prototype = Object.create(proto_proto);
TagChipComponent.prototype.objectType = 'TagChipComponent';

// Extensions should implement that
//TagChipComponent = Components.ExtensibleObject.prototype.addInterface(TagChipComponent, labelledInputInterface);

TagChipComponent.defaultDef = {
	nodeName : 'tag-chip',
	attributes : [
		
	],
	targetSlotIndex : 0,
	templateNodeName : 'span',
	states : [
		{selected : undefined}
	],
	props : [
		{contentToList : null}
	],
	reactOnSelf : [
		
		{from : 'contentToList', cbOnly : true, subscribe : TagChipComponent.prototype.updateTargetView}
	]
}

TagChipComponent.prototype.createDefaultDef = function() {
	var def = TypeManager.createComponentDef(
				TagChipComponent.defaultDef
	);
	def.getHostDef().attributes.push(new TypeManager.attributesModel({title : 'TagChip_' + TypeManager.UIDGenerator.newUID()}));
	return def;
}

// TODO: this MUST be handled on the objectPairsListBuilder
TagChipComponent.prototype.afterRegisterEvents = function(e) {
//	var input;
//	if (input = this.view.subViewsHolder.memberAt(1)) {
//		var self = this;
//		input.addEventListener('change', function(e) {
//			console.log('tagchip onChange');
//			if (self._parent.streams.colorUpdateChannel) {
//				self._parent.streams.colorUpdateChannel.value = self._key === 0 
//					? [e.target.value, self._parent.streams.colorUpdateChannel.value[1]]
//					: [self._parent.streams.colorUpdateChannel.value[0], e.target.value]
//			}
//		});
//	}
}

TagChipComponent.prototype.handleClickEvent = function(e) {
//	console.log(Object.getPrototypeOf(this), Object.getPrototypeOf(Object.getPrototypeOf(this)));
//	if (e.target === this.view.getWrappingNode().children[1]) {
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))).handleClickEvent.call(this, e);
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))).handleClickEvent.call(this, e);
//	}
}

TagChipComponent.prototype.simulateClick = function() {
	this.trigger('clicked_ok');
	this.trigger('update', {self_UID : this._UID}, true);
}

TagChipComponent.prototype.basicLateViewExtend = function(definition) {
	var glyphDef = AGDef.getGlyphs(this.__proto__.objectType);
	if (glyphDef === null)
		return;

	var state;
	
	var nodeDef = TypeManager.createComponentDef({
		nodeName : 'span',
		attributes : [
			{hidden : 'hidden'},
			{className : null}
		]
	}, 'glyphClass');
	
	// unshift'ing the pictos is virtualy handled by using the appendContentFromValueOnView callback on the simili-SimpleText injection
	for (let glyphName in glyphDef) {
		state = glyphName.slice(5).toLowerCase();				// glyphSomething

		if (state in CoreTypes.commonStates && definition.getHostDef().states.findObjectByKey(state) !== false) {
			nodeDef.getHostDef().attributes[1].className = 'glyphicon ' + glyphDef[glyphName];
			this.addReactiveMemberViewFromFreshDef(definition, nodeDef, state);
		}
		state = glyphName.slice(8);								// glyphNotsomething
		if (state in CoreTypes.commonStates && definition.getHostDef().states.findObjectByKey(state) !== false) {
			nodeDef.getHostDef().attributes[1].className = 'glyphicon ' + glyphDef[glyphName]; 
			this.addReactiveMemberViewFromFreshDef(definition, nodeDef, 'Not' + state);
		}
	}
}




module.exports = TagChipComponent;