"use strict";
/**
 * @constructor ColorSwatchWithSelectorComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var ColorSwatchComponent = require('src/UI/packages/minimal/ColorSwatchComponent/ColorSwatchComponent');

//var SlidingBoxDecorator = require('src/coreDecorators/SlidingBoxDecorator');

//var createColorSwatchWithSelectorComponentHostDef = require('src/UI/packages/minimal/ColorSwatchWithSelectorComponent/packageComponentDefs/ColorSwatchWithSelectorComponentHostDef');
//var createColorSwatchWithSelectorComponentSlotsDef = require('src/UI/packages/minimal/ColorSwatchWithSelectorComponent/packageComponentDefs/ColorSwatchWithSelectorComponentSlotsDef');

var ColorSwatchWithSelectorComponent = function(definition, parentView, parent) {
	ColorSwatchComponent.call(this, definition, parentView, parent);
	this.objectType = 'ColorSwatchWithSelectorComponent';
}
ColorSwatchWithSelectorComponent.prototype = Object.create(ColorSwatchComponent.prototype);
ColorSwatchWithSelectorComponent.prototype.objectType = 'ColorSwatchWithSelectorComponent';


ColorSwatchWithSelectorComponent.prototype._asyncInitTasks = [];
ColorSwatchWithSelectorComponent.prototype._asyncInitTasks.push(new TypeManager.TaskDefinition({
	type : 'lateAddChild',
	task : function(definition) {
//			var DecoratedColorSelector = SlidingBoxDecorator('ColorSelector');
//			var basicDef = TypeManager.createComponentDef({type : 'emptyDef'}, 'DecoratedColorSelectorUsedByColorSwatch');
//			new DecoratedColorSelector(basicDef, this.view, this);
//			this.view.subViewsHolder.addMemberView(this._children[this._children.length - 1].view);
		}
	})
);

//ColorSwatchWithSelectorComponent.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			Object.assign(ColorSwatchWithSelectorComponent.defaultDef, createColorSwatchWithSelectorComponentHostDef()),
//			'ColorSwatchWithSelectorComponentDefaultDef'
//		);
//}

module.exports = ColorSwatchWithSelectorComponent;