"use strict";
/**
 * @constructor TransportBar
 * contextualizable constructor : is context-aware, as there can be various transports, but only one by unique target
*/

var Component = require('src/core/Component');
//var videoFile = require('src/player/videoFileModel');
//var fileDropZone = require('src/UI/fileDropZone');
//var playerControler = require('src/controlers/playerControler');

//var miniControlsDef = require('./defs/miniControlsDef');

//var commands = require('src/commands/commands');


	var cGroup, pController, dropZone, com;
	
	var Transport = function(def, parentView, parent) {
		Component.ComponentWithView.call(this, def, parentView, parent);
		this.objectType = 'TransportBar';
	}
	Transport.prototype = Object.create(Component.ComponentWithView.prototype);
	Transport.prototype.objectType = 'Transport';
	
	Transport.prototype.Make = function(def, container) {
		Factory.UIModule.call(this);
		this.container = container;
		this.init(def);
		
//		console.log(this.miniControls);
		// cascading to ProgressControler : get videoNode & resize again (we've passed video node init)
		this.miniControls.buttons[2].Make();
	}
	
	Transport.prototype.createDOM = function(def) {
		var self = this;
		this.miniControls = new cGroup(miniControlsDef(context).getInstance(), this.container);
		this.domElem = this.miniControls.def.sections[0];
		
		this.anim = new TimelineMax({paused : true});
		this.anim.add(TweenMax.to(this.domElem[0], .777, {ease :  Power3.easeOut, paddingTop : 0, height : '44px'}));
		this.anim.add(TweenMax.to(this.domElem[0], .512, {ease :  Power3.easeOut, opacity : 1}), 0.357);
		
		
//		this.cueMark = $('<div/>', {'class' : "cue_point"}).appendTo(this.domElem);
//		$('<div/>', {'class' : 'glyphicon glyphicon-chevron-up'}).appendTo(this.cueMark);

	}

	
	Transport.prototype.setProgress = function(progress) {
//		this.progressBar.width(progress * this.progressContainer.width());
		this.infoBox.html(com.getCurrentTime());
	}
	
	Transport.prototype.resize = function() {
		var self = this;
		setTimeout(function() {
			self.domElem.width(self.container.width());
//			var w = self.container[0].clientWidth - (self.miniControls.isVisible ? self.miniControls.getComputedSize.w : 0);
//			self.domElem.width(w);
		}, 64);
	}
	
	Transport.prototype.setAsSecondary = function() {
		this.miniControls.buttons[3].progressEvent.cancel();
		this.miniControls.buttons[3].domElem.empty();
	}
	
	Transport.prototype.setAsPrimary = function() {
		this.miniControls.buttons[3].progressEvent.start();
	}

module.exports = Transport;
