"use strict";
/**
 * @def ColorRampComponent
 * @isGroup true
 * 
 * @CSSify styleName : ColorRampComponentHost/true
 * @CSSify styleName : ColorRampComponentTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var ColorRampComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host, canvas",
		"margin": "0",
		"padding": "0",
		"border": "0",
		"outline": "0",
		"background": "transparent",
		"verticalAlign": "baseline",
		"boxShadow": "none",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 0"
	},
	{
		"selector": "canvas",
		"flex": "1 1 0",
		"minWidth": "255px",
		"width": "100%",
		"height": "27px",
		"margin": "3px",
		"border": "1px inset #80808022"
	},
	{
		"selector": ":host([hidden])",
		"display": "none"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ColorRampComponentHostStyles'
	}
	
	var moduleDef = TypeManager.createComponentDef({
//			type : 'ComposedCompnent', 				// this is implicit, as we call the CompoundComponent ctor in the TabPanel ctor
			nodeName : 'canvas-component',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}, 'ColorRampComponentDef');
	
	return moduleDef;
}

module.exports = ColorRampComponentDef;