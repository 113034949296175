"use strict";
/**
 * @def ColorSamplerSetComponent
 * @isGroup true
 * 
 * @CSSify styleName : ColorSamplerSetComponentHost/true
 * !!
 * @CSSify styleName : ColorSwatchWithSelectorComponentSwatch/false
 * !!
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');




var ColorSamplerSetComponentDef = function(uniqueID, options, model) {

	
	
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 0",
		"justifyContent": "space-evenly",
		"alignSelf": "flex-end",
		"selector": ":host",
		"boxSizing": "border-box",
		"background": "0",
		"border": "0",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "5px",
		"verticalAlign": "baseline"
	},
	{
		"selector": "box-column:nth-child(2)",
		"marginTop": "55px"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'ColorSamplerSetComponentHostStyles'
	}

	
	return TypeManager.createComponentDef({
				UID : 'dummy'
//				,
//				props : [
//					{updateChannel : undefined}
//				]
//				,
//				reactOnSelf : [
//					{
//						from : 'updateChannel',
//						cbOnly : true,
//						subscribe : function(value) {
//							this.typedSlots[0].resetLength();
//							var scalesAsStreams = this.getCustomStreams();
//							
//							if (Array.isArray(value)) {
//								// we got at least a set, but maybe a group of sets
//								if (Array.isArray(value[0])) {
//									// it's a group
//									if (value[0][0]._id) {
//										// we found the effective obj
//										var items = value.map(function(set) {
//											return this.typedSlots[0].newItem(set);
//										}, this);
//										this.typedSlots[0].pushApply(items);
//										
//										this.typedSlots[0].forEach(function(item, idx) {
//											this._children[idx]._children.forEach(function(child, key) {
//												child.streams.colorGetter = scalesAsStreams[key];
//											}, this);
//										}, this);
//									}
//								}
//								else {
//									// it's a single set
//									this.typedSlots[0].push(
//										this.typedSlots[0].newItem(value)
//									);
//								}
//							}
//							else
//								console.warn(this.objectType, 'set-viewers are meant to instanciate lists, but value received was not an array');
//						}
//					}
//				]
				,
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
			});
}

module.exports = ColorSamplerSetComponentDef;