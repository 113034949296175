"use strict";
/**
 * @def IFrameComponent
 * @isGroup false
 * 
 * @CSSify styleName : IFrameComponentHost_Grid-2-2/false
 * @CSSify styleName : IFrameComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var IFrameComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var Styles = [

	{
		"display": "grid",
		"gridRow": "1",
		"gridColumn": "2",
		"selector": "#computedDataStructures",
		"boxSizing": "border-box"
	},
	{
		"display": "grid",
		"gridRow": "1",
		"gridColumn": "2",
		"selector": "#rasterizedRendering",
		"boxSizing": "border-box"
	}

	];
	var StylesUseCache = {
		use : false,
		nameInCache : 'IFrameComponentHost_Grid-2-2Styles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
			nodeName : 'iframe',
			attributes : [
				{title : ''}	// IFrames must have a title ()https://dequeuniversity.com/rules/axe/4.4/frame-title?utm_source=lighthouse&utm_medium=devtools)
			],
				sWrapper : CreateStyle(
						StylesUseCache.use ? StylesUseCache.nameInCache : null,
						Styles
					)
		});
	
	return moduleDef;
}

module.exports = IFrameComponentDef;