"use strict";
/**
 * @constructor ColorSwatchRangeComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TagChipRangeComponent = require('src/UI/packages/minimal/TagChipRangeComponent/TagChipRangeComponent');

//var createColorSwatchRangeComponentHostDef = require('src/UI/packages/minimal/ColorSwatchRangeComponent/packageComponentDefs/ColorSwatchRangeComponentHostDef');
//var createColorSwatchRangeComponentSlotsDef = require('src/UI/packages/minimal/ColorSwatchRangeComponent/packageComponentDefs/ColorSwatchRangeComponentSlotsDef');

var ColorSwatchRangeComponent = function(definition, parentView, parent) {
	TagChipRangeComponent.call(this, definition, parentView, parent);
	this.objectType = 'ColorSwatchRangeComponent';
}
ColorSwatchRangeComponent.prototype = Object.create(TagChipRangeComponent.prototype);
ColorSwatchRangeComponent.prototype.objectType = 'ColorSwatchRangeComponent';
ColorSwatchRangeComponent.prototype.extends = 'TagChipRangeComponent';

ColorSwatchRangeComponent.defaultDef = {
		host : TypeManager.createComponentDef({
			nodeName : 'colorswatch-range'
		}),
		members : [
			TypeManager.createComponentDef({
				type : 'ColorSwatchComponent'
			})
//			,
//			TypeManager.createComponentDef({
//				type : 'ColorSwatchComponent'
//			}),
//			TypeManager.createComponentDef({
//				type : 'ColorSwatchComponent'
//			})
		]
}

ColorSwatchRangeComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			ColorSwatchRangeComponent.defaultDef,
			'ColorSwatchRangeComponentDefaultDef',
			'rootOnly'
		);
}

module.exports = ColorSwatchRangeComponent;