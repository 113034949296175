"use strict";
/**
 * @constructor VaritextButtonComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var SimpleTextReplace = require('src/UI/Packages/minimal/SimpleTextReplace');

//var createVaritextButtonComponentHostDef = require('src/UI/packages/minimal/VaritextButtonComponent/packageComponentDefs/VaritextButtonComponentHostDef');
//var createVaritextButtonComponentSlotsDef = require('src/UI/packages/minimal/VaritextButtonComponent/packageComponentDefs/VaritextButtonComponentSlotsDef');


var VaritextButtonComponent = function(definition, parentView, parent) {
	SimpleTextReplace.call(this, definition, parentView, parent);
	this.objectType = 'VaritextButtonComponent';
}
VaritextButtonComponent.prototype = Object.create(SimpleTextReplace.prototype);
VaritextButtonComponent.prototype.objectType = 'VaritextButtonComponent';

VaritextButtonComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			props : [
				{content : undefined}
			],
			reactOnSelf : [
				{to : 'content', cbOnly : true, subscribe : this.setContentFromValueOnView}
			]
		}
	);
}

VaritextButtonComponent.prototype.registerClickEvents = function() {
	this.view.getMasterNode().addEventListener('mousedown', this.handleClickEvent.bind(this));
}

VaritextButtonComponent.prototype.handleClickEvent = function(e) {
	e.stopPropagation();
	this.trigger('update', {grandParent_key : this._parent._parent._key, parent_key : this._parent._key, self_depth : this.getSelfDepth(), self_key : this._key, self_UID : this._UID}, true);
}

module.exports = VaritextButtonComponent;