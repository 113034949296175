"use strict";
/**
 * @constructor ProgressControler
*/


var Component = require('src/core/Component');

	var mTimer, videoDisplay, sfModel;
	
	
	var ProgressControler = function(def, parentView, parent) {
		Component.ComponentWithView.call(this, def, parentView, parent);
		
		this.objectType = 'ProgressControler';
		this.def = def;
		
		this.learning = false;
		this.keyboardEvents = def.keyboardSettings;
		this.createEvent('clicked_ok');
		this.createEvent('progress');
		
		this.progressEvent = {
				action : function(timeInterval) {
							this.bar.width(this.videoNode.currentTime * this.domElem.width() / sfModel.getTrackEndTime('video'));
						}.bind(this)
		};
		
		if (false === mTimer.eventIsRegistered(this.progressEvent.action))
			this.progressEvent = mTimer.queueEvent('getBarProgress', this.progressEvent.action, 'paused', null, 5);
		
		this.initGenericEvent();
		this.init(def);
	}
	ProgressControler.prototype = Object.create(Component.ComponentWithView.prototype);
	ProgressControler.prototype.objectType = 'ProgressControler';
	
	ProgressControler.prototype.Make = function(def) {
		this.videoNode = videoDisplay.domElem[0];
		this.resize();
	}

	ProgressControler.prototype.createDOM = function(def) {
		this.domElem = $('<div/>', {'class' : def.className}).css(def.css);
		this.barRail = $('<div/>', {'class' : 'progress_bar_rail'}).css(def.css).appendTo(this.domElem);
		this.bar = $('<div/>', {'class' : def.buttonClassName}).appendTo(this.barRail);
		if (def.glyphInactive) {
			this.domElem.append($('<span/>', {'class' : def.glyphInactive}))
		}
		this.hoverElem = $('<div/>', {'class' : def.buttonClassName + '_hover '});//.appendTo(this.domElem);
		this.command = def.command;
	}

	ProgressControler.prototype.registerClickEvents = function() {
		var self = this, captureKey;
		
		// Initialize (force background image loading for "disabled" state)
		this.domElem.addClass('disabled');
		setTimeout(function() {
			self.domElem.removeClass('disabled');
		}, 1024);
		
		// Click event listener & canAct management 
		this.domElem.on('mousedown', function(e) {
//			console.log('click');
			var canActBool = true
			e.stopPropagation();
			
			var realPos = e.pageX - self.domElem.offset().left;
//			console.log(realPos)
			var factor = realPos / self.domElem.width();
//			console.log(factor)

			if (self.command.act !== null)
				canActBool = self.command.act(factor);

			if (canActBool instanceof Promise) {
				canActBool.then(function() {
//					toggle();
				})
			}
			else if (canActBool) {
//				toggle();
			}
			else {
				self.domElem.addClass('disabled');
				self.bar.addClass('disabled');
				setTimeout(function() {
					self.domElem.removeClass('disabled');
					self.bar.removeClass('disabled');
				}, 255)
			}
			self.trigger('clicked');
		});
	}
	
	ProgressControler.prototype.resize = function() {
		var self = this;
		setTimeout(function() {
//			console.log(window.getComputedStyle(self.domElem[0].previousSibling)['margin-left'].slice(0, -2))
//			console.log(self.domElem[0].parentNode.clientWidth, (self.domElem[0].previousSibling.clientWidth + parseInt(window.getComputedStyle(self.domElem[0].previousSibling)['margin-left'].slice(0, -2))));
//			if (self.domElem[0].parent) {
				var buttonStyle = window.getComputedStyle(self.domElem[0].previousSibling);
//				console.log(self.domElem[0].parentNode.clientWidth, self.domElem[0].previousSibling.clientWidth, parseInt(buttonStyle['margin-left'].slice(0, -2)), parseInt(buttonStyle['padding-left'].slice(0, -2)), self.domElem[0].previousSibling.clientWidth + parseInt(buttonStyle['margin-left'].slice(0, -2))  + parseInt(buttonStyle['padding-left'].slice(0, -2)));
				var w = self.domElem[0].parentNode.clientWidth 
							- (self.domElem[0].previousSibling.clientWidth 
									+ parseInt(buttonStyle['margin-left'].slice(0, -2))
								) * 4 
								- parseInt(buttonStyle['padding-left'].slice(0, -2));
//				console.log(w);
				self.domElem.width(w);
//				self.bar.width(w / 2);
//			}
		}, 64);
	}



module.exports = ProgressControler;
