"use strict";
/**
 * @constructor CanvasBasedJSONTreeComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var JSONTree = require('src/UI/packages/structs/JSONTree');

//var createCanvasBasedJSONTreeComponentHostDef = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/packageComponentDefs/CanvasBasedJSONTreeComponentHostDef');
var createCanvasBasedJSONTreeComponentBranchTemplateDef = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/packageComponentDefs/CanvasBasedJSONTreeComponentBranchDef');
var createCanvasBasedJSONTreeComponentLeafTemplateDef = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/packageComponentDefs/CanvasBasedJSONTreeComponentLeafDef');

var DOMresolver = require('src/UI/packages/structs/CanvasBasedJSONTreeComponent/DOMresolver.js');

var CanvasBasedJSONTreeComponent = function(def, parentView, parent, jsonData, nodeFilterFunction) {
	this.branchTemplate = createCanvasBasedJSONTreeComponentBranchTemplateDef();
	this.leafTemplate = createCanvasBasedJSONTreeComponentLeafTemplateDef();
	JSONTree.call(this, def, parentView, parent, jsonData, nodeFilterFunction || CanvasBasedJSONTreeComponent.nodeFilterFunction);
	
	this.DOMresolver = DOMresolver.resolveStyleFromDOMnode;
}
CanvasBasedJSONTreeComponent.prototype = Object.create(JSONTree.prototype);
CanvasBasedJSONTreeComponent.prototype.objectType = 'CanvasBasedJSONTreeComponent';
CanvasBasedJSONTreeComponent.prototype.extends = 'JSONTree';

CanvasBasedJSONTreeComponent.prototype.resolveStyleFromView = function(view) {
	return this.resolveStyleFromDOMnode(view.getMasterNode());
}

CanvasBasedJSONTreeComponent.prototype.resolveStyleFromDOMnode = function(node) {
	return this.DOMresolver(node);
}


CanvasBasedJSONTreeComponent.nodeFilterFunction = function(node) {
	
}

CanvasBasedJSONTreeComponent.prototype.getHeaderTitle = function(memberSpec) {
//	var len = memberSpec.children.length;
//	if (memberSpec.type === 'array')
		return {
			headerTitle: memberSpec.key + '&nbsp;:&nbsp;' + "[".concat(len, "]"),
			displayedas: memberSpec.type,
			expanded : this.expanded,
			nodeintree : true
			};
//	else if (memberSpec.type === 'object')
//		return {
//			headerTitle: memberSpec.key + '&nbsp;:&nbsp;' + "{".concat(len, "}"),
//			displayedas: memberSpec.type,
//			expanded : this.expanded,
//			nodeintree : true
//			};
//	else if (memberSpec.type.indexOf('Box') !== -1 || memberSpec.type.indexOf('dummy') !== -1)
//		return {
//			headerTitle: memberSpec.key + '&nbsp;:&nbsp;<span class="bracket">[</span><span class="json-type">' + memberSpec.type + '</span><span class="bracket">]</span>',
//			displayedas: memberSpec.type,
//			expanded : this.expanded,
//			branchintree : true
//			};
}

CanvasBasedJSONTreeComponent.prototype.affectClickEvents = function(memberDesc, component) { 	// implementation
	
	var self = this;
	
//	if (memberDesc.children.length) {
//		// VaritextButtonWithPictoFirst has a header node, containing 3 pictos, and an appended span (containing a textNode, key:, and 3 spans, [value])
//		component._children[0].addEventListener('clicked_ok', function(e) {
//			// When artificially clicked from outside of the component, there is no e.data.target
//			if ((!e.data || !e.data.target) || e.data.target === this.view.getWrappingNode().children[4])
////				this.streams.selected.value = 'selected';  // the component shall trigger update and receive the "selected" attribute: not needed here
//				self.trigger('exportdata', memberDesc.projectedData);
//			else if (e.data.target === this.view.getWrappingNode().children[0]
//						|| e.data.target === this.view.getWrappingNode().children[1]
//						|| e.data.target === this.view.getWrappingNode().children[2]
//						|| e.data.target === this.view.getWrappingNode().children[3])
//				component.streams.expanded.value = !component.streams.expanded.value ? 'expanded' : null;
//		}.bind(component._children[0]));
//	}
//	else {
//		// Leaf Component MUST inherit from ComponentWithHooks
//		component.registerClickEvents = function() {
//			if (!component._eventHandlers.clicked_ok)
//				component.createEvent('clicked_ok');
//			
//			Object.getPrototypeOf(this).registerClickEvents.call(this);
//			
//			// VisiblyTypedKeyValuePair has a div with a picto, and 2 divs with key : value
//			(memberDesc.value.length ? this.view.subViewsHolder.memberViews[2] : this.view.subViewsHolder.memberViews[1]).getWrappingNode().addEventListener('click', function(e) {
//				this.trigger('clicked_ok', e);
//			}.bind(component));
//			component.addEventListener('clicked_ok', function(e) {
////				this.streams.selected.value = 'selected';   // when triggering update we receive the "selected" attribute: not needed here
//				this.trigger('update', {self_UID : component._UID}, true);
//				self.trigger('exportdata', memberDesc.projectedData);
//			}.bind(component));
//		}
//	}
}

module.exports = CanvasBasedJSONTreeComponent;