"use strict";
/**
 * @def Menu
 * @isGroup true
 * 
 * @CSSify styleName : MenuHost
 * @CSSify styleName : MenuTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var DF = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var MenuDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"boxSizing": "border-box",
		"background": "#383F50",
		"width": "97px",
		"height": "44px",
		"border": "0",
		"padding": "9px",
		"marginLeft": "5px",
		"textAlign": "center",
		"cursor": "pointer"
	},
	{
		"selector": ":host(:hover)",
		"background": "#404347"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'MenuHostStyles'
	}
	
	
	var moduleDef = DF.createDef({
		host : DF.createDef({
			nodeName : 'menu-group',
			props : [
				{open : false}
			],
			subscribeOnChild : [
				{
					on : 'update',
					subscribe : function(e) {
						if (e.bubble)
							this.trigger('update', e.data, true);
					}
				}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		})
	});
	
	return moduleDef;
}

module.exports = MenuDef;