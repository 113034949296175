"use strict";
/**
 * @def IFrameComponent
 * @isGroup false
 * 
 * @CSSify styleName : IFrameComponentHost_Grid-1-1/false
 * @CSSify styleName : IFrameComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var IFrameComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var Styles = [

	{
		"display": "grid",
		"gridRow": "1",
		"gridColumn": "1",
		"selector": "#sampleCodeToBootstrapViews, #markdownComponentDef",
		"boxSizing": "border-box"
	},
	{
		"display": "none",
		"gridColumn": "1",
		"selector": "#sampleCodeRenderedProof",
		"boxSizing": "border-box"
	},
	{
		"selector": ":host",
		"width": "100%",
		"height": "100%",
		"display": "flex",
		"flexDirection": "column"
	},
	{
		"selector": "iframe",
		"height": "100%",
		"width": "100%",
		"border": "0px solid #AAA"
	},
	{
		"selector": "#markdownComponentProof",
		"display": "grid",
		"gridRow": "1",
		"gridColumn": "2"
	},
	{
		"selector": "#markdownComponentResult",
		"display": "grid",
		"gridRow": "1",
		"gridColumn": "3"
	},
	{
		"selector": ":host section:nth-child(4)",
		"height": "44px"
	},
	{
		"selector": ":host section:nth-child(5)",
		"display": "grid",
		"flexDirection": "row"
	},
	{
		"selector": ":host(#devtools_struct) section:nth-child(6), :host(#markdown_converter) section:nth-child(5)",
		"height": "100%"
	}

	];
	var StylesUseCache = {
		use : false,
		nameInCache : 'IFrameComponentHost_Grid-1-1Styles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
			nodeName : 'iframe',
			attributes : [
				{title : ''}	// IFrames must have a title ()https://dequeuniversity.com/rules/axe/4.4/frame-title?utm_source=lighthouse&utm_medium=devtools)
			],
				sWrapper : CreateStyle(
						StylesUseCache.use ? StylesUseCache.nameInCache : null,
						Styles
					)
		});
	
	return moduleDef;
}

module.exports = IFrameComponentDef;