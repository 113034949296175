"use strict";
/**
 * @def DOMcontentPreview
 * @isGroup true
 * 
 * @CSSify styleName : DOMcontentPreviewHost
 * @CSSify styleName : DOMcontentPreviewSection
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var DOMcontentPreviewDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host section",
		"display": "block"
	},
	{
		"selector": ":host",
		"display": "block",
		"height": "100%"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'DOMcontentPreviewHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'dom_content-preview',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'header',
//				attributes : [
//					{textContent : 'DOM Content Preview:'}
//				]
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'script'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'section',
				attributes : [
					{id : 'DOMcontentPreview'}
				]
			}, null, 'hostOnly')
		]
	}, null, 'rootOnly');
	
	return moduleDef;
}

module.exports = DOMcontentPreviewDef;