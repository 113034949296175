"use strict";
/**
 * @def IncrementableInput
 * @isGroup true
 * 
 * @CSSify styleName : IncrementableInputHost
 * @CSSify styleName : IncrementableInputTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var IncrementableInputDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": "input[type=\"text\"]",
		"display": "inline-block",
		"border": "0",
		"paddingInlineStart": "0",
		"paddingInlineEnd": "0",
		"background": "#111",
		"width": "29px",
		"height": "29px",
		"padding": "5px",
		"borderRadius": "2px",
		"verticalAlign": "baseline",
		"boxSizing": "border-box",
		"WebkitAppearance": "none",
		"cursor": "pointer"
	},
	{
		"selector": ":host",
		"display": "flex",
		"flex": "1 1 0",
		"padding": "3px",
		"border": "1px #99999911 inset",
		"borderWidth": "1px",
		"borderRadius": "3px",
		"boxSizing": "border-box"
	},
	{
		"selector": "span",
		"display": "inline-block",
		"padding": "5px"
	},
	{
		"selector": "label",
		"display": "none"
	},
	{
		"selector": ":host(:nth-child(2))",
		"marginTop": "57px"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'IncrementableInputHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
			nodeName : 'incrementing-input',
			states : [
				{valid : undefined},
				{errors : undefined}
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		});
	
	return moduleDef;
}

module.exports = IncrementableInputDef;