"use strict";
/**
 * @constructor CancelButton
 * 
*/
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var typedInputInterface = require('src/UI/_mixins/typedInputInterface');

var createCancelButtonDef = require('src/UI/packages_defs/minimal/submitButtonDef');

var CancelButton = function() {
	Component.ComponentWithHooks.apply(this, arguments);
	this.objectType = 'CancelButton';
}
CancelButton.prototype = Object.create(Component.ComponentWithHooks.prototype);
CancelButton.prototype.objectType = 'CancelButton';

CancelButton = Component.ExtensibleObject.prototype.addInterface(CancelButton, typedInputInterface);

CancelButton.prototype.createEvents = function() {
	this.createEvent('clicked_ok');
	this.createEvent('cancel');
}

CancelButton.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			nodeName : 'button',
			attributes : [
				{title : '-Cancel'}
			],
			props : [
//				{text : undefined},
				{content : undefined}
			],
			reactOnSelf : [
				{
					cbOnly : true,
					to	 : 'content',
					subscribe : this.setContentFromValueOnView,
				},
				{
					from : 'text',
					to : 'content',
				}
			],
			sWrapper : createCancelButtonDef().getHostDef().sWrapper
	}, 'CancelButton');
}

CancelButton.prototype.afterCreateDOM = function() {
//		this.view.getMasterNode()['value'] = this.def.title;
//		if (this.def.sWrapper && this.def.sWrapper.styleElem)
//			this.view.getMasterNode().appendChild(this.def.sWrapper.styleElem);
}

CancelButton.prototype.afterRegisterEvents = function() {
	var self = this;
	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
		self.trigger('clicked_ok');
	});
	this.onclicked_ok = this._parent.trigger.bind(this._parent, 'cancel');
}
	

module.exports = CancelButton;