"use strict";
/**
 * @def PIXIRendererComponent
 * @isGroup true
 * 
 * @CSSify styleName : PIXIRendererComponentHost
 * @CSSify styleName : PIXIRendererComponentTemplate
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var PIXIRendererComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"selector": ":host",
		"display": "block",
		"overflowY": "scroll"
	}

	];
	var hostStylesUseCache = {
		use : undefined,
		nameInCache : 'PIXIRendererComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createDef({
		host : TypeManager.createDef({
			nodeName : 'PIXI_Renderer'.toLowerCase() + '-component',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		})
	});
	
	return moduleDef;
}

module.exports = PIXIRendererComponentDef;