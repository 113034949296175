"use strict";
/**
 * @constructor TextInput
 * 
*/


var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
var JSkeyboardMap = require('src/events/JSkeyboardMap');

var KeyboardSubmittableInput = function() {
	Component.ComponentWithHooks.apply(this, arguments);
}
KeyboardSubmittableInput.prototype = Object.create(Component.ComponentWithHooks.prototype);
KeyboardSubmittableInput.prototype.objectType = 'KeyboardSubmittableInput';

KeyboardSubmittableInput.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			attributes : [
				{type : 'text'}
			]
	}, 'KeyboardSubmittableInput');
}

KeyboardSubmittableInput.prototype.createEvents = function() {
	this.createEvent('enter_key');
}

KeyboardSubmittableInput.prototype.registerKeyboardEvents = function() {
	this.keyHandler = this.handleKeyboardEvent.bind(this);
	this.view.getMasterNode().addEventListener('keydown', this.keyHandler);
}

KeyboardSubmittableInput.prototype.handleKeyboardEvent = function(e) {
	var self = this;
	e.stopPropagation();
	
	if (this.command && this.command !== null && this.command.act !== null)
		var canActQuery = self.command.act();
	else {
		if (e.keyCode === JSkeyboardMap.indexOf('ENTER')) {
			console.log('update : enter key');
			this.trigger('enter_key', null, true);
			e.target.value = '';
		}
		return;
	}
	
	canActQuery.then(
		function(queryResult) {
			this.trigger('enter_key');
		},
		function(queryResult) {
			self.view.getMasterNode()['disabled'] = 'disabled'; // rootElem ?
			setTimeout(function() {
				self.view.getMasterNode()['disabled'] = null;
			}, 255)
//			self.trigger('enter_key_refused');
		}
	);
}

module.exports = KeyboardSubmittableInput;