"use strict";
/**
 * @constructor ColorRampComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
//var TuppleStreams = require('src/core/TuppleStreams');

var createColorRampComponentHostDef = require('src/UI/packages/minimal/ColorRampComponent/packageComponentDefs/ColorRampComponentHostDef');
//var createColorRampComponentSlotsDef = require('src/UI/packages/minimal/ColorRampComponent/packageComponentDefs/ColorRampComponentSlotsDef');

var ColorRampComponent = function(definition, parentView, parent) {
	this.propToAcquireFromDBType = 'hex';
	
	Components.ComponentWithCanvas.call(this, definition, parentView, parent);
	this.objectType = 'ColorRampComponent';
	
//	this.streams.colorGetter = new TuppleStreams.LinearScaleStream('LinearScaleStream');
}
ColorRampComponent.prototype = Object.create(Components.ComponentWithCanvas.prototype);
ColorRampComponent.prototype.objectType = 'ColorRampComponent';

// Variation on dbFedComponentInterface: keep colors as pairs
ColorRampComponent.prototype.adaptDBTypedValue = function(valueFromDB) {
	return valueFromDB.map(function(item) {
			return item[this.propToAcquireFromDBType];
		}, this);
}

//ColorRampComponent.defaultDef = {
//	nodeName : 'canvas-component',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

ColorRampComponent.prototype.createDefaultDef = function() {
	var def = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
					type : 'ColorRampComponent',
					nodeName : 'canvas-component',
					states : [
						{hidden : 'hidden'}
					],
					props : [
						{updateChannel : undefined}
					],
					reactOnSelf : [
						{
							from : 'updateChannel',
							cbOnly : true,
							subscribe : function(value) {
//								console.log(this._key, this.propToAcquireFromDBType, value[this._key][this.propToAcquireFromDBType], value);

								this.streams.colorGetter(value);
							}
						},
						{
							from : 'colorGetter',
							cbOnly : true,
							subscribe : function(colorScale) {
//								self.trigger('newColorScale', {self_key : this._key, colorScale : colorScale});
								this.view.manualGradientFill(colorScale);	
							}
						}
					]
				}, null, 'hostOnly'),
				members : [
					TypeManager.createComponentDef({
						nodeName : 'canvas'
					}, null, 'hostOnly')//,
//					TypeManager.createComponentDef({
//						nodeName : 'div',
//						attributes : [
//							{className : 'accordion_panel_shadow'}
//						]
//					}, null, 'hostOnly'),
//					TypeManager.createComponentDef({
//						nodeName : 'ul'
//					}, null, 'hostOnly'),
//					TypeManager.createComponentDef({
//						nodeName : 'div',
//						attributes : [
//							{className : 'accordion_panel_inverse_shadow'}
//						]
//					}, null, 'hostOnly'),
//					TypeManager.createComponentDef({
//						nodeName : 'footer',
//					}, null, 'hostOnly')
				]
			}, 'ColorRampComponentDefaultDef', 'rootOnly');
			
	def.getHostDef().sWrapper = createColorRampComponentHostDef().getHostDef().sWrapper;
	
	return def;
}

module.exports = ColorRampComponent;