"use strict";
/**
 * @constructor ColorSwatchComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TagChipComponent = require('src/UI/packages/minimal/TagChipComponent/TagChipComponent');

var labelledColorInputInterface = require('src/UI/_mixins/labelledColorInputInterface');
var dbFedComponentInterface = require('src/UI/_mixins/dbFedComponentInterface');

var createColorSwatchComponentHostDef = require('src/UI/packages/minimal/ColorSwatchComponent/packageComponentDefs/ColorSwatchComponentHostDef');
//var createColorSwatchComponentSlotsDef = require('src/UI/packages/minimal/ColorSwatchComponent/packageComponentDefs/ColorSwatchComponentSlotsDef');

var ColorSwatchComponent = function(definition, parentView, parent) {
	TagChipComponent.call(this, definition, parentView, parent);
	this.objectType = 'ColorSwatchComponent';
	
	this.propToAcquireFromDBType = 'hex';
}

ColorSwatchComponent.prototype = Object.create(TagChipComponent.prototype);
ColorSwatchComponent.prototype.objectType = 'ColorSwatchComponent';
//console.log(ColorSwatchComponent.prototype._asyncInitTasks);
//ColorSwatchComponent.prototype._asyncInitTasks.splice(0, 1); // already queued viewExtension in TagChipComponent
ColorSwatchComponent = Components.ExtensibleObject.prototype.addInterface(ColorSwatchComponent, labelledColorInputInterface);
ColorSwatchComponent = Components.ExtensibleObject.prototype.addInterface(ColorSwatchComponent, dbFedComponentInterface);

//ColorSwatchComponent.defaultDef = {
//	nodeName : 'color-swatch',
//	targetSlotIndex : 0,
//	templateNodeName : 'span',
//	states : [
//		{selected : undefined}
//	],
//	props : [
//		{contentToList : undefined}
//	],
//	reactOnSelf : [
//		{
//			from : 'contentToList',
//			cbOnly : true,
//			subscribe : ColorSwatchComponent.prototype.updateTargetView
//		}
//	]
//}
//console.log(createColorSwatchComponentHostDef);
//Object.assign(ColorSwatchComponent.defaultDef, {sWrapper : createColorSwatchComponentHostDef().getHostDef().sWrapper});

ColorSwatchComponent.prototype.createDefaultDef = function() {
	var def = createColorSwatchComponentHostDef();
//	def.getHostDef().attributes.push(new TypeManager.attributesModel({title : '-ColorSwatch' + TypeManager.UIDGenerator.newUID()}));
	return def;
}

ColorSwatchComponent.prototype.handleClickEvent = function(e) {
//	console.log(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))));
//	if (e.target === this.view.getWrappingNode().children[1]) {
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this)))).handleClickEvent.call(this, e);
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))).handleClickEvent.call(this, e);
//	}
}





module.exports = ColorSwatchComponent;