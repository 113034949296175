"use strict";
/**
 * @def BorderSetViewer
 * @isGroup true
 * 
 * @CSSify styleName : BorderSetViewerHost/true
 * @CSSify styleName : BorderSetViewerTemplate
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var BorderSetViewerDef = function(uniqueID, options, model) {
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	var moduleDef = TypeManager.createComponentDef({
		nodeName : 'borderset-viewer'/**@CSSify Style componentStyle : BorderSetViewerHost */
	}, 'BorderSetViewerDefaultHostDef');
	
	return moduleDef;
}

module.exports = BorderSetViewerDef;