"use strict";
/**
 * @def spinner
 * @isGroup false
 * 
 * @CSSify hostName : spinner
 * @CSSifyRule rule : node
 * 
 */

module.exports = [
/*@CSSifySlot*/
				];