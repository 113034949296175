"use strict";
/**
 * @constructor SelectInput
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var TypedListComponent = require('src/UI/generics/TypedListComponent');

var createSelectInputHostDef = require('src/UI/packages/minimal/SelectInput/packageComponentDefs/SelectInputHostDef');
var createSelectInputSlotsDef = require('src/UI/packages/minimal/SelectInput/packageComponentDefs/SelectInputSlotsDef');

// TODO: this should not be a TypedList: we make use of the children logic: <options> are child of the first child of selectInput
var SelectInput = function(definition, parentView, parent) {
	this.slotsCount = 1;
	var stdDef = createSelectInputHostDef();
	this.slotsDef = createSelectInputSlotsDef();
	(stdDef.getGroupHostDef() || stdDef.getHostDef()).section = (definition.getGroupHostDef() || definition.getHostDef()).section;
	
	Components.CompositorComponent.call(this, stdDef, parentView, parent);
	this.objectType = 'SelectInput';
	
	this.typedSlots[0].setSchema(['optionTitle']);
	this.typedSlots[0].push(this.typedSlots[0].newItem('-'));

//	this._children[1].basicLateViewExtend(stdDef.members[1]);
}
SelectInput.prototype = Object.create(Components.CompositorComponent.prototype);
SelectInput.prototype.objectType = 'SelectInput';
SelectInput.prototype.extendsCore = 'LazySlottedCompoundComponent';


//SelectInput.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

//SelectInput.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			createSelectInputHostDef(),
//			'SelectInputDefaultDef'
//		);
//}


/**
 * @overrride
 */
SelectInput.prototype.updateDefinitionBasedOnSlotsCount = function(definition) {
	
}

SelectInput.prototype.createEvents = function() {
	this.createEvent('clicked_ok')
}

SelectInput.prototype.registerEvents = function() {
	this.clickHandler = this.handleClick.bind(this);
	this._children[0].view.getMasterNode().addEventListener('mousedown', this.clickHandler);
	
	this.addEventListener('clicked_ok', this.toggleExpanded.bind(this));
	this._children[1].addEventListener('clicked_ok', this.toggleExpanded.bind(this));
}

SelectInput.prototype.handleClick = function(e) {
	e.preventDefault();
	this.trigger('clicked_ok');
}

SelectInput.prototype.toggleExpanded = function() {
	console.log('toggleExpanded');
	this.streams.expanded.value = this.streams.expanded.value ? null : 'expanded';
}

module.exports = SelectInput;