"use strict";
/**
 * @def IFrameComponent
 * @isGroup false
 * 
 * @CSSify styleName : IFrameComponentHost/true
 * @CSSify styleName : IFrameComponentTemplate/false
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var IFrameComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "flex",
		"flexFlow": "row",
		"flex": "1 1 100%",
		"selector": ":host",
		"position": "absolute",
		"width": "100%",
		"height": "100%",
		"boxSizing": "border-box"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'IFrameComponentHostStyles'
	}
	
	
	var moduleDef = TypeManager.createComponentDef({
			nodeName : 'iframe',
			attributes : [
				{title : ''}	// IFrames must have a title ()https://dequeuniversity.com/rules/axe/4.4/frame-title?utm_source=lighthouse&utm_medium=devtools)
			],
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
			
			// Local App-Root can't be child of the IFrame Component, as it's instanciation
			// occurs way later...
//			subscribeOnChild : [
//				{
//					on : 'update',
//					subscribe : function(e) {
//						if (e.bubble)
//							this.trigger('update', e.data);
//					}
//				}
//			]
		});
	
	return moduleDef;
}

module.exports = IFrameComponentDef;