"use strict";
/**
 * @def FileSelectorSlots
 * @isGroup false
 * 
 * @CSSify styleName : FileSelectorHeader
 * @CSSify styleName : FileSelectorSection
 * @CSSifyTheme themeName : basic-light
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var FileSelectorSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */ 	// Remove the whitespace between @CSSify and the word DEBUG to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	var individualFileDef = TypeManager.createDef({
		host : TypeManager.createDef({
			type : 'VisibleStateComponent',
			nodeName : 'p',
			states : [
				{selected : null}
			],
			props : [
				{text : undefined},
				{fileName : undefined}
			],
			reactOnSelf : [
				{
					from : 'fileName',
					to : 'content'
				}
				//,
//				{
//					cbOnly : true,
//					from : 'selected',
//					subscribe : function(val) {
//						if (val === true)
//							this.view.API.updateBGColor('#2e3138');
//						else
//							this.view.API.updateBGColor('initial');
//					}
//				}
			]
			/**@CSSify Style componentStyle : FileSelectorSection */
		})
	});
	
	console.log(individualFileDef);
	
	return {
		individualFileDef : individualFileDef
	};
}

module.exports = FileSelectorSlotsDef;