"use strict";
/**
 * @constructor TableFromBuffer
*/
var appConstants = require('src/appLauncher/appLauncher');
var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');
//var ExtensibleTable = require('src/UI/generics/ExtensibleTable');

// created but not used
//var createTableFromBufferDef = require('src/UI/packages_defs/structs/tableFromBufferDef');
//var createTableFromBufferSlotsDef = require('src/UI/packages_defs/structs/tableFromBufferSlotsDef');


var TableFromBuffer = function(def, parentView, parent) {
	Component.ExtensibleTable.call(this, def, parentView, parent);
	this.objectType = 'TableFromBuffer';
	
	this.maxTableLength = 7000;
}
TableFromBuffer.prototype = Object.create(Component.ExtensibleTable.prototype);
TableFromBuffer.prototype.objectType = 'TableFromBuffer';
TableFromBuffer.prototype.extends = 'ExtensibleTable';

TableFromBuffer.prototype.setTableContentFromBuffer = function(bufferType, buffer, headerTitles) {
	
	var entrySize = this.getEntrySize(bufferType);
	if (!entrySize)
		return '';

	var intCount = entrySize / 4;
	
	// (headerTitles is Array, and will be returned as "typed" array through the ReactiveDataset)
	this.defineColumnsConfig(intCount, headerTitles);
	
	var viewBuffer = [], tmp = new DataView(new ArrayBuffer(entrySize)), part;

	for (let i = 0, l = Math.min(this.maxTableLength * entrySize, buffer.byteLength); i < l; i++) {
		tmp.setUint8(i % entrySize, buffer.getUint8(i));
		if (i % entrySize === (entrySize - 1)) {
			viewBuffer.push(this.getDataObj(entrySize, tmp));
			this.rowsCount++;
		}
	}
	
	this.pushApplyToSlot(1, viewBuffer);
	
	return this.rowsCount === this.maxTableLength ? ' (first ' + this.maxTableLength.toString() + ')' : '';
}

TableFromBuffer.prototype.defineColumnsConfig = function(intCount, headerTitles) {
	
	this.earlyUpdateStyle(intCount);
	
	this.setcolumnsCount((intCount + 1), headerTitles);
	// headerTitles = 
	
	var componentDef;
	if (typeof (componentDef = TypeManager.definitionsCache.isKnownUID(this.columnsCount.toString() + 'ColsTableRow')) === 'object' && componentDef)
		this.useRowDef(componentDef);
	else
		this.defineRow(this.columnsCount);
		
	// this.hackStyle();
}


TableFromBuffer.prototype.getDataObj = function(entrySize, tmpBuffer) {
	switch(entrySize) {
		case 4 : 
			return [this.rowsCount.toString(), tmpBuffer.getUint32(0).toString()];
		case 8 : 
			return [this.rowsCount.toString(), tmpBuffer.getUint32(0).toString(), tmpBuffer.getUint32(4).toString()];
		case 12 : 
			return [this.rowsCount.toString(), tmpBuffer.getUint32(0).toString(), tmpBuffer.getUint32(4).toString(), tmpBuffer.getUint32(8).toString()];
	}
}

TableFromBuffer.prototype.defineRow = function(colNbr) {

	var componentDef = TypeManager.createComponentDef({
		type : 'ComponentWith_FastReactiveText',
		nodeName : 'tr',
		props : [
			{rowContentAsArray : undefined}
		],
		reactOnSelf : [
				{
					from : 'rowContentAsArray',
					cbOnly : true,
					subscribe : Component.ComponentWith_FastReactiveText.prototype.setContentFromArrayOnEachMemberView
				}
		]
	}, colNbr.toString() + 'ColsTableRow');
	
	for (let i = 0; i < colNbr; i++) {
		componentDef.members.push(TypeManager.createComponentDef({
			nodeName : 'td'
		}, null, 'hostOnly'));
	}
	
	this.useRowDef(componentDef);
}

TableFromBuffer.prototype.useRowDef = function(def) {
	this.typedSlots[1].defaultListDef.getHostDef().template = def;
	// this.typedSlots[1].defaultListDef.getHostDef().each = this.pseudoModel;
}

/**
 * Interesting "early" stylesheet override allowed by the fact there is no DOM instanciation 
 * for the whole component till we call setColumnsCount()
 * 	=> Here, even caching the stylesheet is a reliable way to go
 * 
 * Otherwise (for example, if the typedSlot would have  already been called)
 * we should have relied on the hackstyle() method, which is nevertheless another interesting
 * stylesheet override mechanism (as there is no DOM childNodes under <pseudo-slot>,
 * the style calculation is of little cost, even while mutating an "in DOM" style element)
 * 
 * @param {number} intCount
 */
TableFromBuffer.prototype.earlyUpdateStyle = function(intCount) {
	var cached_sWrapper;
	if (typeof (cached_sWrapper = appConstants.isKnownUID('TableFromBuffer_sWrapper_' + intCount.toString() + 'columns')) === 'object') {
//		console.log(intCount, 'TableFromBuffer_sWrapper_' + intCount.toString() + 'columns', cached_sWrapper);
		this._children[0].view.styleHook.s = cached_sWrapper;
		this._children[1].view.styleHook.s = cached_sWrapper;
	}
	else {
//		var styleDef = this._children[1].view.sWrapper.getRuleAsObject('td, th');
//		console.log(intCount, cached_sWrapper, styleDef);
//		if (styleDef) {
//			styleDef.rule.attributes.width = (93 / intCount).toString() + "%";
//			this._children[0].view.sWrapper.replaceStyle(styleDef.rule, styleDef.rule.attributes);
//			this._children[1].view.sWrapper.replaceStyle(styleDef.rule, styleDef.rule.attributes);
			this._children[0].view.styleHook.setWidth('td, th', (93 / intCount).toString() + "%");
			this._children[1].view.styleHook.setWidth('td, th', (93 / intCount).toString() + "%");
			appConstants.setUID(cached_sWrapper, this._children[1].view.styleHook.s);
//		}
	}
}

TableFromBuffer.prototype.hackStyle = function() {
//	var styleDef = this._children[1].view.sWrapper.getRuleAsObject('td, th');
//	if (styleDef) {
//		styleDef.rule.attributes.width = (90 / (this.columnsCount - 1)).toString() + "%";
//		this._children[0].view.getWrappingNode().firstChild.innerHTML += (styleDef.strRule = styleDef.rule.linearize());
//		this._children[1].view.getWrappingNode().firstChild.innerHTML += styleDef.strRule;
//	}
	this._children[0].view.styleHook.setWidth('td, th', (93 / intCount).toString() + "%");
	this._children[1].view.styleHook.setWidth('td, th', (93 / intCount).toString() + "%");
}

TableFromBuffer.prototype.getEntrySize = function(bufferType) {
	var entrySize = {
		elst : 12,
		stts : 8,
		ctts : 8,
		stsc : 12,
		stss : 4,
		stco : 4,
		stsz : 4,
		stsd : 0
	};
	return entrySize[bufferType];
}
	
	
	
	
	
	
	
	
	
	

module.exports = TableFromBuffer;
