"use strict";
/**
 * @constructor SelectOption
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

//var createSelectOptionHostDef = require('src/UI/packages/minimal/SelectOption/packageComponentDefs/SelectOptionHostDef');
//var createSelectOptionSlotsDef = require('src/UI/packages/minimal/SelectOption/packageComponentDefs/SelectOptionSlotsDef');

var SelectOption = function() {
	Components.ComponentWithReactiveText.apply(this, arguments);
	this.objectType = 'SelectOption';
}
SelectOption.prototype = Object.create(Components.ComponentWithReactiveText.prototype);
SelectOption.prototype.objectType = 'SelectOption';

SelectOption.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
			props : [
				{content : undefined}
			],
			reactOnSelf : [
				{from : 'text', to : 'content'},
				{to : 'content', cbOnly : true, subscribe : this.setContentFromValueOnView}
			]
	}, 'SelectOption');
}



module.exports = SelectOption;