"use strict";
/**
 * @constructor TagChipGroupsHostComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

//var createTagChipGroupsHostComponentHostDef = require('src/UI/packages/minimal/TagChipGroupsHostComponent/packageComponentDefs/TagChipGroupsHostComponentHostDef');
//var createTagChipGroupsHostComponentSlotsDef = require('src/UI/packages/minimal/TagChipGroupsHostComponent/packageComponentDefs/TagChipGroupsHostComponentSlotsDef');

var TagChipGroupsHostComponent = function(definition, parentView, parent) {
	Components.ComponentWithView.call(this, definition, parentView, parent);
	this.objectType = 'TagChipGroupsHostComponent';
}
TagChipGroupsHostComponent.prototype = Object.create(Components.ComponentWithView.prototype);
TagChipGroupsHostComponent.prototype.objectType = 'TagChipGroupsHostComponent';

TagChipGroupsHostComponent.defaultDef = {
	nodeName : 'div',
	attributes : [],
	states : [],
	props : [],
	reactOnParent : [],
	reactOnSelf : []
}

//TagChipGroupsHostComponent.prototype.createDefaultDef = function() {
//	return TypeManager.createComponentDef(
//			Object.assign(TagChipGroupsHostComponent.defaultDef, createTagChipGroupsHostComponentHostDef()),
//			'TagChipGroupsHostComponentDefaultDef'
//		);
//}

module.exports = TagChipGroupsHostComponent;