"use strict";
/**
 * @def pseudoSlot
 * @isGroup false
 * 
 * @CSSify hostName : tabSlots
 * @CSSifyRule rule : host flexBoxRow
 * @CSSifyRule rule : header tabHeader/border/padding/pointer
 * @CSSifyRule rule : section 
 * 
 */

module.exports = [
/*@CSSifySlot*/
				];