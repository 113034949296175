"use strict";
/**
 * @constructor TimeCounter
*/

var Component = require('src/core/Component');
	var video, mTimer;
	
	var TimeCounter = function(def, parentView, parent) {
		Component.ComponentWithView.call(this, def, parentView, parent);
		
		this.objectType = 'TimeCounter';
		this.def = def;
		
		this.learning = false;
		this.keyboardEvents = def.keyboardSettings;
		
		this.progressEvent = {
				action : function(timeInterval) {
							this.domElem.html(this.convertTime(video.domElem[0].currentTime, 'decToSex', true));
						}.bind(this),
				start : function() {},
				cancel : function() {}
		};
		
		if (false === mTimer.eventIsRegistered(this.progressEvent.action))
			this.progressEvent = mTimer.queueEvent('getTimeProgress', this.progressEvent.action, 'paused', null, 5);
	
		this.initGenericEvent();
		this.init(def);
	}
TimeCounter.prototype = Object.create(Component.ComponentWithView.prototype);
TimeCounter.prototype.objectType = 'TimeCounter';
	
	TimeCounter.prototype.createDOM = function(def) {
		this.domElem = $('<div/>', {'class' : def.buttonClassName + ' ' + def.className}).css(def.css);
		if (def.glyphInactive) {
			this.domElem.append($('<span/>', {'class' : def.glyphInactive}))
		}
		this.hoverElem = $('<div/>', {'class' : def.buttonClassName + '_hover ' + ' '  + def.className}).appendTo(this.domElem);
		this.command = def.command;
	}
	
	TimeCounter.prototype.convertTime = function(time, type, string_return) {
		var s, m, h, timeArray, retour;
		if (type === 'decToSex') {
			if (string_return === true) {
				s = String.prototype.slice.call('0' + (Math.floor(time) % 60), -2);
				m = String.prototype.slice.call('0' + (Math.floor(time / 60) % 60), -2);
				h = String.prototype.slice.call('0' + (Math.floor(time / (60 * 60)) % 24), -2);
				s = s ? s : '00';
				m = m ? m : '00';
				h = h ? h : '00';
				retour = (h !== '00' ? h + ':' : '') + m + ':' + s;
			}
			else {
				s = Math.floor(time) % 60;
				m = Math.floor(time / 60) % 60;
				h = Math.floor(time / (60 * 60)) % 24;
				retour = [h, m, s];
			}
			
			return retour;
		}
		else if (type === 'sexToDec') {
			if (typeof time === 'string') {
				timeArray = time.split(':');
			}
			else {
				timeArray = time;
			}
			h = m = s = i = 0;
			$.each(timeArray, function(k, fraction) {
				if (parseInt(fraction) !== 0) {
					switch (k) {
						case 3: 
							i = parseInt(fraction);
							break;
						case 2: 
							s = parseInt(fraction);
							break;
						case 1: 
							m = parseInt(fraction);
							break;
						case 0: 
							h = parseInt(fraction);
							break;
					}
				}
			});
	
			return dec;
		}
	}
	

module.exports = TimeCounter;