"use strict";
/**
 * @router markdownConverterRouter 
 */

var App = require('src/core/AppIgnition');
//var rDataset = require('src/core/ReactiveDataset');

// App Components
var ISocketListener = require('src/coreComponents/ISocketComponent/ISocketComponent');

// Sample code to include a definition file
var createIFrameStructDef = require('src/clientStructures/iFrameStructDef');

var classConstructor = function() {	

	function init(DomSelector) {
		
		var markdownConverterInstance = new App.coreComponents.CompoundComponent(createIFrameStructDef());
		
		new App.DelayedDecoration();
		document.querySelector(DomSelector).appendChild(markdownConverterInstance.view.getMasterNode());
		
		var markdownComponentDefSocketListener = new ISocketListener(markdownConverterInstance._children[1]);
		markdownComponentDefSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', markdownComponentDefSocketListener._remoteLocation);});
		var markdownComponentProofSocketListener = new ISocketListener(markdownConverterInstance._children[2]);
		markdownComponentProofSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', markdownComponentProofSocketListener._remoteLocation);});
		var markdownComponentResultSocketListener = new ISocketListener(markdownConverterInstance._children[3]);
		markdownComponentResultSocketListener.addEventListener('ready', function(e) {console.log('readyEvent', markdownComponentResultSocketListener._remoteLocation);});
	}
	
	return {
		init : init
	}
}

classConstructor.__factory_name = 'markdownConverterRouter';
module.exports = classConstructor;
