"use strict";
/**
 * @constructor SliderComponentWithSwatch
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var Slider = require('src/UI/generics/AbstractSlider');

var labelledSliderWithSwatchInterface = require('src/UI/_mixins/labelledSliderWithSwatchInterface');


var createSliderComponentWithSwatchHostDef = require('src/UI/packages/structs/SliderComponentWithSwatch/packageComponentDefs/SliderComponentWithSwatchHostDef');
//var createSliderComponentWithSwatchSlotsDef = require('src/UI/packages/structs/SliderComponentWithSwatch/packageComponentDefs/SliderComponentWithSwatchSlotsDef');

var SliderComponentWithSwatch = function(definition, parentView, parent) {
	if (!definition || !definition.getHostDef().nodeName)
		definition.getHostDef().nodeName = createSliderComponentWithSwatchHostDef().getHostDef().nodeName;
	this.orientation = 'horizontal';
	Slider.call(this, definition, parentView, parent);
	this.objectType = 'SliderComponentWithSwatch';
	
}
SliderComponentWithSwatch.prototype = Object.create(Slider.prototype);
SliderComponentWithSwatch.prototype.objectType = 'SliderComponentWithSwatch';

SliderComponentWithSwatch = Components.ExtensibleObject.prototype.addInterface(SliderComponentWithSwatch, labelledSliderWithSwatchInterface);

//SliderComponentWithSwatch.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

SliderComponentWithSwatch.prototype.createDefaultDef = function() {
	var def = TypeManager.createComponentDef(
			Slider.prototype.createDefaultDef(),
			null,
			'rootOnly'
		);
	def.getHostDef().sWrapper = createSliderComponentWithSwatchHostDef().getHostDef().sWrapper;
//	def.getHostDef().nodeName = createSliderComponentWithSwatchHostDef().getHostDef().nodeName;
	return def;
}


SliderComponentWithSwatch.prototype.afterRegisterEvents = function() {
	// Move the label at a position before the slider-track (moving the view in the register doesn't work, as the slider-handle is appended after the rendering)
	this.view.getWrappingNode().prepend(this.view.getWrappingNode().querySelector('label'));
	this.view.getWrappingNode().querySelector('div').appendChild(this.view.getWrappingNode().querySelector('slider-handle'));
}

module.exports = SliderComponentWithSwatch;