"use strict";
/**
 * @constructor UsernameInput
*/

var Component = require('src/core/Component');
var TextInput = require('src/UI/packages/minimal/TextInput');


var UsernameInput = function() {
	TextInput.apply(this, arguments);
	this.objectType = 'UsernameInput';
}
UsernameInput.prototype = Object.create(TextInput.prototype);
UsernameInput.prototype.objectType = 'UsernameInput';
//Component.ExtensibleObject.prototype.getCleanDefAfterExtension(UsernameInput);

module.exports = UsernameInput;