"use strict";
/**
 * @constructor CanvasConnectedGraphNodeComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var CanvasConnectedGraphNodeComponentHostDef = require('src/UI/packages/structs/CanvasConnectedGraphNodeComponent/packageComponentDefs/CanvasConnectedGraphNodeComponentHostDef');
//var createCanvasConnectedGraphNodeComponentSlotsDef = require('src/UI/packages/structs/CanvasConnectedGraphNodeComponent/packageComponentDefs/CanvasConnectedGraphNodeComponentSlotsDef');

var CanvasConnectedGraphNodeComponent = function(definition, parentView, parent) {
	Components.ComponentWithView.call(this, definition, parentView, parent);
	this.objectType = 'CanvasConnectedGraphNodeComponent';
}
CanvasConnectedGraphNodeComponent.prototype = Object.create(Components.ComponentWithView.prototype);
CanvasConnectedGraphNodeComponent.prototype.objectType = 'CanvasConnectedGraphNodeComponent';

//CanvasConnectedGraphNodeComponent.defaultDef = {
//	nodeName : 'div',
//	attributes : [],
//	states : [],
//	props : [],
//	reactOnParent : [],
//	reactOnSelf : []
//}

CanvasConnectedGraphNodeComponent.prototype.createDefaultDef = function() {
	return createCanvasConnectedGraphNodeComponentHostDef();
}

module.exports = CanvasConnectedGraphNodeComponent;