"use strict";
/**
 * @constructor BorderSwatchComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var TagChipComponent = require('src/UI/packages/minimal/TagChipComponent/TagChipComponent');

var labelledBorderSwatchInterface = require('src/UI/_mixins/labelledBorderSwatchInterface');
var dbFedComponentInterface = require('src/UI/_mixins/dbFedComponentInterface');

var createBorderSwatchComponentHostDef = require('src/UI/packages/minimal/BorderSwatchComponent/packageComponentDefs/BorderSwatchComponentHostDef');
//var createBorderSwatchComponentSlotsDef = require('src/UI/packages/minimal/BorderSwatchComponent/packageComponentDefs/BorderSwatchComponentSlotsDef');

var BorderSwatchComponent = function(definition, parentView, parent) {
	TagChipComponent.call(this, definition, parentView, parent);
	this.objectType = 'BorderSwatchComponent';
	
	this.propToAcquireFromDBType = 'style';
}

BorderSwatchComponent.prototype = Object.create(TagChipComponent.prototype);
BorderSwatchComponent.prototype.objectType = 'BorderSwatchComponent';
//console.log(BorderSwatchComponent.prototype._asyncInitTasks);
//BorderSwatchComponent.prototype._asyncInitTasks.splice(0, 1); // already queued viewExtension in TagChipComponent
BorderSwatchComponent = Components.ExtensibleObject.prototype.addInterface(BorderSwatchComponent, labelledBorderSwatchInterface);
BorderSwatchComponent = Components.ExtensibleObject.prototype.addInterface(BorderSwatchComponent, dbFedComponentInterface);



// Needs to be declared before being used in defaultDef
BorderSwatchComponent.prototype.setStylesOnTwoDivs = function(borderStyle) {
	
}




//BorderSwatchComponent.defaultDef = {
//	nodeName : 'border-swatch',
//	targetSlotIndex : 0,
//	templateNodeName : 'span',
//	states : [
//		{selected : undefined}
//	],
//	props : [
//		{contentToList : undefined},
//		{styleSetter : undefined}
//	],
//	reactOnSelf : [
//		{
//			from : 'contentToList',
//			cbOnly : true,
//			subscribe : BorderSwatchComponent.prototype.updateTargetView
//		},
//		{
//			from : 'styleSetter',
//			cbOnly : true,
//			subscribe : BorderSwatchComponent.prototype.setStylesOnTwoDivs
//		}
//	]
//}
//console.log(createBorderSwatchComponentHostDef);
//if (!BorderSwatchComponent.defaultDef.sWrapper)
//	Object.assign(BorderSwatchComponent.defaultDef, {sWrapper : createBorderSwatchComponentHostDef().getHostDef().sWrapper});

BorderSwatchComponent.prototype.createDefaultDef = function() {
	var def = createBorderSwatchComponentHostDef();
	return def;
}

BorderSwatchComponent.prototype.handleClickEvent = function(e) {
//	console.log(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))));
//	if (e.target === this.view.getWrappingNode().children[1]) {
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this)))).handleClickEvent.call(this, e);
		Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(Object.getPrototypeOf(this))))).handleClickEvent.call(this, e);
//	}
}




module.exports = BorderSwatchComponent;