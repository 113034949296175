"use strict";
/**
 * @constructor TagChipRangeComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var VisualSetComponent = require('src/UI/generics/VisualSetComponent/VisualSetComponent');

//var createTagChipRangeComponentHostDef = require('src/UI/packages/minimal/TagChipRangeComponent/packageComponentDefs/TagChipRangeComponentHostDef');
//var createTagChipRangeComponentSlotsDef = require('src/UI/packages/minimal/TagChipRangeComponent/packageComponentDefs/TagChipRangeComponentSlotsDef');

var TagChipRangeComponent = function(definition, parentView, parent) {
	VisualSetComponent.call(this, definition, parentView, parent);
	this.objectType = 'TagChipRangeComponent';
}
TagChipRangeComponent.prototype = Object.create(VisualSetComponent.prototype);
TagChipRangeComponent.prototype.objectType = 'TagChipRangeComponent';
TagChipRangeComponent.prototype.extends = 'VisualSetComponent';

TagChipRangeComponent.defaultDef = {
		host : TypeManager.createComponentDef({
			nodeName : 'tagchip-range'
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'img'
			}, null, 'hostOnly')
		]
}

TagChipRangeComponent.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef(
			TagChipRangeComponent.defaultDef,
			'TagChipRangeComponentDefaultDef',
			'rootOnly'
		);
}

module.exports = TagChipRangeComponent;