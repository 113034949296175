"use strict";
/**
 * @def TypedListBuilderComponentSlots
 * @isGroup false
 * 
 * @CSSify styleName : TypedListBuilderComponentPair/true
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var TypedListBuilderComponentSlotsDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */
	
	
	
	
//	if (options.type === 'FontSwatchCommponent') {
//		Object.assign(pairStyles[':'], {})
//	}
	
	var objectListDef = TypeManager.createComponentDef({
		UID : 'dummy'/**@CSSifyStyle componentStyle : TypedListBuilderComponentPair */
	});
	
	return {
		objectListDef : objectListDef
	};
}

module.exports = TypedListBuilderComponentSlotsDef;