"use strict";
/**
 * @constructor PrefBoxChildTabbedBox
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var ComponentTabPanel = require('src/UI/packages/panels/ComponentTabPanel');
var KeyValueList = require('src/UI/packages/structs/KeyValueList');
var SlidingPanel = require('src/UI/packages/panels/SlidingPanel');
//var TableFromBuffer = require('src/UI/packages/structs/TableFromBuffer');




	var PrefBoxChildTabbedBox = function(def, parentView, parent) {
		// please no def here
		def = undefined;
		
		// On the second extension, calling the ctor from the "extends" prop would cause an infinite recursion
		// 	=> call explicitly the inherited ctor:
		ComponentTabPanel.call(this, def, parentView, parent);
		this.objectType = 'PrefBoxChildTabbedBox';
		
	}
	// On the second extension, calling the ctor from the "extends" prop would cause an infinite recursion
	// we MUST call explicitly the inherited ctor (see above)
	PrefBoxChildTabbedBox.prototype = Object.create(ComponentTabPanel.prototype);
	PrefBoxChildTabbedBox.prototype.objectType = 'PrefBoxChildTabbedBox';
	PrefBoxChildTabbedBox.prototype.extends = 'ComponentTabPanel';
	
	PrefBoxChildTabbedBox.prototype.createDefaultDef = function() {
		return TypeManager.createComponentDef({
				props : [
					{updateChannel : undefined}
				],
				reactOnSelf : [
						{
							from : 'updateChannel',
							cbOnly : true,
							subscribe : this.distributeContent
						}
				]
		}, 'PrefBoxChildTabbedBox');
	}
	
	PrefBoxChildTabbedBox.prototype.distributeContent = function(data) {

		var self = this, tableContent, headerTitlesAsPrivateData, headerTitles, tableTitle, table_UID = '', uidIndex;

		data = data.filter(function(item) {
			if (item.key.indexOf('Box UUID') !== -1) {
				tableTitle = item.value;
				return true;
			}
			else if (item.key.indexOf('Pos') !== -1 && tableTitle) {
				table_UID = tableTitle + item.value.toString();
				return true;
			}
			else if (item.key.indexOf('Table') !== -1) {
				tableContent = item.value;
			}
			else if (item.key.indexOf('_private_') !== -1 && !headerTitlesAsPrivateData) {
				headerTitlesAsPrivateData = item;
				headerTitles = item.value;
			}
			else return true;
		});
		if (tableTitle && !table_UID)
			table_UID += (uidIndex = data.indexOfObjectByValue('key', tableTitle.slice(0, 4) + 'Pos')) !== false ? data[uidIndex].value : TypeManager.UIDGenerator.newUID();

		
		if (this.slotsCache.getItem(table_UID + (0).toString())) {
			this.emptySlots();
			this.retrieveSlots(table_UID);
		}
		else {
			this.emptySlots();
			
			var keyValueListDef = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
	//				reactOnParent : [
	//					{
	//						from : 'updateChannel',
	//						to : 'updateChannel'
	//					}
	//				]
				})
			}, null, 'rootOnly');
			var tableFromBufferDef = TypeManager.createComponentDef({
				host : TypeManager.createComponentDef({
	//				reactOnParent : [
	//					{
	//						from : 'updateChannel',
	//						to : 'updateChannel'
	//					}
	//				]
				})
			}, null, 'rootOnly');
	
			
	
			this.addTabForComponent((tableTitle ? '[' + tableTitle + ']' : '') + ' Box Header', {
				init : function(parentView, parent) {
					var component = new KeyValueList(keyValueListDef, parentView, parent);
					component.acquireData(data);
	//				console.log('keyValueList', component);
				}
			});
			if (tableContent) {
				this.addTabForComponent((tableTitle ? '[' + tableTitle + ']' : '') + ' Box Table', {
					init : function(parentView, parent) {
	
						var slidingPanel = new SlidingPanel(TypeManager.createComponentDef({UID : 'dummy'}), parentView, parent);
						parent.pushChild(slidingPanel);
						
						var component = new Component.TableFromBuffer(tableFromBufferDef);
						// Continuity in reactive binding on the initial model
						var partial = component.setTableContentFromBuffer(tableTitle, tableContent, (headerTitlesAsPrivateData ? headerTitlesAsPrivateData.value : null));
						self.typedSlots[0][1].headerTitle = self.typedSlots[0][1].headerTitle.indexOf('partial') === -1 ? self.typedSlots[0][1].headerTitle + partial : self.typedSlots[0][1].headerTitle;
						
						slidingPanel.render();
						slidingPanel.streams.updateChannel.value = component;
						
						if (table_UID)
							self.cacheSlots(table_UID);
	//					console.log('tableFromBuffer', component);
					}
				});
			}
			
			this.ignitePanel(0);
			this.render();
		}
	}
	

module.exports = PrefBoxChildTabbedBox;
