"use strict";
/**
 * @constructor FormInfo
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var FormInfo = function(def, parentView, parent) {
	
	if (!def.getHostDef().props.findObjectByKey('slotsTextContent'))
		def.getHostDef().props.push({slotsTextContent : undefined});
		
	Component.ComponentWithReactiveText.call(this, def, parentView, parent);
	this.objectType = 'FormInfo';
}
FormInfo.prototype = Object.create(Component.ComponentWithReactiveText.prototype);
FormInfo.prototype.objectType = 'FormInfo';

FormInfo.prototype.createDefaultDef = function() {
	return TypeManager.createComponentDef({
		host : TypeManager.createComponentDef({
			nodeName : 'dl',
//			props : [							// hint: we have to choose between a text by default, or a text always defined by the implementation
//				{slotsTextContent : undefined}
//			],
			reactOnSelf : [
					{
						from : 'slotsTextContent',
						cbOnly : true,
						subscribe : this.setContentFromArrayOnEachMemberView
					}
			]
		}, null, 'hostOnly'),
		members : [
			TypeManager.createComponentDef({
				nodeName : 'dt',
			}, null, 'hostOnly'),
			TypeManager.createComponentDef({
				nodeName : 'dd',
			}, null, 'hostOnly')
		]
	}, 'FormInfo', 'rootOnly');
}

module.exports = FormInfo;