"use strict";
/**
 * @constructor MenuBar
*/

var TypeManager = require('src/core/TypeManager');
var Component = require('src/core/Component');

var createMenuBarHostDef = require('src/UI/packages/panels/MenuBar/packageComponentDefs/MenuBarHostDef');
//var createMenuBarSlotsDef = require('src/UI/packages/minimal/MenuBar/packageComponentDefs/MenuBarSlotsDef');

var MenuBar = function(definition, parentView, parent) {
	Component.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'MenuBar';
}
MenuBar.prototype = Object.create(Component.CompositorComponent.prototype);
MenuBar.prototype.objectType = 'MenuBar';
MenuBar.prototype.extendsCore = 'CompoundComponentWithHooks';

MenuBar.prototype.createDefaultDef = function() {
	return createMenuBarHostDef();
}

MenuBar.prototype.registerClickEvents = function() {
	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
//		console.log('clicked');
		this._children.forEach(function(child) {
//			console.log(child.view.getMasterNode(), e.originalTarget);
			if (!child.streams.open)
				return;
			
			if (e.originalTarget === child.view.getMasterNode()
				|| e.originalTarget.parentNode.host === child.view.getMasterNode()
				|| (e.originalTarget.parentNode.host.parentNode.host
						&& e.originalTarget.parentNode.host.parentNode.host === child.view.getMasterNode())) {
				if (child.streams.open.value === true)
					child.streams.open.value = false;
				else
					child.streams.open.value = true;
			}
			else
				child.streams.open.value = false;
		}, this)
	}.bind(this));
}

module.exports = MenuBar;