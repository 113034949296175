"use strict";
/**
 * @constructor ModalBox
*/


var VisibleStateComponent = require('src/UI/generics/VisibleStateComponent');

var ModalBox = function(def, parentView, parent) {
	VisibleStateComponent.call(this, def, parentView, parent);
	
	this.createEvent('validate');
	this.createEvent('cancel');
}

ModalBox.prototype = Object.create(VisibleStateComponent.prototype);
ModalBox.prototype.objectType = 'ModalBox';




ModalBox.__factory_name = 'ModalBox';
module.exports = ModalBox;