"use strict";
/**
 * @def AppBoundaryComponent
 * @isGroup true
 * 
 * @CSSify styleName : AppBoundaryComponentHost/true
 * @CSSifyTheme themeName : basic-light
 * 
 */
var TypeManager = require('src/core/TypeManager');
var CreateStyle = require('src/core/GenericStyleConstructor');


var AppBoundaryComponentDef = function(uniqueID, options, model) {
	/**@CSSify DEBUG */		// DEBUG must be stuck (RED and bold) to trigger debug infos
		
	// Some CSS stuff (styles are directly injected in the main def below)
	/**@CSSifySlots placeholder */

	var hostStyles = [

	{
		"display": "flex",
		"flexFlow": "column",
		"flex": "1 1 0",
		"justifyContent": "space-evenly",
		"selector": ":host",
		"boxSizing": "border-box",
		"background": "0",
		"border": "1px outset #282828",
		"boxShadow": "none",
		"margin": "0",
		"outline": "0",
		"padding": "0",
		"verticalAlign": "baseline"
	}

	];
	var hostStylesUseCache = {
		use : true,
		nameInCache : 'AppBoundaryComponentHostStyles'
	}
	
	
		var moduleDef = TypeManager.createComponentDef({
			nodeName : 'app-boundary',
				sWrapper : CreateStyle(
						hostStylesUseCache.use ? hostStylesUseCache.nameInCache : null,
						hostStyles
					)
		});
	
	return moduleDef;
}

module.exports = AppBoundaryComponentDef;