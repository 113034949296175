"use strict";
/**
 * @constructor MenuOption
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createMenuOptionHostDef = require('src/UI/packages/setsForPanels/MenuOption/packageComponentDefs/MenuOptionHostDef');
//var createMenuOptionSlotsDef = require('src/UI/packages/setsForPanels/MenuOption/packageComponentDefs/MenuOptionSlotsDef');

var MenuOption = function(definition, parentView, parent) {
	Components.ComponentWithHooks.call(this, definition, parentView, parent);
	this.objectType = 'MenuOption';
}
MenuOption.prototype = Object.create(Components.ComponentWithHooks.prototype);
MenuOption.prototype.objectType = 'MenuOption';

MenuOption.prototype.createEvents = function() {
	this.createEvent('clicked_ok');
}

MenuOption.prototype.createDefaultDef = function() {
	return createMenuOptionHostDef();
}

MenuOption.prototype.beforeRegisterEvents = function(definition) {
	// definition.getHostDef().props.getObjectValueByKey('title')
	this.appendContentFromValueOnView(this.streams.title.value);
}

MenuOption.prototype.afterRegisterEvents = function(definition) {
	var self = this;
	this.view.currentViewAPI.getMasterNode().addEventListener('mouseup', function(e) {
		self.trigger('clicked_ok');
	});
}

module.exports = MenuOption;