"use strict";
/**
 * @constructor OnEnterValidableTextInput
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');
var keyCodes = require('src/events/JSkeyboardMap');

//var createOnEnterValidableTextInputHostDef = require('src/UI/packages/minimal/OnEnterValidableTextInput/packageComponentDefs/OnEnterValidableTextInputHostDef');
//var createOnEnterValidableTextInputSlotsDef = require('src/UI/packages/minimal/OnEnterValidableTextInput/packageComponentDefs/OnEnterValidableTextInputSlotsDef');

var OnEnterValidableTextInput = function(definition, parentView, parent) {
	Components.ComponentStrokeAware.call(this, definition, parentView, parent);
	this.objectType = 'OnEnterValidableTextInput';
	
	// When we inherit from a component which already defines Events, the additional Events must be created in the ctor
	this.createEvent('stroke_enter');
	this.createEvent('stroke_escape');
}
OnEnterValidableTextInput.prototype = Object.create(Components.ComponentStrokeAware.prototype);
OnEnterValidableTextInput.prototype.objectType = 'OnEnterValidableTextInput';

//OnEnterValidableTextInput.prototype.beforeRegisterEvents = function() {
//	this.createEvent('stroke_enter');
//	this.createEvent('stroke_escape');
//}

OnEnterValidableTextInput.prototype.createDefaultDef = function(componentDef) {
	var def = TypeManager.createComponentDef({
			nodeName : 'input',
			attributes : [
				{type  : 'text'}
			],
			props : [
//				{text : undefined}
				{content : undefined}
			],
			reactOnSelf : [
				{to : 'content', cbOnly : true, subscribe : this.setContentFromValueOnView},
				{from : 'text', to : 'content'}
			],
			subscribeOnSelf : [
				{
					on : 'stroke',
					subscribe : function(e) {
						if (e.data.keyCode === keyCodes.indexOf('ENTER')) {
							this.trigger('stroke_enter', {value : this.view.value.trim()}, true);
						}
						else if (e.data.keyCode === keyCodes.indexOf('ESCAPE')) {
							this.trigger('stroke_escape', {action : 'stop_edit'}, true);
						}
					}
				}
			]
	});
	
	// SAME AS SIMPLE TEXT
	// Sort of a Hack, to reduce the risk of errors for the user:
	// In case of a component choosing not to define a "text"" prop
	// but rather, for example, reactOnParent directly to "content"
	if (!componentDef.getHostDef().props.hasObjectByKey('text')) {
		var textProp = new TypeManager.propsModel({text : undefined});
		def.getHostDef().props.push(textProp);
		def.getHostDef().streams.push(textProp);
	}
	
	return def;
}

module.exports = OnEnterValidableTextInput;