"use strict";
/**
 * @constructor FrameRateVisualizerComponent
*/

var TypeManager = require('src/core/TypeManager');
var Components = require('src/core/Component');

var createFrameRateVisualizerComponentHostDef = require('src/UI/packages/panels/FrameRateVisualizerComponent/packageComponentDefs/FrameRateVisualizerComponentHostDef');
//var createFrameRateVisualizerComponentSlotsDef = require('src/UI/packages/panels/FrameRateVisualizerComponent/packageComponentDefs/FrameRateVisualizerComponentSlotsDef');

var FrameRateVisualizerComponent = function(definition, parentView, parent) {
	Components.CompositorComponent.call(this, definition, parentView, parent);
	this.objectType = 'FrameRateVisualizerComponent';
}
FrameRateVisualizerComponent.prototype = Object.create(Components.CompositorComponent.prototype);
FrameRateVisualizerComponent.prototype.extendsCore = ['CompoundComponent'];
FrameRateVisualizerComponent.prototype.objectType = 'FrameRateVisualizerComponent';

FrameRateVisualizerComponent.prototype.createDefaultDef = function() {
	return createFrameRateVisualizerComponentHostDef();
}

module.exports = FrameRateVisualizerComponent;